import React from 'react';
import { SettingsSubpage } from '../components/SettingsPage/SettingsSubpage';
import { Route, Switch } from 'react-router-dom';
import { SettingsOverview } from '../components/SettingsPage/SettingsOverview';
import { useTranslation } from 'react-i18next';
import { TagSettings } from '../components/SettingsPage/TagSettings';
import SettingsResetPassword from '../components/SettingsPage/SettingsResetPassword';
import UserProfileSetting from '../components/SettingsPage/UserProfileSetting';

const SettingsPage: React.FunctionComponent = () => {
  const { t } = useTranslation(['common']);
  return (
    <Switch>

      <Route exact path="/settings">
        <SettingsOverview />
      </Route>

      <Route path="/settings/resetPassword">
        <SettingsSubpage
          title={`${t('common:settingsPage.password')}`}
          description={`${t('common:settingsPage.passwordDescription')}`}
        >
          <SettingsResetPassword />
        </SettingsSubpage>
      </Route>

      <Route path="/settings/tags">
        <SettingsSubpage
          title={`${t('common:tags.tagSettings')}`}
          description={`${t('common:tags.tagSettingsDescription')}`}
        >
          <TagSettings />
        </SettingsSubpage>
      </Route>

      <Route path="/settings/profile">
        <SettingsSubpage
          title={t('common:profile.userProfile')}
          description={t('common:profile.description')}
        >
          <UserProfileSetting />
        </SettingsSubpage>
      </Route>

    </Switch>
  );
};

export default SettingsPage;
