import { Box, IconButton, makeStyles, Menu, MenuItem } from '@material-ui/core';
import ChevronDown from '@material-ui/icons/ExpandMore';
import LanguageIcon from '@material-ui/icons/Language';
import i18next from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Languages from '../models/Languages';

const useStyles = makeStyles(() => ({
  root: {},
  icon: {
    color: '#4FB1E7',
    fontSize: 20,
  },
  iconButton: {
    width: 'auto',
    height: '20',
    padding: 0,
    paddingBottom: 3,
  },
}));

export default function LanguageSwitcher() {
  const classes = useStyles();

  const changeLanguage = (lang: Languages) => {
    i18next.changeLanguage(lang);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { t } = useTranslation(['common']);

  return (
    <Box display="flex" justifyItems="center" alignItems="center">
      <IconButton className={classes.iconButton} onClick={handleClick}>
        <LanguageIcon className={classes.icon} />
        <ChevronDown className={classes.icon} />
      </IconButton>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => changeLanguage(Languages.german)}>
          {t('common:languages.german')}
        </MenuItem>
        <MenuItem onClick={() => changeLanguage(Languages.english)}>
          {t('common:languages.english')}
        </MenuItem>
      </Menu>
    </Box>
  );
}
