import { Attachment } from '../models/UserAssetModel';
import React, { FunctionComponent } from 'react';
import { Box } from '@material-ui/core';
import { B64ImageLoader } from './B64ImageLoader';
import { PreviewSizes } from '../utils/PreviewURL';
import { DEFAULT_BORDER_RADIUS } from '../utils/Constants';

type ImageORTextPreviewProps = {
  attachment: Attachment,
  width: number,
};
export const ImageORTextPreview: FunctionComponent<ImageORTextPreviewProps> = (
  {
    attachment,
    width,
  },
) => (
  <Box
    borderRadius={DEFAULT_BORDER_RADIUS}
    width={width}
    height={width}
    display="flex"
    justifyContent="center"
    alignItems="center"
    textOverflow="ellipsis"
  >
    <B64ImageLoader url={attachment.url} size={PreviewSizes.XL} width={width} />
  </Box>
);
