// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
import React from 'react';
import { Route } from 'react-router-dom';
import { Redirect } from 'react-router';
import { observer } from 'mobx-react-lite';
import useStores from '../hooks/use-stores';

/* eslint-disable react/require-default-props */
interface IProps {
  children?: any,
  exact?: boolean,
  rest?: any,
  path?: any,
}

// eslint-disable-next-line react/prop-types
const PrivateRoute = observer(({ children, exact, ...rest }:IProps) => {
  const {
    moovyAuthStore: {
      isAuthenticated,
      // TODO: Add User Store
    },
  } = useStores();

  // eslint-disable-next-line no-console
  console.warn(`from privateroute: ${isAuthenticated}`);
  return (
    <Route
      {...rest}
      render={({ location }) => (isAuthenticated ? (
        children
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: location },
          }}
        />
      ))}
    />
  );
});

export default PrivateRoute;
