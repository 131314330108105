/* eslint-disable */
// @ts-nocheck
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from '@material-ui/core';
import React from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import useStores from '../../hooks/use-stores';
import { useLoginPageStyles } from '../LoginPage/LoginPageStyles';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import { Visibility, VisibilityOff } from '@material-ui/icons';

type NewPasswordFormProps = {
  token: string;
};

const NewPasswordForm: React.FC<NewPasswordFormProps> = ({ token }) => {
  const classes = useLoginPageStyles();

  const {
    moovyAuthStore: { resetPassword },
  } = useStores();

  const history = useHistory();

  const { t } = useTranslation(['common', 'validation']);

  const passwordValidationSchema = yup.object({
    password: yup
      .string()
      .min(10, t('validation:login.passwordTooShortError'))
      .required(t('validation:general.fieldRequired')),
    passwordRepeat: yup
      .string()
      .oneOf(
        [yup.ref('password'), null],
        t('validation:general.passwordNoMatch')
      )
      .required(t('validation:general.fieldRequired')),
  });

  // init formik for this component
  const passwordFormik = useFormik({
    initialValues: {
      password: '',
      passwordRepeat: '',
    },
    validationSchema: passwordValidationSchema,
    onSubmit: (values) => {
      resetPassword(values.password, token)
        .then(() => {
          toast.success('Password geändert.');
          history.replace('/home');
        })
        .catch((reason) => {
          toast.error(t('common:newPasswordPage.invalidTokenTryAgain'), {
            autoClose: 5000,
          });
        });
    },
  });

  // local state for toggling show/hide password
  const [isHidden, setHidden] = React.useState({
    isHidden: true,
  });
  // local state for toggling show/hide repeat password
  const [isHiddenRep, setHiddenRep] = React.useState({
    isHiddenRep: true,
  });

  return (
    <form onSubmit={passwordFormik.handleSubmit} autoComplete="off">
      <Grid
        container
        spacing={2}
        justify="center"
        alignItems="center"
        direction="column"
      >
        <Grid item>
          <Typography variant="h5">
            {t('common:login.resetPassword')}
          </Typography>
        </Grid>

        <Grid item>
          <FormControl
            variant="outlined"
            error={
              passwordFormik.touched.password &&
              Boolean(passwordFormik.errors.password)
            }
          >
            <InputLabel htmlFor="password">
              {t('common:login.password')}
            </InputLabel>
            <OutlinedInput
              autoComplete="new-password"
              id="password"
              label={t('common:login.password')}
              type={isHidden ? 'password' : 'text'}
              fullWidth
              autoFocus
              className={classes.textfield}
              value={passwordFormik.values.password}
              onChange={passwordFormik.handleChange}
              onBlur={passwordFormik.handleBlur}
              error={
                passwordFormik.touched.password &&
                Boolean(passwordFormik.errors.password)
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() =>
                      isHidden ? setHidden(false) : setHidden(true)
                    }
                    edge="end"
                  >
                    {isHidden ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={70}
            />
            <FormHelperText>
              {passwordFormik.touched.password &&
                passwordFormik.errors.password}
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid item>
          <FormControl
            variant="outlined"
            error={
              passwordFormik.touched.passwordRepeat &&
              Boolean(passwordFormik.errors.passwordRepeat)
            }
          >
            <InputLabel htmlFor="passwordRepeat">
              {t('common:login.repeatPassword')}
            </InputLabel>
            <OutlinedInput
              autoComplete="new-password"
              id="passwordRepeat"
              label={t('common:login.repeatPassword')}
              type={isHiddenRep ? 'password' : 'text'}
              fullWidth
              autoFocus
              className={classes.textfield}
              value={passwordFormik.values.passwordRepeat}
              onChange={passwordFormik.handleChange}
              onBlur={passwordFormik.handleBlur}
              helperText={
                passwordFormik.touched.passwordRepeat &&
                passwordFormik.errors.passwordRepeat
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password repeat visibility"
                    onClick={() =>
                      isHiddenRep ? setHiddenRep(false) : setHiddenRep(true)
                    }
                    edge="end"
                  >
                    {isHiddenRep ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={70}
            />
            <FormHelperText>
              {passwordFormik.touched.password &&
                passwordFormik.errors.passwordRepeat}
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid item>
          <Button
            className={classes.button}
            disableElevation
            type="submit"
            size="large"
            variant="contained"
            color="primary"
            fullWidth
          >
            {t('common:newPasswordPage.setNewPassword')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default NewPasswordForm;
