import React, { FunctionComponent } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { Attachment } from '../models/UserAssetModel';
import { ImageORTextPreview } from './ImageORTextPreview';
import { MOOV_COLOR_BLUE } from '../resources/colors';

type InnerBoxesProps = {
  attachments: Attachment[],
};
export const InnerBoxes: FunctionComponent<InnerBoxesProps> = ({ attachments }) => {
  const count: number = attachments.length;

  if (count === 0) {
    return null;
  }

  if (count === 1) {
    return (
      <Grid container justify="center">
        {attachments.map((attachment: Attachment) => (
          <Grid item key={attachment.id}>
            <ImageORTextPreview
              attachment={attachment}
              width={200}
            />
          </Grid>
        ))}
      </Grid>
    );
  }

  if (count >= 2 && count <= 4) {
    return (
      <Grid
        container
        alignContent="center"
        justify="center"

      >
        {attachments.map((attachment: Attachment) => (
          <Grid
            item
            style={{
              padding: '2%',
            }}
            key={attachment.id}
          >
            <ImageORTextPreview
              attachment={attachment}
              width={90}
            />
          </Grid>
        ))}
      </Grid>
    );
  }

  if (count >= 4) {
    return (
      <Grid container alignContent="center" justify="center">
        {attachments.slice(0, 3)
          .map((attachment: Attachment) => (
            <Grid
              item
              style={{
                padding: '2%',
              }}
              key={attachment.id}
            >
              <ImageORTextPreview
                attachment={attachment}
                width={90}
              />
            </Grid>
          ))}
        <Grid item>
          <Box width={100} height={100} display="flex" justifyContent="center" alignItems="center">
            <Typography
              variant="h5"
              style={{
                color: MOOV_COLOR_BLUE,
              }}
            >
              {`+ ${count - 3}`}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    );
  }

  return null;
};
