import { Container, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import TransparentPaper from '../components/TransparentPaper';
import { DEFAULT_CONTAINER_WIDTH } from '../utils/Constants';

const useStyles = makeStyles(() => ({
  container: {
    height: '100%',
  },
}));

const TermsPage = () => {
  const classes = useStyles();

  return (
    <Container maxWidth={DEFAULT_CONTAINER_WIDTH} className={classes.container}>
      <TransparentPaper>

        <Typography variant="h1">Terms</Typography>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Assumenda facere
          inventore laborum
          necessitatibus quaerat sed sint vitae voluptatibus. Accusantium adipisci,
          atque explicabo is
          laboriosam omnis quas rem veritatis. Accusantium, sequi.
        </p>

      </TransparentPaper>
    </Container>
  );
};

export default TermsPage;
