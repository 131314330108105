import React, { FunctionComponent, useEffect, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import { b64StringToCSSString, PreviewSizes, previewUrlFrom } from '../utils/PreviewURL';
import { Box, CircularProgress } from '@material-ui/core';
import BrokenImageIcon from '@material-ui/icons/BrokenImage';
import { makeStyles } from '@material-ui/core/styles';
import { MOOV_COLOR_LIGHTBLUE_TRANSPARENT, MOOV_COLOR_WHITE } from '../resources/colors';
import { DEFAULT_BORDER_RADIUS } from '../utils/Constants';

const useStyles = makeStyles(() => ({
  image: {
    width: '100%',
    height: '100%',
    borderRadius: DEFAULT_BORDER_RADIUS,
  },
}));

type B64ImageLoaderProps = {
  url: string;
  size: PreviewSizes;
  width?: number | 'auto' | '100%' | 'max-content',
  height?: number | 'auto' | '100%' | 'max-content',
  noLoader?: boolean,
  bgColor?: string,
};

/**
 * Shows thumbnails based on a b64 data string from api
 * @param url direct file url which the /preview can be appended
 * @param size enum of sizes @see PreviewSizes
 * @param width px
 * @param height px
 * @param noLoader disable the loader if present
 * @param bgColor color string
 * @constructor
 */
export const B64ImageLoader: FunctionComponent<B64ImageLoaderProps> = ({
  url,
  size,
  width,
  height,
  noLoader,
  bgColor,
}) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [b64, setb64] = useState('');

  useEffect(() => {
    setLoading(true);
    axios
      .get(previewUrlFrom(url, size), {
        withCredentials: true,
      })
      .then((r: AxiosResponse<string>) => {
        setb64(r.data);
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [url, size]);

  return (
    <Box
      style={{
        width: width ?? '100%',
        height: height ?? '100%',
        backgroundColor: bgColor ?? MOOV_COLOR_WHITE,
        borderRadius: DEFAULT_BORDER_RADIUS,
      }}
      justifyContent="center"
      alignItems="center"
    >
      {(loading && noLoader) && null}

      {(loading && !noLoader) && (
      <Box
        display="flex"
      >
        <CircularProgress size={width} style={{ color: MOOV_COLOR_LIGHTBLUE_TRANSPARENT }} />
      </Box>
      )}

      {(!loading && error) && (
        <Box
          className={classes.image}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <BrokenImageIcon style={{ fontSize: 20 }} />
        </Box>
      )}

      {(!loading && !error) && (
        <Box
          className={classes.image}
          style={{
            backgroundImage: b64StringToCSSString(b64),
            backgroundSize: 'cover',
          }}
        />
      )}
    </Box>
  );
};
