import { Button, Grid, makeStyles, TextField, Theme } from '@material-ui/core';
import { FormikValues, useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useStores from '../../hooks/use-stores';
import DoneIcon from '@material-ui/icons/Done';
import * as yup from 'yup';
import { UserProfile } from '../../models/UserAssetModel';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react-lite';

const useStyles = makeStyles((theme: Theme) => ({
  textfield: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
}));

const UserProfileSetting: React.FC = observer(() => {
  const {
    userAssetStore: {
      userProfile,
      getUserProfile,
      updateUserProfile,
    },
  } = useStores();

  useEffect(() => {
    getUserProfile();
  }, []); // eslint-disable-line

  const { email } = userProfile;

  const { t } = useTranslation(['common']);

  const classes = useStyles();

  const validationSchema = yup.object({
    name: yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      name: userProfile.name,
      email,
    },
    validationSchema,
    onSubmit: (values: FormikValues) => {
      const { id } = userProfile;
      const {
        name,
      } = values;
      const updatedUserProfile = {
        id,
        name,
        email,
      } as UserProfile;
      // Update
      updateUserProfile(updatedUserProfile)
        .then(() => {
          toast.success(t('common:profile.nameUpdated'));
        });
    },
  });

  return (

    <form onSubmit={formik.handleSubmit} autoComplete="off">

      <Grid
        container
        spacing={0}
        style={{
          width: '100%' }}
      >

        <Grid item xs={12}>
          <TextField
            id="name"
            label={t("common:profile.name")}
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={classes.textfield}
            placeholder={userProfile.name}
              // label={t('common:profile.name')}
            variant="outlined"
            fullWidth
            type="text"
          />

          <Grid />

          {/* <Grid item xs={12}> */}
          {/*  <TextField */}
          {/*    id="email" */}
          {/*    label={t("common:profile.userEmail")} */}
          {/*    className={classes.textfield} */}
          {/*    placeholder={email} */}
          {/*    variant="outlined" */}
          {/*    disabled */}
          {/*    fullWidth */}
          {/*    type="text" */}
          {/*  /> */}

          {/* </Grid> */}

          <Grid item xs={12}>
            <Button
              onClick={() => {
                formik.submitForm();
              }}
              type="submit"
              color="primary"
              variant="outlined"
              size="large"
              startIcon={<DoneIcon />}
            >
              {t('common:detailPage.save')}
            </Button>
          </Grid>

        </Grid>

      </Grid>
    </form>

  );
});

export default UserProfileSetting;
