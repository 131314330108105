/* eslint-disable */
// @ts-nocheck

import { Box, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(() => ({
  container: {
    height: '100%',
  },
  wrapper: {
    padding: 30,
  },
  ul: {
    listStyleType: 'disc',
  },
  header: {
    fontSize: 20,
    color: '#005798',
  },
  largeHeader: {
    fontSize: 24,
    color: '#005798',
    fontWeight: '500',
  },
}));

export const PrivacyTextGerman: React.FC = () => {
  const classes = useStyles();
  return (
    <Box className={classes.wrapper}>
      <h2 className={classes.largeHeader}>
        Datenschutzinformation von MoovyMed
      </h2>
      <p>
        <span className={classes.header}>
          Datenschutzinformation von MoovyMed
        </span>
        <br />
        Wir als Betreiber der Website unter www.moovymed.de sind Verantwortliche
        im Sinne des geltenden Datenschutzrechts, insbesondere der
        Datenschutz-Grundverordnung („DSGVO“), für die personenbezogenen Daten
        des Nutzers („Sie“) dieser Website.
        <br />
        <br />
        Im Folgenden unterrichten wir Sie übersichtlich im Rahmen unserer
        Informationspflichten (Art. 13 ff. DSGVO) darüber, welche Daten bei
        Ihrem Besuch unserer Website verarbeitet werden und auf welcher
        Rechtsgrundlage dies geschieht. Sie erhalten außerdem Informationen
        darüber, welche Rechte Sie gegenüber uns und gegenüber der zuständigen
        Aufsichtsbehörde haben.
      </p>
      <p>
        <span className={classes.header}>
          1. Informationen zum Verantwortlichen
        </span>
        <br />
        MoovyMed GmbH
        <br />
        <br />
        Werftstr. 12
        <br />
        76189 Karlsruhe
        <br />
        <br />
        <a href="mailto:datenschutz@moovymed.de">datenschutz@moovymed.de</a>
      </p>
      <p>
        <span className={classes.header}>
          2. Informatorische Nutzung unserer Website
        </span>
        <br />
        Wenn Sie unsere Website aufrufen, um sie lediglich zu besuchen, werden
        so genannte Logfiles verarbeitet, indem sie von unserem System
        automatisiert erfasst werden.
        <br />
        <br />
        Folgende Logfiles werden automatisiert verarbeitet:
        <br />
        <ul>
          <li>IP-Adresse des anfragenden Rechners</li>
          <li>Typ des verwendeten Internet-Browsers</li>
          <li>Sprache des verwendeten Internet-Browsers</li>
          <li>Version des verwendeten Internet-Browsers</li>
          <li>Betriebssystem und dessen Version</li>
          <li>Oberfläche des Betriebssystems</li>
          <li>Aufgerufene Seiten</li>
          <li>Datum und Uhrzeit des Besuchs</li>
          <li>Zeitzonendifferenz zur Greenwich Mean Time (GMT)</li>
          <li>Zugriffsstatus/http-Statuscode</li>
          <li>Übertragene Datenmenge</li>
          <li>Erfolg bzw. Fehler des Ladevorgangs</li>
          <li>Referrer</li>
          <li>
            Websites, die vom System des Besuchers über unsere Website
            aufgerufen werden
          </li>
          <li>Internet-Service-Provider des Nutzers</li>
        </ul>
        <br />
        Die Logfiles enthalten Ihre IP-Adresse, aber sie wird vor der
        Speicherung gekürzt. Daher ist eine Zuordnung zu Ihnen nicht möglich und
        Ihre Daten werden auch nicht zusammen mit anderen personenbezogenen
        Daten gespeichert.
        <br />
        <br />
        Für die Bereitstellung unserer Website ist die Verarbeitung der oben
        genannten Daten erforderlich.
        <br />
        <br />
        Rechtsgrundlage für eine Verarbeitung der Daten zu
        Anonymisierungszwecken ist Art. 6 (1) lit. f DSGVO.
      </p>
      <p>
        <span className={classes.header}>3. Nutzung von Angeboten</span>
        <br />
        <span
          style={{
            fontWeight: 'bold',
          }}
        >
          {`a) Anmeldung zum Newsletter (regelmäßig) / zu aktuellen Tipps und
          Hinweisen (unregelmäßig)`}
        </span>
        <br />
        <br />
        Sie haben die Möglichkeit, sich auf unserer Website zu registrieren.
        Dann erhalten Sie – je nachdem, wofür Sie sich bei uns anmelden –
        regelmäßig unseren Newsletter, oder / und aktuelle Informationen, z.B.
        erfahren Sie als Erstes, wann unser Portal online geht. Folgende
        personenbezogene Daten verarbeiten wir, wenn Sie unser
        Registrierungsformular ausfüllen:
        <br />
        <ul>
          <li>Vorname und Nachname, um Sie persönlich anzusprechen</li>
          <li>E-Mail-Adresse</li>
          <li>Fragen/Infos/Hinweise (Freifeld für Sie)</li>
        </ul>
        Die <b>Rechtsgrundlage</b> für diese Datenverarbeitung ist Art. 6 (1)
        lit. a DSGVO. Mit dem Absenden der Registrierung bei uns stimmen Sie der
        Verarbeitung Ihrer Daten durch uns zu (auch i.S.d. § 7 UWG).
        <br />
        <br />
        Wir werden Ihnen erst dann den Newsletter bzw. unsere Tipps und Hinweise
        zusenden, wenn Sie uns zuvor Ihre Anmeldung über eine Ihnen zu diesem
        Zweck zugesandte Bestätigungs-E-Mail durch Klicken des dafür
        vorgesehenen Links bestätigen. Damit möchten wir sicherstellen, dass nur
        Sie selbst sich bei uns anmelden können. Ihre diesbezügliche Bestätigung
        muss zeitnah nach Erhalt der Bestätigungs-E-Mail erfolgen, da
        andernfalls Ihre Newsletter-Anmeldung automatisch aus unserer Datenbank
        gelöscht wird.
        <br />
        <br />
        Zusätzlich speichern wir im Rahmen Ihrer Newsletter-Anmeldung den
        Zeitpunkt (Datum und Uhrzeit) der Übermittlung Ihrer Daten an uns, sowie
        Ihre IP-Adresse. Die Verarbeitung dieser Daten entspricht unserem
        berechtigten Interesse gem. Art. 6 (1) lit. f DSGVO, um die Sicherheit
        unserer Systeme zu gewährleisten und Missbrauch entgegenzuwirken.
        <br />
        <br />
        Ihre Daten werden ausschließlich im Zusammenhang mit dem Versand von
        Newslettern bzw. Tipps und Hinweisen verarbeitet. Zweck der Verarbeitung
        Ihrer E-Mail-Adresse ist die Möglichkeit, Ihnen unsere E-Mails zustellen
        zu können. Weitere Daten im Rahmen des Anmeldevorgangs dienen entweder
        dazu, Sie persönlich anzusprechen oder auch die Sicherheit unserer
        Dienste zu gewährleisten und einen Missbrauch der verwendeten
        E-Mail-Adresse zu verhindern.
        <br />
        <br />
        Ihre Daten werden nur so lange gespeichert, wie es für die Erreichung
        des Zwecks notwendig ist. Ihre E-Mail-Adresse wird daher über den
        Zeitraum Ihres aktiven Newsletter-Abonnements gespeichert, wenn Ihre
        Einwilligung hierfür vorliegt. Die Daten, die wir während Ihrer
        Anmeldung zusätzlich automatisch erheben (IP-Adresse, Datum und
        Uhrzeit), werden spätestens gelöscht, wenn Sie Ihre
        Newsletter-Abonnement beenden.
        <br />
        <br />
        <span
          style={{
            fontWeight: 'bold',
          }}
        >
          {`b) Widerspruchsmöglichkeit / Newsletter abbestellen`}
        </span>
        <br />
        <br />
        Sie können aus unserem E-Mail-Verteiler jederzeit abmelden. Den Link
        dazu finden Sie am Ende jeden Newsletters. Hierdurch widerrufen Sie Ihre
        Einwilligung bzw. widersprechen einer weiteren Verwendung Ihrer Daten
        zum Zwecke des Newsletter-Versands.
        <br />
        <br />
        <span
          style={{
            fontWeight: 'bold',
          }}
        >
          {`c) Kontaktaufnahme per E-Mail, Telefon oder Fax`}
        </span>
        <br />
        <br />
        Sie haben die Möglichkeit, uns per <b>E-Mail oder Telefon</b>{' '}
        kontaktieren. Wenn wir miteinander kommunizieren, verarbeiten wir
        hierfür Ihre personenbezogenen Daten. Die Daten werden ausschließlich
        verarbeitet, um Ihre Kontaktaufnahme zu bearbeiten und Ihr Anliegen zu
        lösen. Die
        <b>Rechtsgrundlage</b> für die Verarbeitung Ihrer personenbezogenen
        Daten ist Art. 6 (1) lit. f DSGVO. Die Daten werden gespeichert, bis sie
        zur Erreichung des Zwecks der Konversation mit Ihnen nicht mehr
        erforderlich sind und das Anliegen Ihrer Kontaktaufnahme umfassend
        geklärt ist.
        <br />
        <br />
        Wenn Ihre Kontaktaufnahme darauf abzielt, einen Vertrag mit uns
        abzuschließen, ist die zusätzliche <b>Rechtsgrundlage</b> für die
        Verarbeitung Ihrer personenbezogenen Daten Art. 6 (1) lit. b DSGVO.
        Diese Daten werden so lange gespeichert, wie sie zur Durchführung des
        Vertrags oder der vorvertraglichen Maßnahmen erforderlich sind.
        Darüberhinausgehend speichern wir Ihre Daten nur, um vertraglichen oder
        gesetzlichen Verpflichtungen (z.B. steuerlichen Pflichten) nachzukommen
        (Art. 6 (1) lit. c DSGVO).
        <br />
        <br />
        Zusätzlich zu den Daten, die Sie uns freiwillig mitteilen, erhalten wir
        ggf. den Zeitpunkt (Datum und Uhrzeit) der Übermittlung Ihrer Daten an
        uns, sowie Ihre IP-Adresse. Die Verarbeitung dieser Daten entspricht
        unserem berechtigten Interesse (Art. 6 (1) lit. f DSGVO), um die
        Sicherheit unserer Systeme zu gewährleisten und Missbrauch
        entgegenzuwirken. Diese Daten, die wir während Ihrer Kontaktaufnahme
        zusätzlich erheben, werden gelöscht, sobald sie nicht mehr benötigt
        werden, spätestens wenn das Anliegen Ihrer Kontaktaufnahme umfassend
        geklärt ist.
        <br />
        <br />
        Sie können uns jederzeit mitteilen (siehe oben <b>Ziffer 1</b>), dass
        wir die im Rahmen der Konversation mitgeteilten Daten löschen sollen. In
        diesem Fall werden, soweit zulässig, alle personenbezogenen Daten der
        Konversation gelöscht und eine Fortführung der Konversation ist nicht
        möglich.
        <br />
        <br />
        <span
          style={{
            fontWeight: 'bold',
          }}
        >
          {`d) Rückruf vereinbaren (mit Calendly)`}
        </span>
        <br />
        <br />
        Wenn Sie auf unserer Website den Button „Rückruf vereinbaren“ anklicken,
        können Sie mit uns einen Telefontermin vereinbaren. Hierfür setzen wir
        den Dienstleister „Calendly“ von der Calendly LLC, 1315 Peachtree St NE,
        Atlanta, GA 30309, USA, ein.
        <br />
        <br />
        Folgende Daten werden zur Terminvereinbarung mit uns erhoben:
        <ul>
          <li>Name</li>
          <li>E-Mail-Adresse</li>
          <li>Telefonnummer</li>
          <li>Datum und Uhrzeit des Termins</li>
          <li>Ihre Nachricht an uns (freiwillig)</li>
        </ul>
        Die Daten zur Terminvereinbarung genutzt. Mit Abschicken des Formulars
        stimmen Sie einer Verarbeitung Ihrer Daten durch uns zu.
        Rechtsgrundlagen für die Verarbeitung Ihrer Daten über die
        Calendly-Terminvereinbarung sind Art. 6 (1) lit. b und f DSGVO. Diese
        Daten, die wir zur Terminvereinbarung erheben, werden gelöscht, sobald
        sie nicht mehr benötigt werden, spätestens wenn das Anliegen Ihrer
        Kontaktaufnahme umfassend geklärt ist.
        <br />
        <br />
        Zusätzlich werden anhand der Daten interne Statistiken erstellt, mit der
        wir unsere Organisation verbessern können. Für uns ist diese Funktion
        daher sehr hilfreich, und im Rahmen einer Interessenabwägung mit Ihren
        Interessen zu dem Schluss gekommen, dass Ihre Interessen und Grundrechte
        dem Einsatz von Calendly nicht entgegenstehen und im Vergleich zu
        unseren Interessen nicht überwiegen. Rechtsgrundlage für die
        Verarbeitung Ihrer Daten, um die Statistik zu erstellen, ist Art. 6 (1)
        lit. f DSGVO. Die Statistiken haben keinen Personenbezug.
        <br />
        <br />
        Zusätzlich zu diesen Daten speichern wir den Zeitpunkt (Datum und
        Uhrzeit) der Übermittlung Ihrer Daten an uns, sowie Ihre IP-Adresse. Die
        Verarbeitung dieser Daten entspricht unserem berechtigten Interesse
        (Art. 6 (1) lit. f DSGVO), um die Sicherheit unserer Systeme zu
        gewährleisten und Missbrauch entgegenzuwirken. Diese zusätzlichen Daten
        werden gelöscht, sobald sie nicht mehr benötigt werden, spätestens wenn
        der Vertrag mit Ihnen abgewickelt ist.
        <br />
        <br />
        Die Datenschutzerklärung von Calendly finden Sie unter:
        <a
          href="https://calendly.com/de/pages/privacy"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://calendly.com/de/pages/privacy
        </a>{' '}
        Für eine etwaige Datenübermittlung aus der EU in ein Drittland (z.B. die
        USA) stützen wir uns auf die mit Calendly abgeschlossenen
        EU-Standardvertragsklauseln.
        <br />
        <br />
        Aus organisatorischer Sicht bevorzugen wir es, wenn die Funktion von
        Calendly zur Terminvereinbarung verwendet wird. Wenn Sie jedoch kein
        Interesse daran haben, mit uns über Calendly einen Termin zu
        vereinbaren, besteht auch die Möglichkeit, dass wir per E-Mail oder
        telefonisch einen Termin vereinbaren.
      </p>
      <p>
        <span className={classes.header}>4. Social Media</span>
        <br />
        <span
          style={{
            fontWeight: 'bold',
          }}
        >
          {`a) Icon-Links zu Social Networks`}
        </span>
        <br />
        <br />
        Auf unserer Website setzen wir kleine Icons ein, die jeweils auf unseren
        Webauftritt auf Plattformen von Dritten (Facebook, Twitter, LinkedIn)
        verweisen. Es handelt sich jeweils um <b>Hyperlinks</b>, also werden{' '}
        <b>keine</b>
        Daten von Ihnen automatisch übertragen, sondern erst, wenn Sie auf die
        Icons klicken und sich in Ihrem Browser ein neuer Tab mit der Website
        des Drittanbieters öffnet.
        <br />
        <br />
        <span
          style={{
            fontWeight: 'bold',
          }}
        >
          {`b) Facebook-Fanpage`}
        </span>
        <br />
        <br />
        Wir betreiben auf der Social-Media-Plattform Facebook (Facebook Inc.,
        Facebook Ireland Ltd, 4 Grand Canal Square, Grand Canal Harbour, D2
        Dublin, Ireland (im Folgenden: <b>“Facebook“</b>), eine Fan-Page, die
        wir auf unserer Unternehmensseite über das Facebook-Icon verlinken.
        Solange Sie nicht auf den Link klicken, erhält Facebook keine Daten von
        Ihnen. Wenn Sie den Link anklicken, um beispielsweise unseren
        Unternehmensauftritt bei Facebook anzusehen oder unsere Seite zu
        „liken“, erhält Facebook Daten von Ihnen (welche Daten Facebook erhält,
        hängt auch davon ab, ob Sie bei Facebook mit Ihrem Nutzerprofil
        eingeloggt sind, während Sie die Seite anklicken oder nicht).
        <br />
        <br />
        Während Facebook diese Daten in eigener Verantwortlichkeit unter anderem
        dazu nutzt, um Profile zu erstellen, können wir auf unserer
        Unternehmenshomepage lediglich aggregierte Daten, d.h. Statistiken
        sehen, die keinen Personenbezug mehr haben. Diese werden „Page Insights“
        genannt. Mehr Informationen über die Page Insights finden Sie unter
        folgendem Link:
        <br />
        <br />
        <a
          href="https://www.facebook.com/legal/terms/information_about_page_insights_data"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.facebook.com/legal/terms/information_about_page_insights_data
        </a>
        <br />
        <br />
        Aufgrund der Vorgaben der DSGVO haben wir mit Facebook eine von Facebook
        bereitgestellte Vereinbarung geschlossen, in der die gemeinsame
        Verantwortlichkeit für unsere Fan-Page geregelt ist. Sie finden diese
        Vereinbarung in deutscher Sprache unter folgendem Link:
        <br />
        <br />
        <a
          href="https://www.facebook.com/legal/terms/page_controller_addendum"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.facebook.com/legal/terms/page_controller_addendum
        </a>
        <br />
        <br />
        Daraus ergibt sich, dass Facebook primär für die aggregierten
        Insight-Daten verantwortlich ist. Außerdem wird Facebook sämtliche
        Pflichten aus der DSGVO im Hinblick auf die Verarbeitung von
        Insights-Daten erfüllen (u. a. Art. 12, 13 DSGVO, Art. 15–22 DSGVO und
        Art. 32–34 DSGVO). Wenn sie eine Anfrage bzgl. unserer Facebook-Fanpage
        an uns richten, werden wir Facebook zeitnah darüber informieren.
        Facebook wird laut unserer Vereinbarung die Anfrage beantworten.
        <br />
        <br />
        In der Nutzung und Verknüpfung unterschiedlicher Kommunikationskanäle
        liegt unser berechtigtes Interesse zur Verarbeitung von
        personenbezogenen Daten.
        <br />
        <br />
        Die Verarbeitung erfolgt auf folgenden <b>Rechtsgrundlagen</b> Art. 6
        Abs. 1 S. 1 lit. A und f DSGVO (Ihre Einwilligung in das Setzen von
        Cookies und unsere berechtigtes Interesse an Analyse, Auswertung und
        Marketing). Die Datenrichtlinie von Facebook finden Sie unter folgendem
        Link:
        <a
          href="https://www.facebook.com/policy.php"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.facebook.com/policy.php
        </a>
        .
        <br />
        <br />
        Für den Datentransfer in die USA haben wir mit Facebook Verträge
        abgeschlossen, darunter die Standardvertragsklauseln. Mehr dazu finden
        Sie hier
        <br />
        <a
          href="https://www.facebook.com/legal/technology_terms"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.facebook.com/legal/technology_terms
        </a>
        .
        <br />
        <br />
        Facebook ist nach wie vor unter dem Privacy Shield zertifiziert;
        allerdings stützen wir den Datentransfer in die USA nicht auf diese
        Grundlage. Informationen hierzu finden Sie unter:
        <br />
        <a
          href=" https://www.facebook.com/about/privacyshield"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.facebook.com/about/privacyshield
        </a>
        <br />
        <br />
        <a
          href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC.
        </a>
        <br />
        <br />
        <span
          style={{
            fontWeight: 'bold',
          }}
        >
          {`c) LinkedIn`}
        </span>
        <br />
        <br />
        Wir betreiben auf dem Social-Media-Portal LinkedIn (LinkedIn Ireland
        Unlimited Company, Wilton Place, Dublin 2, Irland) einen
        Internetauftritt. Wir sind mit LinkedIn teilweise gemeinsam
        verantwortlich für Datenverarbeitungen, die auf unserer LinkedIn-Seite (
        <a
          href="https://www.linkedin.com/company/memoresa"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.linkedin.com/company/memoresa
        </a>
        ) stattfinden. Wir verarbeiten über LinkedIn personenbezogene Daten,
        wenn wir Postings erstellen oder kommentieren und über unser Unternehmen
        berichten.
        <br />
        <br />
        Rechtsgrundlagen für die Datenverarbeitung sind Ihre Einwilligung gemäß
        Art. 6 Abs. 1 lit. a DSGVO, sofern Sie uns eine solche erteilt haben
        (z.B. für die Veröffentlichung von Fotos oder Videos) und/oder unsere
        berechtigten Interessen gemäß Art. 6 Abs. 1 lit. f DSGVO, z.B. weil die
        Berichterstattung oder Interaktion auf dem Social-Media-Portal dazu
        dient, unter Unternehmen oder unsere Tätigkeiten bekannt zu machen. Die
        Speicherdauer richtet sich danach, wie lange wir die Datenverarbeitung
        zum Erreichen unserer Zwecke aufrechterhalten müssen. Falls Sie Ihre
        Einwilligung uns gegenüber widerrufen und keine andere Grundlage für die
        Datenverarbeitung vorliegt, werden wir Ihre personenbezogenen Daten
        unter den Voraussetzungen des Art. 17 DSGVO löschen.
        <br />
        <br />
        Wir haben mit LinkedIn teilweise eine Auftragsverarbeitung ({' '}
        <a
          href="https://legal.linkedin.com/dpa/DE"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://legal.linkedin.com/dpa/DE
        </a>
        ) und teilweise, jedenfalls hinsichtlich der „Insight-Daten“ (für uns
        nur als Statistiken ersichtlich), die wir auf unserem Profil einsehen
        können, eine gemeinsame Verantwortlichkeit (mehr dazu hier:
        <a
          href="https://legal.linkedin.com/pages-joint-controller-addendum"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://legal.linkedin.com/pages-joint-controller-addendum
        </a>
        ).
        <br />
        <br />
        Die Datenschutzerklärung von LinkedIn finden Sie unter:
        <a
          href="https://www.linkedin.com/legal/privacy-policy?trk=lithograph_footer-privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.linkedin.com/legal/privacy-policy?trk=lithograph_footer-privacy-policy
        </a>
        <br />
        <br />
        Da es sich bei LinkedIn um ein Unternehmen handelt, dessen Mutterkonzern
        in den USA ist, ist es grundsätzlich nicht ausgeschlossen, dass ein
        Datentransfer in die USA oder in andere Drittstaaten erfolgt. Für einen
        etwaigen Datentransfer stützen wir uns auf EU-Standardvertragsklauseln.
        Weitere Informationen hierzu finden Sie unter:
        <a
          href="https://www.linkedin.com/help/linkedin/answer/62533?trk=microsites-frontend_legal_privacy-policy&lang=de"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.linkedin.com/help/linkedin/answer/62533?trk=microsites-frontend_legal_privacy-policy&lang=de
        </a>
        <br />
        <br />
        <span
          style={{
            fontWeight: 'bold',
          }}
        >
          {`d) XING`}
        </span>
        <br />
        <br />
        Wir haben auf dem Social-Media-Portal XING (XING AG, Dammtorstraße
        29-32, 20354 Hamburg) eine Unternehmensseite. Wir verarbeiten über XING
        personenbezogene Daten, wenn wir Postings erstellen oder kommentieren
        und über unser Unternehmen berichten.
        <br />
        <br />
        Rechtsgrundlagen für die Datenverarbeitung sind Ihre Einwilligung gemäß
        Art. 6 Abs. 1 lit. a DSGVO, sofern Sie uns eine solche erteilt haben
        (z.B. für die Veröffentlichung von Fotos oder Videos) und/oder unsere
        berechtigten Interessen gemäß Art. 6 Abs. 1 lit. f DSGVO, z.B. weil die
        Berichterstattung oder Interaktion auf dem Social-Media-Portal dazu
        dient, unter Unternehmen oder unsere Tätigkeiten bekannt zu machen. Die
        Speicherdauer richtet sich danach, wie lange wir die Datenverarbeitung
        zum Erreichen unserer Zwecke aufrechterhalten müssen. Falls Sie Ihre
        Einwilligung uns gegenüber widerrufen und keine andere Grundlage für die
        Datenverarbeitung vorliegt, werden wir Ihre personenbezogenen Daten
        unter den Voraussetzungen des Art. 17 DSGVO löschen.
        <br />
        <br />
        Die Datenschutzerklärung von XING finden Sie unter:
        <a
          href="https://privacy.xing.com/de/datenschutzerklaerung"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://privacy.xing.com/de/datenschutzerklaerung
        </a>
        .
      </p>
      <p>
        <span className={classes.header}>
          5. Inanspruchnahme von Dienstleistern
        </span>
        <br />
        Wir möchten darauf hinweisen, dass wir ggf. bei der Verarbeitung Ihrer
        personenbezogener Daten Dienstleister einsetzen, mit denen wir
        Auftragsverarbeitungsverträge abgeschlossen haben (z.B. für das
        Website-Hosting). Sofern Auftragsverarbeiter in einem Drittstaat (nicht
        innerhalb der EU) die Datenverarbeitung vornehmen, stellen wir sicher,
        dass das durch die DSGVO gewährleistete Schutzniveau Ihrer Daten nicht
        untergraben wird (Art. 44 ff DSGVO). Rechtsgrundlage für den Einsatz von
        Dienstleistern ist Art. 6 Abs. 1 S. 1 lit. f DSGVO. Die Beauftragung von
        Dienstleistern (Spezialisten oder sonstige Leistungserbringer in
        Bereichen, die wir nicht selbst bedienen können) entspricht unserem
        berechtigten Interesse. Falls Sie eine Kopie der geeigneten oder
        angemessenen Garantien erhalten möchten, teilen Sie uns dies gerne (s.o.
        <b>Ziff. 1</b>) mit.
      </p>
      <p>
        <span className={classes.header}>6. Bewerbungen</span>
        <br />
        <span
          style={{
            fontWeight: 'bold',
          }}
        >
          {`a. Bewerbungsmöglichkeiten`}
        </span>
        <br />
        <br />
        Sie haben verschiedene Möglichkeiten, sich bei uns zu bewerben.
        <br />
        <br />
        <span
          style={{
            fontWeight: 'bold',
          }}
        >
          {`Bewerbung per E-Mail`}
        </span>
        <br />
        <br />
        Wenn Sie sich per E-Mail bei uns bewerben, werden die von Ihnen
        übermittelten personenbezogenen Daten bei uns zur Abwicklung des
        Bewerbungsverfahrens verarbeitet. Das bedeutet, Ihre Bewerbung wird bei
        uns von den Mitarbeitern eingesehen, die für eine Vorauswahl zuständig
        sind. Die Rechtsgrundlage für die Verarbeitung Ihrer Daten ist Art. 6
        (1) S.1 lit. b, Art. 88 (1) DSGVO i.V.m. § 26 BDSG.
        <br />
        <br />
        Wenn wir Ihre Bewerbung ablehnen, speichern wir die Daten nur so lange
        wie nötig, längstens über einen Zeitraum von sechs Monaten, es sei denn,
        Sie geben uns Ihre Einwilligung, dass wir die Bewerberdaten länger
        speichern dürfen, um Sie ggf. auch nach Ablauf der sechs Monate zu
        kontaktieren.
        <br />
        <br />
        Auf Ihre Anfrage hin (zum Beispiel per E-Mail) werden alle
        personenbezogenen Daten der Bewerbung gelöscht, sofern nicht eine
        längere Speicherdauer erforderlich ist, um unsere berechtigten
        Interessen zu wahren und sofern Ihre Interessen nicht überwiegen (Art. 6
        (1) lit. f DSGVO).
        <br />
        <br />
        <span
          style={{
            fontWeight: 'bold',
          }}
        >
          {`Bewerbung per Post`}
        </span>
        <br />
        <br />
        Ihre per Post übermittelten personenbezogenen Daten werden bei uns zur
        Abwicklung des Bewerbungsverfahrens verarbeitet. Das bedeutet, Ihre
        Bewerbung wird bei uns von den Mitarbeitern eingesehen, die für eine
        Vorauswahl zuständig sind. Die Rechtsgrundlage für die Verarbeitung
        Ihrer Daten ist Art. 6 (1) S.1 lit. b, Art. 88 (1) DSGVO i.V.m. § 26
        BDSG.
        <br />
        <br />
        Wenn wir Ihre Bewerbung ablehnen, speichern wir die Daten nur so lange
        wie nötig, längstens über einen Zeitraum von sechs Monaten, es sei denn,
        Sie geben uns Ihre Einwilligung, dass wir die Bewerberdaten länger
        speichern dürfen, um Sie ggf. auch nach Ablauf der sechs Monate zu
        kontaktieren.
        <br />
        <br />
        Auf Ihre Anfrage hin (zum Beispiel per E-Mail) werden alle
        personenbezogenen Daten der Bewerbung gelöscht, sofern nicht eine
        längere Speicherdauer erforderlich ist, um unsere berechtigten
        Interessen zu wahren und sofern Ihre Interessen nicht überwiegen (Art. 6
        (1) lit. f DSGVO).
        <br />
        <br />
        Dokumente, die wir von Ihnen per Post erhalten haben, senden wir Ihnen
        nach Abschluss des Bewerbungsverfahrens unverzüglich zurück. Bitte legen
        Sie dafür einen frankierten Rückumschlag. Die Dokumente können auch in
        unserer Geschäftsstelle zu den üblichen Bürozeiten abgeholt werden.
        <br />
        <br />
        <span
          style={{
            fontWeight: 'bold',
          }}
        >
          {`b. Bewerbungsgespräch`}
        </span>
        <br />
        <br />
        Wir verarbeiten Ihre Bewerberdaten, insbesondere Vor- und Nachnamen,
        Anschrift und gegebenenfalls E-Mail-Adresse oder Telefonnummer, um Sie
        zu einem Bewerbungsgespräch einzuladen, für den Fall, dass Ihre
        Bewerbung in der Vorauswahl ausgewählt wurde. Die Rechtsgrundlage für
        die Verarbeitung Ihrer Daten ist Art. 6 (1) S.1 lit. b, Art. 88 (1)
        DSGVO i.V.m. § 26 BDSG.
        <br />
        <br />
        <span
          style={{
            fontWeight: 'bold',
          }}
        >
          {`c. Angebot für ein Beschäftigungsverhältnis`}
        </span>
        <br />
        <br />
        Sofern Sie ein Angebot von uns bekommen, mit uns ein
        Beschäftigungsverhältnis einzugehen, gilt zusätzlich zu dieser
        Datenschutzerklärung die Datenschutzerklärung für Mitarbeiter.
      </p>
      <p>
        <span className={classes.header}>7. Ihre Rechte</span>
        <br />
        Wenn wir Ihre Daten verarbeiten, sind Sie „Betroffener“ im Sinne der
        DSGVO. Ihnen stehen folgende Rechte zu: Auskunftsrecht, Recht auf
        Berichtigung, Recht auf Einschränkung der Verarbeitung, Recht auf
        Löschung, Recht auf Unterrichtung sowie Recht auf Datenübertragbarkeit.
        Darüber hinaus haben Sie ein Widerspruchsrecht und ein Widerrufsrecht
        und das Recht, sich bei der Aufsichtsbehörde zu beschweren. Im Folgenden
        finden Sie einige Details zu den einzelnen Rechten:
        <br />
        <br />
        <span
          style={{
            fontWeight: 'bold',
          }}
        >
          {`a) Auskunftsrecht`}
        </span>
        <br />
        <br />
        Sie haben das Recht, von uns eine Bestätigung darüber zu verlangen, ob
        wir Ihre personenbezogenen Daten verarbeiten. Sofern wir Ihre
        personenbezogenen Daten verarbeiten, haben Sie das Recht, insbesondere
        Auskunft über Verarbeitungszwecke, Kategorien personenbezogener Daten,
        Empfänger oder Empfängerkategorien, ggf. Speicherdauer zu erhalten.
        <br />
        <br />
        <span
          style={{
            fontWeight: 'bold',
          }}
        >
          {`b) Berichtigungsrecht`}
        </span>
        <br />
        <br />
        Sie haben das Recht auf Berichtigung und/oder Vervollständigung der
        Daten, die wir über Sie gespeichert haben, wenn diese Daten unrichtig
        oder unvollständig sind. Wir nehmen die Berichtigung oder
        Vervollständigung unverzüglich vor.
        <br />
        <br />
        <span
          style={{
            fontWeight: 'bold',
          }}
        >
          {`c) Recht zur Einschränkung der Verarbeitung`}
        </span>
        <br />
        <br />
        Unter bestimmten Voraussetzungen haben Sie das Recht, von uns die
        Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
        verlangen. Ein Beispiel hierfür ist, dass Sie die Richtigkeit Ihrer
        personenbezogenen Daten bestreiten und wir für eine bestimmte Dauer die
        Richtigkeit überprüfen müssen. Für die Dauer der Prüfung werden Ihre
        Daten nur eingeschränkt verarbeitet. Ein anderes Beispiel für die
        Einschränkung ist es, dass wir zwar Ihre Daten nicht mehr brauchen, Sie
        sie aber für einen Rechtsstreit benötigen.
        <br />
        <br />
        <span
          style={{
            fontWeight: 'bold',
          }}
        >
          {`d) Löschungsrecht`}
        </span>
        <br />
        <br />
        Sie haben in bestimmten Situationen das Recht, von uns zu verlangen,
        dass wir Ihre personenbezogenen Daten unverzüglich löschen. Dies ist
        beispielsweise der Fall, wenn wir Ihre personenbezogenen Daten für die
        Zwecke, für die wir die Daten erhoben haben, nicht länger benötigen oder
        wenn wir Ihre Daten unrechtmäßig verarbeitet haben. Ein weiteres
        Beispiel wäre es, dass wir Ihre Daten aufgrund Ihrer Einwilligung
        verarbeiten, Sie Ihre Einwilligung widerrufen und wir die Daten nicht
        aufgrund einer anderweitigen Rechtsgrundlage verarbeiten. Ihr
        Löschungsrecht besteht aber nicht immer. Es kann beispielsweise sein,
        dass wir Ihre personenbezogenen Daten verarbeiten, um einer rechtlichen
        Verpflichtung nachzukommen oder weil wir sie für einen Rechtsstreit
        benötigen.
        <br />
        <br />
        <span
          style={{
            fontWeight: 'bold',
          }}
        >
          {`e) Unterrichtungsrecht`}
        </span>
        <br />
        <br />
        Wenn Sie Ihr Berichtigungs-, Löschungs- oder Einschränkungsrecht
        gegenüber uns geltend gemacht haben, sind wir dazu verpflichtet, allen
        Empfängern, denen wir Ihre personenbezogenen Daten offengelegt haben,
        die Berichtigung, Löschung oder Einschränkung der Verarbeitung Ihrer
        Daten mitzuteilen, es sei denn, dies erweist sich als unmöglich oder ist
        mit einem unverhältnismäßigen Aufwand verbunden.
        <br />
        <br />
        <span
          style={{
            fontWeight: 'bold',
          }}
        >
          {`f) Recht auf Datenübertragbarkeit`}
        </span>
        <br />
        <br />
        Sie haben unter bestimmten Bedingung das Recht, die personenbezogenen
        Daten, die Sie uns bereitgestellt haben, in einem strukturierten,
        gängigen und maschinenlesbaren Format zu erhalten und das Recht, dass
        diese Daten einem anderen Verantwortlichen übermittelt werden. Das ist
        dann der Fall, wenn wir die Daten entweder aufgrund Ihrer Einwilligung
        verarbeiten oder aufgrund eines Vertrags mit Ihnen und dass wir die
        Daten mithilfe automatisierter Verfahren verarbeiten.
        <br />
        <br />
        Sie haben dabei das Recht zu erwirken, dass wir Ihre personenbezogenen
        Daten direkt an einen anderen Verantwortlichen übermitteln, soweit dies
        technisch machbar ist und Freiheiten und Rechte anderer Personen
        hierdurch nicht beeinträchtigt werden.
        <br />
        <br />
        <span
          style={{
            fontWeight: 'bold',
          }}
        >
          {`g) Widerspruchsrecht`}
        </span>
        <br />
        <br />
        Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen
        Situation ergeben, jederzeit gegen die Verarbeitung Ihrer
        personenbezogenen Daten, die auf von Art. 6 (1) lit. e oder lit. f DSGVO
        beruht, Widerspruch einzulegen. Dies gilt auch für ein auf diese
        Bestimmungen genanntes Profiling.
        <br />
        <br />
        Wir verarbeiten nach einem Widerspruch Ihre personenbezogenen nicht
        mehr, es sei denn, wir können zwingende schutzwürdige Gründe für die
        Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten
        überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung
        oder Verteidigung von Rechtsansprüchen.
        <br />
        <br />
        Wenn wir Ihre personenbezogenen Daten verarbeiten, um Direktwerbung zu
        betreiben, haben Sie das Recht, jederzeit Widerspruch gegen die
        Verarbeitung Ihrer personenbezogenen Daten zum Zwecke derartiger Werbung
        einzulegen. Dies gilt auch für Profiling, soweit es mit der
        Direktwerbung in Verbindung steht. Wenn Sie der Verarbeitung Ihrer
        personenbezogenen Daten zu Zwecken der Direktwerbung widersprechen,
        werden wir diese nicht mehr für diese Zwecke verarbeiten.
        <br />
        <br />
        <span
          style={{
            fontWeight: 'bold',
          }}
        >
          {`h) Widerrufsrecht`}
        </span>
        <br />
        <br />
        Sie haben gem. Art. 7 (3) DSGVO das Recht, Ihre Einwilligung jederzeit
        zu widerrufen. Durch den Widerruf der Einwilligung wird die
        Rechtmäßigkeit der Verarbeitung nicht rückwirkend unwirksam.
        <br />
        <br />
        <span
          style={{
            fontWeight: 'bold',
          }}
        >
          {`i) Beschwerderecht bei einer Aufsichtsbehörde`}
        </span>
        <br />
        <br />
        Sie haben das Recht auf Beschwerde bei einer Aufsichtsbehörde,
        unbeschadet eines anderweitigen verwaltungsrechtlichen oder
        gerichtlichen Rechtsbehelfs. Sie können insbesondere im Mitgliedstaat
        Ihres Wohnortes, Ihres Arbeitsplatzes oder des Orts des mutmaßlichen
        Verstoßes Ihr Beschwerderecht geltend machen, wenn Sie der Ansicht sind,
        dass die Verarbeitung Ihrer personenbezogenen Daten gegen die DSGVO
        verstößt.
        <br />
        <br />
        Eine Übersicht über die jeweiligen Landesdatenschutzbeauftragten der
        Länder sowie deren Kontaktdaten finden Sie unter dem folgenden Link:
        <br />
        <br />
        <a
          href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html.
        </a>
      </p>
      <h2 className={classes.largeHeader}>
        Informationen über Cookies auf unserer Website
      </h2>

      <p>
        Bitte beachten Sie folgenden Hinweis: Sie können selbst dafür sorgen,
        dass überhaupt keine Cookies auf Ihrem Rechner gespeichert werden, oder
        dass die Speicherung nur von bestimmten Cookies zugelassen wird. Dies
        können Sie in Ihren Internetbrowser-Einstellungen auswählen. Sie können
        dort auch die gespeicherten Cookies einsehen und löschen.
        <br />
        <br />
        Wenn Sie alle Cookies blockieren, kann es sein, dass Ihnen nicht alle
        Funktionen unserer Website zur Verfügung stehen.
        <br />
        <br />
        Wir verwenden auf unserer Website Cookies. Cookies sind Textdateien, die
        im Rahmen Ihres Besuchs unserer Website von unserem Webserver an Ihren
        Browser gesandt und von diesem auf Ihrem Rechner für einen späteren
        Abruf vorgehalten werden. Durch ein Cookie kann Ihr Internetbrowser also
        identifiziert werden, wenn Sie die Website erneut aufrufen. Es gibt
        Session-Cookies, das sind solche, die sich bei Schließen des Browsers
        wieder löschen und es gibt persistente Cookies, die auf der Festplatte
        gespeichert werden, bis ihr voreingestelltes Ablaufdatum erreicht ist
        oder bis sie aktiv durch Sie entfernt werden.
        <br />
        <br />
        <span className={classes.header}>1. Eigene Cookies (First-Party)</span>
        <br />
        <br />
        Wir nutzen eigene Cookies, um die{' '}
        <b>Funktionalität unserer Website zu gewährleisten</b>. Einige Elemente
        unserer Internetseite setzen notwendig voraus, dass Ihr Internetbrowser
        nach einem Seitenwechsel wiedererkannt wird.
        <br />
        <br />
        Für die Verarbeitung personenbezogener Daten in Cookies, die wir auf
        unserer Website setzen um die <b>Funktionalität</b> unserer Website und
        unseres Angebots zu gewährleisten, ist die <b>Rechtsgrundlage</b> Art. 6
        (1) lit. f DSGVO, sofern personenbezogene Daten in den Cookies enthalten
        sind.
        <br />
        <br />
        <span className={classes.header}>
          2. Fremde Cookies (Third-Party-Cookies/Drittanbieter-Cookies)
        </span>
        <br />
        <br />
        Wir verwenden auf unserer Website Cookies von sog. „Drittanbietern“. Das
        bedeutet, dass im Rahmen Ihres Besuchs unserer Website Daten von in
        Ihrem Web-Browser an den Webserver des Dritten übertragen werden und
        dort gespeichert werden. Der Verarbeitungsvorgang, der auf unserer
        Website ausgelöst wird, ist daher eine Übermittlung. Wir erhalten Ihre
        personenbezogenen Daten nicht.
        <br />
        <br />
        <span className={classes.header}>3. Google Analytics</span>
        <br />
        <br />
        Auf unserer Website ist der Analysedienst Google Analytics der Google
        LLC, 1600 Amphitheatre Parkway Mountain View, CA 94043, USA (im
        Folgenden: „<b>Google Analytics</b>“) implementiert.
        <br />
        <br />
        Google Analytics setzt Cookies, die folgende Informationen speichern:
        <ul>
          <li>
            Typ des verwendeten Internet-Browsers, Version des Internetbrowsers
          </li>
          <li>das von Ihnen verwendete Betriebssystem</li>
          <li>Referrer (zuvor besuchte Website)</li>
          <li>Ihre gekürzte IP-Adresse</li>
          <li>Uhrzeit der Server-Anfrage</li>
        </ul>
        Name, Zweck und Speicherdauer des Cookie
        <br />
        <br />
        _ga; dient zur Unterscheidung von Benutzern, um statistische Daten über
        die Nutzung der Website zu generieren; Dauer: 2 Jahre
        <br />
        <br />
        Name, Zweck und Speicherdauer des Cookie:
        <br />
        <br />
        _gat, beschränkt die Anforderungsrate für Google Analytics; Dauer: bis
        zum Beenden der Browsersitzung
        <br />
        <br />
        Name, Zweck und Speicherdauer des Cookie:
        <br />
        <br />
        _gid, dient zur Unterscheidung von Benutzern, um statistische Daten über
        die Nutzung der Website zu generieren; Dauer: 24 Stunden
        <br />
        <br />
        Wir nutzen eine Funktion von Google Analytics, mit der Ihre IP-Adresse
        vor der Speicherung oder Verarbeitung anonymisiert wird. Ihre IP-Adresse
        wird in der Regel noch innerhalb der Europäischen Union/des EWR gekürzt
        und erst danach z.B. an Google-Server in den USA übertragen. Die
        Verarbeitung Ihrer Informationen erfolgt pseudonym und wir werden keine
        Zusammenführung mit anderen personenbezogenen Daten von Ihnen vornehmen.
        <br />
        <br />
        Uns werden über das Tool lediglich Statistiken angezeigt, anhand derer
        wir unsere Website und Angebote optimieren können.
        <br />
        <br />
        Bevor wir die Cookies setzen, holen wir hierfür Ihre Einwilligung (Art.
        6 (1) lit. a DSGVO) ein.
        <br />
        <br />
        Sie können die Erfassung der durch das Cookie erzeugten und auf Ihre
        Nutzung der Website bezogenen Daten (inkl. Ihrer (anonymisierten)
        IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google
        verhindern, indem Sie das unter dem folgenden Link (
        <a
          href="http://tools.google.com/dlpage/gaoptout?hl=de"
          target="_blank"
          rel="noopener noreferrer"
        >
          http://tools.google.com/dlpage/gaoptout?hl=de
        </a>
        ) verfügbare Browser-Plugin herunterladen und installieren.
        <br />
        <br />
        Die Datenschutzerklärung von Google finden Sie unter folgendem Link:
        <a
          href="https://policies.google.com/privacy?hl=de"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://policies.google.com/privacy?hl=de
        </a>
        .
        <br />
        <br />
        Google ist immer noch unter dem Privacy Shield zertifiziert, aber für
        Datenübermittlungen stützt sich Google inzwischen auf die
        Standardvertragsklauseln.
        <br />
        <br />
        Hierzu:
        <a
          href="https://support.google.com/analytics/answer/6004245?hl=de&ref_topic=2919631"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://support.google.com/analytics/answer/6004245?hl=de&ref_topic=2919631
        </a>
        und zum Privacy Shield:
        <br />
        <br />
        <a
          href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI
        </a>{' '}
        sowie
        <br />
        <br />
        <a
          href="https://policies.google.com/privacy/frameworks?hl=de&gl=de"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://policies.google.com/privacy/frameworks?hl=de&gl=de{' '}
        </a>{' '}
        .
        <br />
        <br />
        <span className={classes.header}>
          2. Stand und Aktualität dieser Cookie-Information
        </span>
        <br />
        Stand: März 2023
      </p>
    </Box>
  );
};
