import { Box, Button, Grid } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { observer } from 'mobx-react-lite';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useStores from '../hooks/use-stores';
import IconMenuAnwendungen from '../resources/vector/Icon-Menu-Anwendungen.svg';
import { DEFAULT_BORDER_RADIUS } from '../utils/Constants';
// import IconMenuHilfe from '../resources/vector/Icon-Menu-Hilfe.svg';
import IconMenuSuche from '../resources/vector/Icon-Menu-Suche.svg';
// import IconMenuProfil from '../resources/vector/Icon-Menu-Profil.svg';
import IconMenuFeedback from '../resources/vector/Icon-Menu-Feedback.svg';
import CustomSvgIcon from './CustomSvgIcon';
import LanguageSwitcher from './LanguageSwitcher';

const NavbarMenuDesktop: FunctionComponent = observer(() => {
  const {
    moovyAuthStore: { logout, isAuthenticated },
    dialogStore: { setFeedbackDialogOpen },
    searchStore: { setSearchDialogOpen },
  } = useStores();

  const history = useHistory();

  const { t } = useTranslation(['common']);

  const md = 6;

  return (
    <Box p={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <Grid container justify="flex-end">
        {isAuthenticated && (
          <Grid item md={md}>
            <Button
              onClick={() => {
                setFeedbackDialogOpen(true);
              }}
              color="primary"
              variant="text"
              style={{ borderRadius: DEFAULT_BORDER_RADIUS }}
              startIcon={<CustomSvgIcon src={IconMenuFeedback} />}
            >
              {t('common:navbar.feedback')}
            </Button>
          </Grid>
        )}
        {isAuthenticated && (
          <Grid item md={md}>
            <Button
              color="primary"
              variant="text"
              style={{ borderRadius: DEFAULT_BORDER_RADIUS }}
              startIcon={<CustomSvgIcon src={IconMenuAnwendungen} />}
              onClick={() => history.push('/settings')}
            >
              {t('common:navbar.settings')}
            </Button>
          </Grid>
        )}

        {isAuthenticated && (
          <Grid item md={md}>
            <Button
              onClick={() => setSearchDialogOpen(true)}
              color="primary"
              variant="text"
              style={{ borderRadius: DEFAULT_BORDER_RADIUS }}
              startIcon={<CustomSvgIcon src={IconMenuSuche} />}
            >
              {t('common:navbar.search')}
            </Button>
          </Grid>
        )}

        {/* {isAuthenticated && ( */}
        {/*  <Grid item md={md}> */}
        {/*    <NavLink to="/help"> */}
        {/*      <Button */}
        {/*        color="primary" */}
        {/*        variant="text" */}
        {/*        style={{ borderRadius: DEFAULT_BORDER_RADIUS }} */}
        {/*        startIcon={( */}
        {/*          <CustomSvgIcon src={IconMenuHilfe} /> */}
        {/*        )} */}
        {/*      > */}
        {/*        {t('common:navbar.help')} */}
        {/*      </Button> */}
        {/*    </NavLink> */}
        {/*  </Grid> */}
        {/* )} */}

        {isAuthenticated && (
          <Grid item md={md}>
            <Button
              color="primary"
              variant="text"
              style={{ borderRadius: DEFAULT_BORDER_RADIUS }}
              startIcon={<ExitToAppIcon />}
              onClick={() => {
                logout().then(() => history.replace('/login'));
              }}
            >
              {t('common:login.logout')}
            </Button>
          </Grid>
        )}
      </Grid>
      <LanguageSwitcher />
    </Box>
  );
});

export default NavbarMenuDesktop;
