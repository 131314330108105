import React from 'react';
import { observer } from 'mobx-react-lite';
import useStores from '../../hooks/use-stores';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Button, Grid } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { toast } from 'react-toastify';
import DeleteConfirm from '../DeleteConfirm';

export const AssetTools = observer(() => {
  // Store
  const {
    userAssetStore: {
      deleteUserAsset,
      setAddAttachmentDialogOpen,
    },

  } = useStores();

  // Translation
  const { t } = useTranslation(['common']);

  const history = useHistory();

  const [open, setOpen] = React.useState(false);

  return (
    <Grid container spacing={2} justify="center">

      <Grid item>
        <Button
          color="primary"
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={() => setAddAttachmentDialogOpen(true)}
        >
          {t('common:detailPage.addAttachment')}
        </Button>
      </Grid>

      <Grid item>
        <Button
          color="secondary"
          variant="outlined"
          startIcon={<DeleteIcon />}
          onClick={() => setOpen(true)}
        >
          {t('common:detailPage.deleteAsset')}
        </Button>
        <DeleteConfirm
          open={open}
          description={t('common:deleteConfirmDialog.irreversibleDelete')}
          onConfirm={() => deleteUserAsset()
            .then(() => {
              setOpen(false);
              toast.success(t('common:detailPage.deleteSuccess'));
              history.replace('/home');
            })}
          onClose={() => setOpen(false)}
        />

      </Grid>

    </Grid>
  );
});
