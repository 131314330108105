import React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import useStores from "../hooks/use-stores";
import { UserAssetTag } from "../stores/TagStore";
import { get } from "../utils/RequestManager";
import { UserAsset } from "../models/UserAssetModel";
import { handleError } from "../utils/ErrorUtils";
import { Box, CircularProgress, Grid } from "@material-ui/core";
import { DEFAULT_BORDER_RADIUS, DEFAULT_GRID_SPACING, PAPER_BG_COLOR } from "../utils/Constants";
import { Autocomplete } from "@material-ui/lab";
import { toJS } from "mobx";
import { AutocompleteRenderInputParams } from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { InnerBoxes } from "./InnerBoxes";
import Typography from "@material-ui/core/Typography";
import moment from "moment/moment";
import { CenteredSectionHeadline } from "./CenteredSectionHeadline";

export const TagFilterSection: React.FC = observer(() => {
  const { t } = useTranslation(['common']);
  const history = useHistory();
  const {
    tagStore:
      { tags },
    userAssetStore: {
      getUserAsset,
    },
  } = useStores();
  const [searchTag, setSearchTag] = React.useState<UserAssetTag | null>();
  const [searchResults, setSearchResults] = React.useState<any[]>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const searchByTag = async (tagId: string) => {
    try {
      setLoading(true);
      const res = await get(`/userasset/searchbytag?tagId=${tagId.trim()}`);

      // Workaround for missing endpoint

      // Modify search results to contain real user assets
      const x: any[] = res.data;

      // Get the real asset for every search hit

      // eslint-disable-next-line consistent-return
      const y = Promise.all(x.map(async (searchResult) => {
        try {
          const fullSingleAsset = await getUserAsset(searchResult.id);
          const retVal = fullSingleAsset.data as UserAsset;
          console.warn(retVal);
          return retVal;
        } catch (e) {
          handleError(e, { showToast: true });
        }
      }));

      // write the states
      setLoading(false);
      setSearchResults(await y);
    } catch (e) {
      handleError(e, { showToast: true });
    }
  };

  React.useEffect(() => {
    if (searchTag) searchByTag(searchTag.id);
  }, [searchTag]); // eslint-disable-line

  return (
    <Grid container spacing={DEFAULT_GRID_SPACING}>

      <Grid item xs={12}>
        <CenteredSectionHeadline string={t('common:tags.tagSearch')} />
      </Grid>

      <Grid item xs={12}>
        <Box>
          <Autocomplete
            options={toJS(tags)}
            getOptionLabel={(option: UserAssetTag) => option.name}
            getOptionSelected={(option, value) => option?.name === value?.name}
            value={searchTag}
            onChange={(event, value) => setSearchTag(value)}
            fullWidth
            renderInput={(params: AutocompleteRenderInputParams) => (
              <TextField
                {...params}
                label={t('common:tags.filterByTag')}
                variant="outlined"
                placeholder={t('common:tags.filterByTag')}
                style={{
                  borderRadius: DEFAULT_BORDER_RADIUS,
                }}
              />
            )}
          />
        </Box>
      </Grid>

      {loading && <Grid item><CircularProgress /></Grid>}
      {(!loading && searchResults && searchResults.length > 0)
      && searchResults.map((userAsset: UserAsset) => (
        <Box p={2} key={userAsset.id}>
          <Box
            width={200}
            height={200}
            style={{
              background: PAPER_BG_COLOR,
              flexShrink: 0,
              borderRadius: DEFAULT_BORDER_RADIUS,
            }}
            display="flex"
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            mb={1}
            onClick={() => history.push(`/asset/${userAsset.id}`)}
          >
            {userAsset.attachments ? <InnerBoxes attachments={userAsset.attachments} /> : null}
          </Box>
          <Box
            width={200}
            height="auto"
            display="flex"
            justifyContent="center"
          >
            <Typography
              variant="subtitle2"
            >

              {userAsset.title ? userAsset.title : `${t('common:detailPage.collectionFrom')} ${moment(userAsset.created)
                .format('DD.MM.YY')}`}
            </Typography>

          </Box>

          {' '}
          {/* {userAsset.created.toString()} */}
        </Box>
      ))}

      {(searchResults && searchResults?.length === 0) && (
        <Grid item>
          <Typography>{t('common:general.noItems')}</Typography>
        </Grid>
      )}
    </Grid>
  );
});
