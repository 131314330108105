import { makeAutoObservable, runInAction } from 'mobx';
import axios, { AxiosResponse, CancelTokenSource } from 'axios';
import { post } from '../utils/RequestManager';
import { handleError } from '../utils/ErrorUtils';
import { UploadDialogContent } from '../models/UploadDialogContent';

export class UploadDialogStore {
  axiosUploadCancelToken: CancelTokenSource | undefined = undefined;

  percentComplete = 0;

  loading = false;

  uploadDialogOpen = false;

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  setUploadDialogOpen(open: boolean): void {
    this.uploadDialogOpen = open;
  }

  async submit(content: UploadDialogContent): Promise<AxiosResponse> {
    try {
      const folder = content.category ? content.category : '';
      const tags = content.tags ? content.tags.map((tag) => tag.id).join(',') : '';
      console.warn(tags);
      const formData = new FormData();
      formData.append('title', content.title!);
      formData.append('notes', content.notes!);
      formData.append('folder', folder);
      formData.append('tags', tags);
      content.files.forEach((file: File) => formData.append('file', file));

      runInAction(() => {
        // create a new cancel token for this upload
        this.axiosUploadCancelToken = axios.CancelToken.source();
        // gui changes
        this.loading = true;
      });

      return await post('/userasset', formData, {
        headers: {
          'content-type': 'multipart/form-data',
        },
        cancelToken: this.axiosUploadCancelToken?.token,
        onUploadProgress: (progress) => {
          runInAction(() => {
            this.percentComplete = Math.floor(
              (progress.loaded * 100) / progress.total,
            );
            if (this.percentComplete >= 100) {
              this.loading = false;
            }
          });
        },
      });
    } catch (e) {
      runInAction(() => {
        this.loading = false;
      });
      return handleError(e);
    }
  }

  cancelSubmit() {
    this.axiosUploadCancelToken?.cancel('Upload cancelled');
  }
}
