/* eslint-disable */
// @ts-nocheck
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  Link as MuiLink,
  TextField,
  Typography,
} from '@material-ui/core';
import React from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import useStores from '../../hooks/use-stores';
import { useLoginPageStyles } from './LoginPageStyles';
import { LoginState } from '../../models/LoginModels';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { OutlinedInput } from '@material-ui/core';

export default function InitialForm() {
  const classes = useLoginPageStyles();

  const {
    moovyAuthStore: { mail, setPassword, setStep, login, setRememberMe },
  } = useStores();

  const { t } = useTranslation(['common', 'validation', 'error']);

  // form validation is on component basis!
  // data goes to stores = less props
  // define a validation schema
  const loginPasswordValidationSchema = yup.object({
    password: yup.string().required(t('validation:general.fieldRequired')),
    rememberMe: yup.boolean().required(),
  });

  // local state for toggling show/hide password
  const [isHidden, setHidden] = React.useState({
    isHidden: true,
  });

  // init formik for this component
  const loginPasswordFormik = useFormik({
    initialValues: {
      password: '',
      rememberMe: false,
    },
    validationSchema: loginPasswordValidationSchema,
    onSubmit: async (values) => {
      setRememberMe(values.rememberMe);
      // TODO: Use hashed passwords.
      // setPassword(values.password)
      //   .then(() => login())
      //   .catch((error) => {
      //     enqueueSnackbar(`${t('error:error')}: ${error.message}`, { variant: 'error' });
      //     Error(error);
      //   });
      setPassword(values.password);
      try {
        await login();
      } catch (e) {
        // console.log(e);
      }
    },
  });

  return (
    <form onSubmit={loginPasswordFormik.handleSubmit} autoComplete="off">
      <Grid
        container
        spacing={2}
        justify="center"
        alignItems="center"
        direction="column"
      >
        <Grid item>
          <Typography variant="h5">{t('common:login.password')}</Typography>
        </Grid>

        <Grid item>
          <Typography variant="h6">{mail}</Typography>
        </Grid>

        <Grid item>
          <FormControl
            variant="outlined"
            error={
              loginPasswordFormik.touched.password &&
              Boolean(loginPasswordFormik.errors.password)
            }
          >
            <TextField
              disabled
              type="email"
              id="mail"
              autoComplete="username"
              autoFocus
              className={classes.textfield_invisible}
              value={mail}
            />
            <InputLabel htmlFor="outlined-adornment-password">
              {t('common:login.password')}{' '}
            </InputLabel>
            <OutlinedInput
              autoComplete="current-password"
              id="password"
              label={t('common:login.password')}
              type={isHidden ? 'password' : 'text'}
              fullWidth
              autoFocus
              className={classes.textfield}
              value={loginPasswordFormik.values.password}
              onChange={loginPasswordFormik.handleChange}
              onBlur={loginPasswordFormik.handleBlur}
              error={
                loginPasswordFormik.touched.password &&
                Boolean(loginPasswordFormik.errors.password)
              }
              //helperText={loginPasswordFormik.touched.password && loginPasswordFormik.errors.password}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() =>
                      isHidden ? setHidden(false) : setHidden(true)
                    }
                    // onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {isHidden ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={70}
            />
            <FormHelperText>
              {loginPasswordFormik.touched.password &&
                loginPasswordFormik.errors.password}
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                checked={loginPasswordFormik.values.rememberMe}
                onChange={loginPasswordFormik.handleChange}
                name="rememberMe"
                color="primary"
              />
            }
            label={t('common:login.rememberMe')}
          />
        </Grid>

        <Grid item>
          <Button
            className={classes.button}
            disableElevation
            type="submit"
            size="large"
            variant="contained"
            color="primary"
            fullWidth
          >
            {t('common:login.loginLabel')}
          </Button>
        </Grid>

        <Grid item>
          <MuiLink onClick={() => setStep(LoginState.initial)}>
            {t('common:general.back')}
          </MuiLink>
        </Grid>

        <Grid item>
          <MuiLink onClick={() => setStep(LoginState.resetPassword)}>
            {t('common:login.forgotPasswordQuestion')}
          </MuiLink>
        </Grid>
      </Grid>
    </form>
  );
}
