import { makeStyles, Paper, Theme } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { DEFAULT_BORDER_RADIUS, PAPER_BG_COLOR } from '../utils/Constants';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    backdropFilter: 'blur(20px)',
    backgroundColor: PAPER_BG_COLOR,
    borderRadius: DEFAULT_BORDER_RADIUS,
    padding: theme.spacing(1),
    width: '100%',
  },
}));

type TransparentBackgroundWrapperProps = {
  marginBottom?: any,
  paddingBottom?: any,
  elevation?: number,
};

const TransparentBackgroundWrapper: FunctionComponent<TransparentBackgroundWrapperProps> = (
  {
    children,
    marginBottom, elevation,
    paddingBottom,
  },
) => {
  const classes = useStyles();
  return (
    <Paper className={classes.paper} elevation={elevation ?? 0} style={{ marginBottom, paddingBottom }}>
      {children}
    </Paper>
  );
};

export default TransparentBackgroundWrapper;
