import {
  Card, CardActionArea, CardContent, Grid, Typography,
} from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type SettingCardProps = {
  settingId: string,
  title: string,
  description?: string,
};

export const SettingCard: React.FunctionComponent<SettingCardProps> = (
  {
    settingId,
    title,
    description,
  },
) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t } = useTranslation(['common']);
  return (

    <Grid
      item
      sm={4}
    >
      <Link to={`/settings/${settingId}`}>
        <Card elevation={5}>
          <CardActionArea>
            <CardContent style={{
              height: 150,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
            >
              <Typography
                variant="h5"
                align="center"
                style={{
                  marginBottom: 5,
                  fontWeight: 500,
                }}
              >
                {title}
              </Typography>
              <Typography align="center">
                {description}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Link>

    </Grid>

  );
};
