import { makeStyles } from "@material-ui/core/styles";
import { MOOV_COLOR_BLUE } from "../resources/colors";
import React from "react";
import { Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

type CenteredSectionHeadlineProps = {
  string: string
};
const useStyles = makeStyles(() => ({
  folderLabel: {
    color: MOOV_COLOR_BLUE,
    fontWeight: 400,
    fontSize: 20,
    textAlign: "center",
    marginTop: 20,
    marginBottom: 20,
  },
}));
export const CenteredSectionHeadline: React.FC<CenteredSectionHeadlineProps> = ({ string }) => {
  const classes = useStyles();
  return (
    <Box display="flex" justifyContent="center">
      <Typography variant="button" className={classes.folderLabel}>{string}</Typography>
    </Box>
  );
};
