export const tagSpecialties = [
  {
    key: 'allergies',
    translationEN: 'Allergies',
    translationDE: 'Allergien',
  },
  {
    key: 'generalMedicineFamilyDoctor',
    translationEN: 'General Medicine / Family Doctor',
    translationDE: 'Allgemeinmedizin / Hausarzt',
  },
  {
    key: 'anesthesiology',
    translationEN: 'Anesthesiology',
    translationDE: 'Anästhesie',
  },
  {
    key: 'surgery',
    translationEN: 'Surgery',
    translationDE: 'Chirurgie',
  },
  {
    key: 'dermatology',
    translationEN: 'Dermatology',
    translationDE: 'Dermatologie',
  },
  {
    key: 'diabetology',
    translationEN: 'Diabetology',
    translationDE: 'Diabetologie',
  },
  {
    key: 'endocrinology',
    translationEN: 'Endocrinology',
    translationDE: 'Endokrinologie',
  },
  {
    key: 'holisticMedicine',
    translationEN: 'Holistic Medicine',
    translationDE: 'Ganzheitliche Medizin',
  },
  {
    key: 'gynecologyObstetrics',
    translationEN: 'Gynecology / Obstetrics',
    translationDE: 'Gynäkologie / Geburtshilfe',
  },
  {
    key: 'otorhinolaryngologyENT',
    translationEN: 'Otorhinolaryngology (ENT)',
    translationDE: 'Hals-Nasen-Ohrenheilkunde',
  },
  {
    key: 'naturopathy',
    translationEN: 'Naturopathy',
    translationDE: 'Heilpraktiker',
  },
  {
    key: 'internalMedicine',
    translationEN: 'Internal Medicine',
    translationDE: 'Innere Medizin',
  },
  {
    key: 'cardiology',
    translationEN: 'Cardiology',
    translationDE: 'Kardiologie',
  },
  {
    key: 'orthodontics',
    translationEN: 'Orthodontics',
    translationDE: 'Kieferorthopädie',
  },
  {
    key: 'pediatrics',
    translationEN: 'Pediatrics',
    translationDE: 'Kinder- und Jugendmedizin',
  },
  {
    key: 'laboratoryMedicine',
    translationEN: 'Laboratory Medicine',
    translationDE: 'Labormedizin',
  },
  {
    key: 'pulmonology',
    translationEN: 'Pulmonology',
    translationDE: 'Lungenheilkunde',
  },
  {
    key: 'oralAndMaxillofacialSurgery',
    translationEN: 'Oral and Maxillofacial Surgery',
    translationDE: 'Mund-Kiefer-Gesichtschirurgie',
  },
  {
    key: 'alternativeMedicine',
    translationEN: 'Alternative Medicine',
    translationDE: 'Naturheilverfahren',
  },
  {
    key: 'nephrology',
    translationEN: 'Nephrology',
    translationDE: 'Nephrologie',
  },
  {
    key: 'neurology',
    translationEN: 'Neurology',
    translationDE: 'Neurologie',
  },
  {
    key: 'oncology',
    translationEN: 'Oncology',
    translationDE: 'Onkologie',
  },
  {
    key: 'orthopedics',
    translationEN: 'Orthopedics',
    translationDE: 'Orthopädie',
  },
  {
    key: 'osteopathy',
    translationEN: 'Osteopathy',
    translationDE: 'Osteopathie',
  },
  {
    key: 'pathology',
    translationEN: 'Pathology',
    translationDE: 'Pathologie',
  },
  {
    key: 'phoniatricsAndPediatricAudiology',
    translationEN: 'Phoniatrics and Pediatric Audiology',
    translationDE: 'Phoniatrie und Pädaudiologie',
  },
  {
    key: 'physiotherapy',
    translationEN: 'Physiotherapy',
    translationDE: 'Physiotherapie',
  },
  {
    key: 'plasticAndAestheticSurgery',
    translationEN: 'Plastic and Aesthetic Surgery',
    translationDE: 'Plastische und ästhetische Chirurgie',
  },
  {
    key: 'proctology',
    translationEN: 'Proctology',
    translationDE: 'Proktologie',
  },
  {
    key: 'psychiatry',
    translationEN: 'Psychiatry',
    translationDE: 'Psychiatrie',
  },
  {
    key: 'radiology',
    translationEN: 'Radiology',
    translationDE: 'Radiologie',
  },
  {
    key: 'rheumatology',
    translationEN: 'Rheumatology',
    translationDE: 'Rheumatologie',
  },
  {
    key: 'sleepMedicine',
    translationEN: 'Sleep Medicine',
    translationDE: 'Schlafmedizin',
  },
  {
    key: 'sportsMedicine',
    translationEN: 'Sports Medicine',
    translationDE: 'Sportmedizin',
  },
  {
    key: 'radiationTherapy',
    translationEN: 'Radiation Therapy',
    translationDE: 'Strahlentherapie',
  },
  {
    key: 'urology',
    translationEN: 'Urology',
    translationDE: 'Urologie',
  },
  {
    key: 'dentistry',
    translationEN: 'Dentistry',
    translationDE: 'Zahnmedizin',
  },
];
