import {
  Button, Grid, TextField, Typography,
} from '@material-ui/core';
import React from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import useStores from '../../hooks/use-stores';
import { useLoginPageStyles } from './LoginPageStyles';

export default function InitialForm() {
  const classes = useLoginPageStyles();

  const {
    moovyAuthStore: {
      setMail,
      checkMail,
    },
  } = useStores();

  const { t } = useTranslation(['common', 'validation']);
  // email validation with formik
  const emailValidationSchema = yup.object({
    mail: yup
      .string()
      .email(t('validation:login.mailDeformedError'))
      .required(t('validation:general.fieldRequired')),
  });

  const emailFormik = useFormik({
    initialValues: {
      mail: '',
    },
    validationSchema: emailValidationSchema,
    onSubmit: (values) => {
      setMail(values.mail);
      checkMail();
    },
  });
  return (
    <form onSubmit={emailFormik.handleSubmit} autoComplete="on">
      <Grid
        container
        spacing={2}
        justify="center"
        alignItems="center"
        direction="column"
      >

        <Grid item>
          <Typography variant="h5">
            {t('common:login.loginLabel')}
          </Typography>
        </Grid>
        <Grid item>
          <Typography align="center">
            {t('common:login.helperText')}
          </Typography>
        </Grid>

        <Grid item>
          <TextField
            type="email"
            id="mail"
            autoComplete="username"
            label="E-Mail"
            fullWidth
            variant="outlined"
            autoFocus
            className={classes.textfield}
            value={emailFormik.values.mail}
            onChange={emailFormik.handleChange}
            onBlur={emailFormik.handleBlur}
            error={emailFormik.touched.mail && Boolean(emailFormik.errors.mail)}
            helperText={emailFormik.touched.mail && emailFormik.errors.mail}
          />
        </Grid>

        <Grid item>
          <Button
            className={classes.button}
            disableElevation
            type="submit"
            size="large"
            variant="contained"
            color="primary"
            fullWidth
          >
            {t('common:login.preAuthButtonLabel')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
