import {
  Button, Icon, makeStyles, Tooltip,
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_BORDER_RADIUS } from '../utils/Constants';
import useStores from '../hooks/use-stores';
import { MOOV_COLOR_BLUE, MOOV_COLOR_WHITE } from '../resources/colors';
import B64svgicon from './b64svgicon';

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: DEFAULT_BORDER_RADIUS + 10,
    backgroundColor: MOOV_COLOR_WHITE,
    color: MOOV_COLOR_BLUE,
    padding: 15,
  },
}));

export default function UploadButton() {
  const classes = useStyles();

  const { t } = useTranslation(['upload']);

  const {
    uploadDialogStore: {
      setUploadDialogOpen,
    },
  } = useStores();

  const plusIconb64 = 'PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMC43MzciIGhla'
    + 'WdodD0iMzEuNjYiIHZpZXdCb3g9IjAgMCAzMC43MzcgMzEuNjYiPgogICAgPGRlZnM+CiAgICAgICAgPHN0eWxlPi5he2'
    + 'ZpbGw6'
    + 'bm9uZTtzdHJva2U6IzAwNTc5ODtzdHJva2UtbGluZWNhcDpyb3VuZDtzdHJva2UtbWl0ZXJsaW1pdDoxMDt9PC9zdHlsZ'
    + 'T4KICAgID'
    + 'wvZGVmcz4KICAgIDxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xLjcxNiAtMS4wODQpIj4KICAgICAgICA8cGF0aCBjbGF'
    + 'zcz0iYSIKICA'
    + 'gICAgICAgICAgICBkPSJNMTUuOTUsMzIuMjQ0YS43MDcuNzA3LDAsMCwxLS43MDctLjcwN1YxOS4yOTNhLjcwNy43MDcs'
    + 'MCwwLDAtLj'
    + 'cwNy0uNzA3SDIuOTIzYS43MDcuNzA3LDAsMCwxLS43MDctLjcwN1YxNS44MzRhLjcwNy43MDcsMCwwLDEsLjcwNy0uNzA'
    + '3SDE0LjUzNW'
    + 'EuNzA4LjcwOCwwLDAsMCwuNzA3LS43MDdWMi4yOTFhLjcwNy43MDcsMCwwLDEsLjcwNy0uNzA3aDIuMjc0YS43MDguNzA'
    + '4LDAsMCwxLC4'
    + '3MDcuNzA3VjE0LjQyYS43MDcuNzA3LDAsMCwwLC43MDcuNzA3SDMxLjI0NmEuNzA3LjcwNywwLDAsMSwuNzA3LjcwN3Yy'
    + 'LjA0NGEuNzA3'
    + 'LjcwNywwLDAsMS0uNzA3LjcwN0gxOS42MzhhLjcwNy43MDcsMCwwLDAtLjcwNy43MDd2OS4yNjEiCiAgICAgICAgICAgI'
    + 'CAgdHJhbnNmb3'
    + 'JtPSJ0cmFuc2xhdGUoMCAwKSIvPgogICAgPC9nPgo8L3N2Zz4K';

  return (
    <Tooltip title={t('upload:button.tooltip')} arrow>
      <Button
        onClick={() => setUploadDialogOpen(true)}
        className={classes.root}
        variant="contained"
        size="large"
        endIcon={(
          <Icon style={{
            display: 'flex',
            height: 'inherit',
            width: 'inherit',
          }}
          >
            <B64svgicon data={plusIconb64} />
          </Icon>
        )}
      >
        {t('upload:button.addDocument')}
      </Button>
    </Tooltip>
  );
}
