import {
  Typography, Grid, Container,
} from '@material-ui/core';
import React from 'react';
import TransparentBackgroundWrapper from '../TransparentBackgroundWrapper';
import { DEFAULT_CONTAINER_WIDTH } from '../../utils/Constants';
import ButtonBack from '../ButtonBack';
import { useTranslation } from 'react-i18next';

const SettingsResetPassword: React.FunctionComponent = () => {
  const { t } = useTranslation(['common']);

  return (
    <Container maxWidth={DEFAULT_CONTAINER_WIDTH}>
      <TransparentBackgroundWrapper>
        <Grid container spacing={2}>

          <Grid item xs={12}>
            <ButtonBack goBackTo="settings" />
          </Grid>

          <Grid item>
            <Typography variant="h5">
              {t('common:login.password')}
            </Typography>
            <Typography variant="subtitle1">

              {t('common:settingsPage.passwordDescription')}

            </Typography>
          </Grid>

        </Grid>

      </TransparentBackgroundWrapper>
    </Container>
  );
};

export default SettingsResetPassword;
