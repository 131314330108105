/* eslint-disable */
// @ts-nocheck
import { Container, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DEFAULT_CONTAINER_WIDTH } from '../../utils/Constants';
import { ImpressumTextEnglish } from './ImpressumTextEnglish';
import { ImpressumTextGerman } from './ImpressumTextGerman';

const useStyles = makeStyles(() => ({
  container: {
    height: '100%',
  },
}));
export const ImpressumPage = () => {
  const classes = useStyles();
  const { i18n } = useTranslation('common');

  return (
    <Container maxWidth={DEFAULT_CONTAINER_WIDTH} className={classes.container}>
      {i18n.language === 'de' ? (
        <ImpressumTextGerman />
      ) : (
        <ImpressumTextEnglish />
      )}
    </Container>
  );
};
