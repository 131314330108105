import Axios, { AxiosRequestConfig } from 'axios';

export const defaultHeaders = {
  Accept: 'application/json',
  'Accept-Language': 'de-DU,de;q=0.9',
};

const baseUrl: string = `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_URL_APPENDIX}`;

export const request = (config: AxiosRequestConfig) => {
  const modConfig = config;
  modConfig.withCredentials = true;
  modConfig.headers = { ...defaultHeaders };
  modConfig.url = `${baseUrl}${modConfig.url}`;
  return Axios(modConfig);
};

export const get = async (url: string, config?: AxiosRequestConfig) =>
  request({
    method: 'get',
    url,
    ...config,
  });
export const put = async (
  url: string,
  data?: any,
  config?: AxiosRequestConfig
) =>
  request({
    method: 'put',
    url,
    data,
    ...config,
  });

export const post = async (
  url: string,
  data?: any,
  config?: AxiosRequestConfig
) =>
  request({
    method: 'post',
    url,
    data,
    ...config,
  });

export const patch = async (url: string, config?: AxiosRequestConfig) =>
  request({
    method: 'patch',
    url,
    ...config,
  });

export const deleteReq = async (url: string, config?: AxiosRequestConfig) =>
  request({
    method: 'delete',
    url,
    ...config,
  });
