import { Box, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import i18next from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import useStores from '../../hooks/use-stores';
import { MOOV_COLOR_BLUE } from '../../resources/colors';
import { DEFAULT_GRADIENT_NO_WHITE } from '../../utils/Constants';
import { RootFolderIconMapping } from '../../utils/DefaultRootFolders';
import { getLocalizedFolderName } from '../../utils/folderTranslations';

const useStyles = makeStyles(() => ({
  folderSectionRoundIcon: {
    borderRadius: '50%',
    background: DEFAULT_GRADIENT_NO_WHITE,
    height: 0,
    width: '100%',
    paddingBottom: '100%',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
  },
  icon: {
    position: 'absolute',
    bottom: '50%',
    transform: 'translateY(50%) scale(0.6)',
    height: 'auto',
    width: '100%',
  },
  folderLabel: {
    color: MOOV_COLOR_BLUE,
    textAlign: 'center',
  },
}));

type RootFolderProps = {
  id: string;
};

export const LargeHeaderFolder: React.FC<RootFolderProps> = observer(
  ({ id }) => {
    const {
      folderStore: { getChildById },
    } = useStores();

    const folder = getChildById(id);
    const classes = useStyles();

    const { language } = i18next;
    const folderInfo = RootFolderIconMapping.find(
      (icon) => folder?.icon === icon.apiIconId
    );
    const iconSource = folderInfo?.icon;

    const localizedHelpText =
      language === 'de' ? folderInfo?.helptext : folderInfo?.helptextEN;
    return (
      <Box
        width="100%"
        mb={3}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Box width={200}>
          <Box className={classes.folderSectionRoundIcon}>
            <img className={classes.icon} src={iconSource} alt="Icon" />
          </Box>
        </Box>

        <Box
          display="flex"
          justifyContent="center"
          p={1}
          flexDirection="column"
        >
          <Tooltip title={`${localizedHelpText}`}>
            <Typography variant="h4" className={classes.folderLabel}>
              {folder?.name &&
                getLocalizedFolderName(folder.name)?.toUpperCase()}
            </Typography>
          </Tooltip>
          <Typography variant="h5" className={classes.folderLabel}>
            {folder?.assets?.length && `${folder?.assets?.length}`}
          </Typography>
        </Box>
      </Box>
    );
  }
);
