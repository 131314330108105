import {
  Box,
  Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, Grid, makeStyles, TextField, Typography, useMediaQuery, useTheme,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import useStores from '../hooks/use-stores';
import {
  DEFAULT_BORDER_RADIUS, DEFAULT_GRADIENT_NO_WHITE, DEFAULT_GRID_SPACING,
} from '../utils/Constants';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
// import { ImageORTextPreview } from './ImageORTextPreview';

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: DEFAULT_BORDER_RADIUS,
  },
  button: {
    background: DEFAULT_GRADIENT_NO_WHITE,
    borderRadius: DEFAULT_BORDER_RADIUS,
  },
}));

const SearchInput: React.FC = observer(() => {
  const classes = useStyles();

  const theme = useTheme();
  const { t } = useTranslation(['common', 'validation']);

  const {
    searchStore: {
      search,
      searchResults,
      isSearchDialogOpen,
      setSearchDialogOpen,
    },

  } = useStores();

  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  function handleClose() {
    setSearchDialogOpen(false);
  }

  const history = useHistory();

  return (

    <Box>
      <Dialog
        fullScreen={fullScreen}
        fullWidth
        maxWidth="md"
        open={isSearchDialogOpen}
        onClose={handleClose}
        disableBackdropClick
        className={classes.root}
        scroll="paper"
      >
        <DialogTitle>
          {t('common:searchDialog.searchFor')}
          {' '}
          <Grid container direction="column" spacing={2}>

            <Grid item>
              <TextField
                id="keyword"
                variant="outlined"
                type="text"
                fullWidth
                autoFocus
                onChange={(e) => {
                  setTimeout(() => search(e.target.value), 250);
                }}
              />
            </Grid>

          </Grid>
        </DialogTitle>

        {/* START render search results  */}
        {searchResults && (
        <DialogContent dividers>

          <Grid container spacing={DEFAULT_GRID_SPACING} direction="column">
            { searchResults.length > 0 && (
            <Grid item>
              <Typography>

                {t('common:searchDialog.found')}
                {' '}
                {searchResults.length}
                {' '}
                {searchResults.length <= 1 ? t('common:searchDialog.asset') : t('common:searchDialog.assets')}

              </Typography>
            </Grid>
            ) }

            { searchResults.map((asset) => (

              <Grid item>
                <Card
                  elevation={5}
                  onClick={() => {
                    setSearchDialogOpen(false);
                    history.push(`/asset/${asset.id}`);
                  }}
                >
                  <CardContent>

                    <Grid container spacing={DEFAULT_GRID_SPACING}>

                      {/* START Attachment preview */}
                      <Grid item>
                        {/*         <Box
                          width={200}
                          height={200}
                          style={{
                            border: 'solid purple 1px',
                            background: PAPER_BG_COLOR,
                            flexShrink: 0,
                            borderRadius: DEFAULT_BORDER_RADIUS,
                          }}
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          alignContent="center"
                          onClick={() => history.push(`/asset/${asset.id}`)}
                        >
                          {asset.attachments
                            ? (
                              <ImageORTextPreview
                                attachment={asset.attachments[0]}
                                width={100}
                              />
                            ) : null}
                        </Box> */}
                      </Grid>

                      <Grid item>
                        <Grid container direction="column">
                          <Grid item>
                            <Typography variant="h5">
                              { `${asset.title}` }
                            </Typography>
                          </Grid>

                          <Grid item>
                            <Typography variant="subtitle1">
                              { `${asset.notes}` }
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>

                    </Grid>

                  </CardContent>

                </Card>

              </Grid>
            ))}
          </Grid>

        </DialogContent>
        )}
        {/* END render search results */}

        <DialogActions>
          <Button
            onClick={() => handleClose()}
            color="primary"
            size="large"
            startIcon={<CloseIcon />}
          >
            {t('common:general.cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
});
export default SearchInput;
