import { Grid, IconButton, Tooltip } from '@material-ui/core';
import CloudDownload from '@material-ui/icons/CloudDownload';
import DeleteIcon from '@material-ui/icons/Delete';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import useStores from '../../hooks/use-stores';
import DeleteConfirm from '../DeleteConfirm';

export const AttachmentTools = observer(() => {
  // Store
  const {
    userAssetStore: {
      deleteDocument,
      currentActiveAttachment,
      singleUserAsset,
      setAttachmentDialogOpen,
    },
  } = useStores();

  // Translation
  const { t } = useTranslation(['common']);

  const {
    attachment: { id, url },
  } = currentActiveAttachment;

  const [open, setOpen] = React.useState(false);

  const history = useHistory();

  return (
    <Grid
      container
      spacing={2}
      style={{
        justifyContent: 'flex-end',
      }}
    >
      <Grid item>
        <Tooltip title={t('common:detailPage.download')}>
          <IconButton
            color="primary"
            onClick={() => {
              window.location.href = url!;
            }}
          >
            <CloudDownload />
          </IconButton>
        </Tooltip>
      </Grid>

      <Grid item>
        <Tooltip title={t('common:detailPage.deleteDocument')}>
          <IconButton color="secondary" onClick={() => setOpen(true)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
        <DeleteConfirm
          open={open}
          description={t('common:deleteConfirmDialog.irreversibleDelete')}
          onConfirm={() =>
            deleteDocument(id!).then(() => {
              setAttachmentDialogOpen(false);
              toast.success(t('common:detailPage.deleteSuccess'));
              history.push(`/asset/${singleUserAsset.id}`);
            })
          }
          onClose={() => setOpen(false)}
        />
      </Grid>
    </Grid>
  );
});
