import { makeStyles, Paper, Theme } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { DEFAULT_BORDER_RADIUS, PAPER_BG_COLOR } from '../utils/Constants';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backdropFilter: 'blur(20px)',
    backgroundColor: PAPER_BG_COLOR,
    borderRadius: DEFAULT_BORDER_RADIUS,
    padding: theme.spacing(1),
  },
}));

interface Props {
  children: ReactNode,
}

function TransparentPaper({ children } : Props) {
  const classes = useStyles();
  return (
    <Paper className={classes.root}>
      {children}
    </Paper>
  );
}

export default TransparentPaper;
