import { observer } from 'mobx-react-lite';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import React from 'react';
import useStores from '../../hooks/use-stores';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { MOOV_COLOR_WHITE } from '../../resources/colors';
import Slide from '@material-ui/core/Slide';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { FilePond } from 'react-filepond';
import { FilePondFile } from 'filepond';
import { Button, FormHelperText, useMediaQuery } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import { CircularProgressWithLabel } from '../CircularProgressWithLabel';
import BackupIcon from '@material-ui/icons/Backup';
import { useTranslation } from 'react-i18next';
import { FormikValues, useFormik } from 'formik';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import {
  DEFAULT_BORDER_RADIUS,
  DEFAULT_GRADIENT_NO_WHITE,
  DEFAULT_GRID_SPACING,
} from "../../utils/Constants";
import { green } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    backgroundColor: MOOV_COLOR_WHITE,
    color: theme.palette.primary.main,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  button: {
    background: DEFAULT_GRADIENT_NO_WHITE,
    borderRadius: DEFAULT_BORDER_RADIUS,
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

// @ts-ignore
const Transition:any = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export const AddAttachmentDialog = observer(() => {
  const {
    userAssetStore: {
      isAddToAttachmentDialogOpen,
      setAddAttachmentDialogOpen,
      addAttachmentsToAsset,
      percentComplete,
      uploading,
    },
  } = useStores();

  const classes = useStyles();

  const { t } = useTranslation(['upload', 'validation', 'common']);

  const validationSchema = yup.object({
    files: yup
      .array()
      .min(1, t('upload:validation.noFiles'))
      .required(t('upload:validation.noFiles')),
  });

  const formik = useFormik({
    initialValues: {
      files: [],
    },
    validationSchema,
    onSubmit: ((values: FormikValues) => {
      addAttachmentsToAsset(values.files)
        .then(() => {
          setAddAttachmentDialogOpen(false);
          toast.success(t('upload:uploadSuccess'));
        })
        .finally(() => {
          formik.resetForm();
        });
    }),
  });

  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      open={isAddToAttachmentDialogOpen}
      onClose={() => setAddAttachmentDialogOpen(false)}
      TransitionComponent={Transition}
      fullScreen={fullScreen}
      maxWidth="sm"
      fullWidth
    >
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle id="responsive-dialog-title">
          {t('upload:dialog.title')}
        </DialogTitle>

        <DialogContent dividers>

          <DialogContentText>

            <Grid container direction="column" spacing={2}>

              <Grid item>
                <FilePond
                  onupdatefiles={(files) => {
                    // We do not want FilePondFile[] but File[], so extract them.
                    formik.setFieldValue(
                      'files',
                      files.map((i: FilePondFile) => i.file),
                      true,
                    );
                  }}
                  allowMultiple
                  maxFiles={100}
                  name="files"
                  labelIdle={`${t('upload:dndYourFilesOr')}
<span class="filepond--label-action">
${t('upload:choose')}</span>`}
                />
                {formik.touched.files && (
                  <FormHelperText
                    error={Boolean(formik.errors.files)}
                    component="span"
                  >
                    {formik.errors.files}
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Grid container justify="flex-end" spacing={DEFAULT_GRID_SPACING}>

            <Grid item>
              <Button
                onClick={() => setAddAttachmentDialogOpen(false)}
                color="primary"
                size="large"
                startIcon={<CloseIcon />}
              >
                {t('common:general.cancel')}
              </Button>
            </Grid>

            <Grid item>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                disabled={uploading}
                className={classes.button}
                startIcon={uploading ? (
                  <CircularProgressWithLabel
                    value={percentComplete}
                  />
                ) : (<BackupIcon />)}
              >
                {t('upload:upload')}
              </Button>
            </Grid>

          </Grid>
        </DialogActions>
      </form>

    </Dialog>
  );
});
