import { AxiosResponse } from 'axios';
import i18next from 'i18next';
import { makeAutoObservable, runInAction } from 'mobx';
import { handleError } from '../utils/ErrorUtils';
import { deleteReq, get, post } from '../utils/RequestManager';
import { tagSpecialties } from '../utils/tagSpecialties';

export class TagStore {
  tags: Array<UserAssetTag> = [];

  currentChangeUserAssetTag: Partial<UserAssetTag> = {};

  isAddTagDialogOpen = false; // TODO: Move to Dialog Store

  isChangeTagDialogOpen = false; // TODO: Move to Dialog Store.

  loading: boolean = false;

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
    this.getAllTags();
  }

  setAddTagDialogOpen(open: boolean) {
    this.isAddTagDialogOpen = open;
  }

  setChangeTagDialogOpen(open: boolean, userAssetTag?: UserAssetTag) {
    this.isChangeTagDialogOpen = open;
    if (userAssetTag) this.currentChangeUserAssetTag = userAssetTag;
  }

  async getAllTags(
    search?: string,
    withExtraTags?: boolean
  ): Promise<AxiosResponse> {
    try {
      runInAction(() => {
        this.loading = true;
      });
      let res: AxiosResponse;
      if (search) {
        const urlEncodedSearch = encodeURI(search);
        res = await get(`/tags?search=${urlEncodedSearch}`);
      } else {
        res = await get('/tags');
      }
      if (withExtraTags) {
        const savedLocale = i18next.language;
        let extraTags = tagSpecialties.map((tag) => ({
          id: `partial-${tag.key}`,
          name: savedLocale === 'de' ? tag.translationDE : tag.translationEN,
          style: null,
        }));

        if (search) {
          extraTags = extraTags.filter((tag) =>
            tag.name?.toLowerCase()?.includes(search?.toLowerCase()));
        }

        res.data = [...res.data, ...extraTags];

        // remove duplicates by tag name
        res.data = res.data.filter(
          (tag: any, index: number, self: any) =>
            index === self.findIndex((t: any) => t.name === tag.name)
        );
      }
      runInAction(() => {
        this.tags = res.data;
        this.loading = false;
      });
      return res;
    } catch (e: any) {
      return handleError(e);
    }
  }

  // TODO: Maybe this isn't even needed.
  async getTag(id: string): Promise<AxiosResponse> {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const res: AxiosResponse = await get(`/tags/${id}`);
      runInAction(() => {
        this.loading = false;
      });
      return res;
    } catch (e: any) {
      return handleError(e);
    }
  }

  async createTag(userAssetTag: Partial<UserAssetTag>): Promise<AxiosResponse> {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const res: AxiosResponse = await post('/tags', userAssetTag);
      runInAction(() => {
        this.loading = false;
        this.getAllTags();
      });
      return res;
    } catch (e: any) {
      return handleError(e);
    }
  }

  async changeTag(userAssetTag: Partial<UserAssetTag>): Promise<AxiosResponse> {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const res: AxiosResponse = await post(
        `/tags/${userAssetTag.id}`,
        userAssetTag
      );
      runInAction(() => {
        this.loading = false;
        this.getAllTags();
        this.currentChangeUserAssetTag = {}; // Clean the Tag to change.
      });
      return res;
    } catch (e: any) {
      return handleError(e);
    }
  }

  /**
   * Delete a Tag
   * If force is enabled, tags are removed from the assets.
   * @param id
   * @param force
   */
  async deleteTag(id: string, force: boolean = true): Promise<AxiosResponse> {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const url = force ? `/tags/${id}?force=true` : `/tags/${id}`;
      const res: AxiosResponse = await deleteReq(url);
      runInAction(() => {
        this.loading = false;
        this.getAllTags();
      });
      return res;
    } catch (e: any) {
      return handleError(e);
    }
  }
}

export type UserAssetTag = {
  id: string;
  name: string;
  style: any;
};
