import {
  Button,
  Collapse,
  Grid,
  makeStyles,
  TextField,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import BuildIcon from '@material-ui/icons/Build';
import DoneIcon from '@material-ui/icons/Done';
import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab';
import { FormikValues, useFormik } from 'formik';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import 'moment/locale/de';
import React, { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import useStores from '../../hooks/use-stores';
import { FolderLabels } from '../../models/FolderLabels';
import { UserAsset } from '../../models/UserAssetModel';
import { MOOV_COLOR_BLUE, MOOV_COLOR_WHITE } from '../../resources/colors';
import {
  DEFAULT_BORDER_RADIUS,
  DEFAULT_GRADIENT_NO_WHITE,
  DEFAULT_GRID_SPACING,
} from '../../utils/Constants';
import { getLocalizedFolderName } from '../../utils/folderTranslations';
import { TagCreatableBox } from '../TagCreatableBox';

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: DEFAULT_BORDER_RADIUS + 10,
    backgroundColor: MOOV_COLOR_WHITE,
    color: MOOV_COLOR_BLUE,
    padding: 15,
  },
  button: {
    background: DEFAULT_GRADIENT_NO_WHITE,
    borderRadius: DEFAULT_BORDER_RADIUS,
  },
}));

const DetailPageForm: FunctionComponent = observer(() => {
  const {
    userAssetStore: { singleUserAsset, updateDocument },
    folderStore: { folderLabels, getFolderLabels },
    tagStore: { createTag },
  } = useStores();

  const { t } = useTranslation(['common']);

  const classes = useStyles();

  const validationSchema = yup.object({
    title: yup.string(),
    date: yup.string(),
    parentFolderId: yup.string(),
    notes: yup.string(),
    tags: yup.array(),
  });

  const formik = useFormik({
    initialValues: {
      title: singleUserAsset.title,
      date: singleUserAsset.created,
      parentFolderId: singleUserAsset.parentFolderId,
      notes: singleUserAsset.notes,
      tags: singleUserAsset.tags,
    },
    validationSchema,
    onSubmit: async (values: FormikValues) => {
      // Get id, title, notes and form a new object
      const { id } = singleUserAsset;
      const { title, notes, parentFolderId, tags } = values;
      // Treat the new Object as a Partial UserAsset
      const updatedUserAsset = {
        id,
        title,
        notes,
        parentFolderId,
        tags,
      } as UserAsset;
      // find tags with an id that starts by "partial"
      const partialTags = updatedUserAsset.tags.filter((tag) =>
        tag.id?.startsWith('partial')
      );

      // remove partial tags from the form values
      updatedUserAsset.tags = updatedUserAsset.tags.filter(
        (tag) => !tag.id?.startsWith('partial')
      );

      if (partialTags.length > 0) {
        for (const tag of partialTags) {
          const newTag = await createTag({ name: String(tag?.name) });
          // push the new tag to the form values
          updatedUserAsset.tags.push(newTag.data);
        }
      }
      // Update
      updateDocument(updatedUserAsset).then(() => {
        toast.success(t('common:detailPage.updateSuccess'));
      });
    },
  });

  const autocompleteOptions = toJS(folderLabels?.children!);

  const originalFolder = autocompleteOptions
    ? (autocompleteOptions.find(
        (z) => z?.id === formik.values.parentFolderId
      ) as FolderLabels)
    : undefined;

  useEffect(() => {
    getFolderLabels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [checked, setChecked] = React.useState(false);

  return (
    <>
      <Box
        width="100%"
        my={DEFAULT_GRID_SPACING}
        display="flex"
        justifyContent="center"
        onClick={() => setChecked(!checked)}
      >
        <Button
          variant="contained"
          color="primary"
          size="large"
          startIcon={<BuildIcon />}
          className={classes.button}
        >
          {t('common:detailPage.editAsset')}
        </Button>
      </Box>
      <Collapse in={checked}>
        <form onSubmit={formik.handleSubmit} autoComplete="off">
          <Grid
            container
            spacing={1}
            direction="row"
            alignItems="center"
            justify="center"
            xs={12}
          >
            <Grid item xs={12} md={4}>
              <TextField
                placeholder={singleUserAsset.title}
                label={t('common:detailPage.title')}
                type="text"
                variant="outlined"
                fullWidth
                id="title"
                value={formik.values.title}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.title && Boolean(formik.errors.title)}
                helperText={formik.touched.title && formik.errors.title}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Autocomplete
                options={autocompleteOptions}
                getOptionLabel={(option: FolderLabels) =>
                  option?.name! ? getLocalizedFolderName(option.name) : ''
                }
                getOptionSelected={(option, value) =>
                  option?.name === value?.name
                }
                value={originalFolder}
                fullWidth
                onChange={(
                  event: React.ChangeEvent<{}>,
                  value: FolderLabels | null
                ) => {
                  formik.setFieldValue('parentFolderId', value?.id);
                }}
                renderInput={(params: AutocompleteRenderInputParams) => (
                  <TextField
                    {...params}
                    label={t('common:detailPage.chooseCategory')}
                    variant="outlined"
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                label={t('common:detailPage.notes')}
                type="text"
                variant="outlined"
                multiline
                fullWidth
                rowsMax="6"
                id="notes"
                value={formik.values.notes}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.notes && Boolean(formik.errors.notes)}
                helperText={formik.touched.notes && formik.errors.notes}
              />
            </Grid>
            <Grid item />

            <Grid item xs={12} md={12}>
              <TagCreatableBox
                preSelectedTags={formik.values.tags}
                formik={{
                  autocompleteOnBlur: formik.handleBlur,
                  autocompleteSetFieldValue: (field, value) =>
                    formik.setFieldValue(field, value),
                  textfieldError: Boolean(formik.errors.tags),
                  textfieldFormHelperText: formik.errors.tags as string,
                  selectedCategory: folderLabels?.children?.find(
                    (folder) => folder?.id === formik.values.parentFolderId
                  )?.name!,
                }}
              />
            </Grid>

            <Grid item>
              <Button
                type="submit"
                color="primary"
                variant="outlined"
                startIcon={<DoneIcon />}
                disabled={!formik.dirty}
                size="large"
              >
                {t('common:detailPage.save')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Collapse>
    </>
  );
});

export default DetailPageForm;
