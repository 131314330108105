import React, { FunctionComponent } from 'react';

import { Box, Grid, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';

import { useTranslation } from 'react-i18next';
import useStores from '../../hooks/use-stores';
import { Attachment } from '../../models/UserAssetModel';
import { DEFAULT_BORDER_RADIUS, DEFAULT_GRID_SPACING, PAPER_BG_COLOR } from '../../utils/Constants';
import { ImageORTextPreview } from '../ImageORTextPreview';

const AttachmentThumbnailGrid: FunctionComponent = observer(() => {
  const { t } = useTranslation(['common']);

  const {
    userAssetStore: {
      singleUserAsset,
      setAttachmentDialogOpen,
    },
  } = useStores();

  const { attachments } = singleUserAsset;

  if (!attachments) {
    return (
      <Grid
        container
        spacing={DEFAULT_GRID_SPACING}
        justify="center"
      >
        <Grid item>
          <Typography>{t('common:general.noItems')}</Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid
      container
      justify="center"
    >

      {attachments && attachments.map((attachment: Attachment, index) => (
        <Grid item>
          <Box p={2}>

            <Box
              onClick={() => setAttachmentDialogOpen(true, index, attachment)}
              key={attachment.id}
              width={200}
              height={200}
              style={{
                background: PAPER_BG_COLOR,
                flexShrink: 0,
                borderRadius: DEFAULT_BORDER_RADIUS,
              }}
              display="flex"
              flexWrap="none"
              justifyContent="center"
              alignItems="center"
              alignContent="center"
              mb={1}
            >

              <ImageORTextPreview attachment={attachment} width={200} />

            </Box>

            <Box
              width={200}
              height="auto"
              display="flex"
              justifyContent="center"
            >
              <Typography
                variant="subtitle2"
                noWrap
              >
                {attachment.fileName}

              </Typography>
            </Box>
          </Box>
        </Grid>

      ))}

    </Grid>
  );
});

export default AttachmentThumbnailGrid;
