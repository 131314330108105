import {
  Container, makeStyles, Paper, Theme, Typography,
} from '@material-ui/core';
import React from 'react';
import { observer } from 'mobx-react-lite';
import { DEFAULT_BORDER_RADIUS, DEFAULT_CONTAINER_WIDTH, PAPER_BG_COLOR } from '../utils/Constants';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    backdropFilter: 'blur(20px)',
    backgroundColor: PAPER_BG_COLOR,
    borderRadius: DEFAULT_BORDER_RADIUS,
    padding: theme.spacing(1),
    width: '100%',
  },
}));

// Reactive Component using observer
const HomePage = observer(() => {
  // define the style classes
  const classes = useStyles();

  return (
    <Container maxWidth={DEFAULT_CONTAINER_WIDTH} className={classes.container}>
      <Paper className={classes.paper} elevation={0}>
        <Typography variant="h3">
          Dies ist die Profilseite.
        </Typography>
      </Paper>
    </Container>
  );
});

export default HomePage;
