import Languages from './Languages';

export enum LoginState {
  initial = 'inital',
  login = 'login',
  register = 'register',
  emailVerification = 'emailVerification',
  final = 'final',
  logoutSuccess = 'logoutSuccess',
  emailVerificationSent = 'emailVerificationSent',
  resetPassword = 'resetPassword',
  resetPasswordMailSent = 'resetPasswordMailSent',
}

export interface CredentialRequestData {
  email: string,
  passwordSha256: string,
}
export class LoginRequestData implements CredentialRequestData {
  email: string;

  passwordSha256: string;

  rememberMe: boolean;

  constructor(email: string, passwordSha256: string, rememberMe: boolean) {
    this.email = email;
    this.passwordSha256 = passwordSha256;
    this.rememberMe = rememberMe;
  }
}

export class RegisterRequestData implements CredentialRequestData {
  email: string;

  passwordSha256: string;

  // TODO: Automatic language detection. Leave german for now.
  language: Languages = Languages.german;

  constructor(email: string, passwordSha256: string) {
    this.email = email;
    this.passwordSha256 = passwordSha256;
  }
}

export interface LoginResponse {
  otpRequired: boolean;
  emailVerificationRequired: boolean;
}

export class LoginResponseData implements LoginResponse {
  constructor(emailVerificationRequired: boolean, otpRequired: boolean) {
    this.emailVerificationRequired = emailVerificationRequired;
    this.otpRequired = otpRequired;
  }

  emailVerificationRequired: boolean;

  otpRequired: boolean;
}

export interface RegisterResponse {
  success: boolean,
  message: string,
}

export enum LoginErrorState {
  general,
  registerFailed,
  loginFailed,
  none,
  logoutFailed,
  resendVerificationMailFailed,
}

export interface LoginErrorInterface {
  state: LoginErrorState,
  message?: string | undefined,
}

export class LoginError implements LoginErrorInterface {
  message?: string | undefined;

  state: LoginErrorState;

  constructor(state: LoginErrorState, message?: string) {
    this.message = message;
    this.state = state;
  }
}
