import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { Swiper, SwiperSlide } from 'swiper/react';
import useStores from '../../hooks/use-stores';
import { observer } from 'mobx-react-lite';
import AttachmentPreview from './AttachmentPreview';
import Box from '@material-ui/core/Box';
import { Attachment } from '../../models/UserAssetModel';
import { AttachmentTools } from './AttachmentTools';
import { MOOV_COLOR_WHITE } from '../../resources/colors';
import Grid from '@material-ui/core/Grid';
import { DialogContent, useMediaQuery } from '@material-ui/core';

import 'swiper/swiper-bundle.css';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    backgroundColor: MOOV_COLOR_WHITE,
    color: theme.palette.primary.main,
  },
  title: {
    marginLeft: theme.spacing(2),
    whiteSpace: 'nowrap',
    maxWidth: 500,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    minWidth: 10,
  },
  titleBox: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    minWidth: 10,
  },
}));

// @ts-ignore
const Transition: any = React.forwardRef((props, ref) => (
  // @ts-ignore
  <Slide direction="up" ref={ref} {...props} />
));

const AttachmentPreviewDialog = observer(() => {
  const {
    userAssetStore: {
      isAttachmentDialogOpen,
      setAttachmentDialogOpen,
      singleUserAsset,
      currentActiveAttachment,
      setCurrentActiveAttachment,
    },
  } = useStores();

  const { attachments } = singleUserAsset;

  const classes = useStyles();

  // const activeIndex = attachments?.findIndex((attachment) => attachment.id === currentActiveAttachment.id);

  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      maxWidth="md"
      open={isAttachmentDialogOpen}
      onClose={() => setAttachmentDialogOpen(false)}
      TransitionComponent={Transition}
    >
      <AppBar
        className={classes.appBar}
        elevation={3}
        style={{ paddingRight: 0 }}
      >
        <Toolbar>
          <Grid
            container
            justify="space-between"
            direction="row"
            alignItems="center"
            wrap="nowrap"
          >
            <Grid item zeroMinWidth xs>
              <Grid container alignItems="center">
                <Grid item>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={() => setAttachmentDialogOpen(false)}
                    aria-label="close"
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
                <Grid item xs className={classes.titleBox}>
                  <Typography variant="h6" className={classes.title}>
                    {currentActiveAttachment.attachment.fileName}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <AttachmentTools />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      {attachments && (
        <DialogContent>
          {/* SWIPER MAIN */}
          <Box p={2}>
            <Swiper
              id="main"
              initialSlide={currentActiveAttachment.index}
              onSwiper={(swiper) => {
                if (currentActiveAttachment.index) {
                  swiper.slideTo(currentActiveAttachment.index, 10, undefined);
                  setCurrentActiveAttachment(
                    swiper.activeIndex,
                    attachments[swiper.activeIndex]
                  );
                }
              }}
              onSlideChange={(swiper) => {
                setCurrentActiveAttachment(
                  swiper.activeIndex,
                  attachments[swiper.activeIndex]
                );
              }}
              spaceBetween={50}
              slidesPerView={1}
              style={{
                width: '100%',
                height: '100%',
              }}
            >
              {attachments &&
                attachments.map((attachment: Attachment) => (
                  <SwiperSlide>
                    <AttachmentPreview attachment={attachment} />
                  </SwiperSlide>
                ))}
            </Swiper>
          </Box>
        </DialogContent>
      )}
    </Dialog>
  );
});
export default AttachmentPreviewDialog;
