import { Box, Button, Grid } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import { makeStyles } from '@material-ui/core/styles';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { observer } from 'mobx-react-lite';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useStores from '../hooks/use-stores';
import IconMenuAnwendungen from '../resources/vector/Icon-Menu-Anwendungen.svg';
import { DEFAULT_BORDER_RADIUS } from '../utils/Constants';
// import IconMenuHilfe from '../resources/vector/Icon-Menu-Hilfe.svg';
import IconMenuSuche from '../resources/vector/Icon-Menu-Suche.svg';
// import IconMenuProfil from '../resources/vector/Icon-Menu-Profil.svg';
import IconMenuFeedback from '../resources/vector/Icon-Menu-Feedback.svg';
import CustomSvgIcon from './CustomSvgIcon';
import LanguageSwitcher from './LanguageSwitcher';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
  },
  container: {
    display: 'flex',
  },
  paper: {
    margin: theme.spacing(1),
  },
  iconRoot: {
    textAlign: 'center',
  },
  imageIcon: {
    display: 'flex',
    height: 'inherit',
    width: 'inherit',
  },
}));

type NavbarProps = {
  open: boolean;
};

const NavbarMenu: FunctionComponent<NavbarProps> = observer(({ open }) => {
  const classes = useStyles();

  const {
    moovyAuthStore: { logout, isAuthenticated },
    dialogStore: { setFeedbackDialogOpen },
    searchStore: { setSearchDialogOpen },
  } = useStores();

  const history = useHistory();

  const { t } = useTranslation(['common']);

  const xs = 12;
  const sm = 6;

  return (
    <Collapse in={open} className={classes.root}>
      <Box p={2} style={{ display: 'flex', justifyContent: 'end' }}>
        <Grid container justify="center">
          {isAuthenticated && (
            <Grid item xs={xs} sm={sm}>
              <Button
                onClick={() => setFeedbackDialogOpen(true)}
                color="primary"
                variant="text"
                style={{ borderRadius: DEFAULT_BORDER_RADIUS }}
                startIcon={<CustomSvgIcon src={IconMenuFeedback} />}
              >
                {t('common:navbar.feedback')}
              </Button>
            </Grid>
          )}

          {isAuthenticated && (
            <Grid item xs={xs} sm={sm}>
              <Button
                color="primary"
                variant="text"
                style={{ borderRadius: DEFAULT_BORDER_RADIUS }}
                startIcon={<CustomSvgIcon src={IconMenuAnwendungen} />}
                onClick={() => history.push('/settings')}
              >
                {t('common:navbar.settings')}
              </Button>
            </Grid>
          )}

          {isAuthenticated && (
            <Grid item xs={xs} sm={sm}>
              <Button
                onClick={() => setSearchDialogOpen(open)}
                color="primary"
                variant="text"
                style={{ borderRadius: DEFAULT_BORDER_RADIUS }}
                startIcon={<CustomSvgIcon src={IconMenuSuche} />}
              >
                {t('common:navbar.search')}
              </Button>
            </Grid>
          )}

          {isAuthenticated && (
            <Grid item xs={xs} sm={sm}>
              <Button
                color="primary"
                variant="text"
                style={{ borderRadius: DEFAULT_BORDER_RADIUS }}
                startIcon={<ExitToAppIcon />}
                onClick={() => {
                  logout().then(() => history.replace('/login'));
                }}
              >
                {t('common:login.logout')}
              </Button>
            </Grid>
          )}
          <Grid item xs={xs} sm={sm} style={{ padding: 4 }}>
            <LanguageSwitcher />
          </Grid>
        </Grid>
      </Box>
    </Collapse>
  );
});

export default NavbarMenu;
