import { Icon, makeStyles } from '@material-ui/core';

import React, { FunctionComponent } from 'react';

const useStyles = makeStyles(() => ({
  iconRoot: {
    textAlign: 'center',
  },
  imageIcon: {
    display: 'flex',
    height: 'inherit',
    width: 'inherit',
  },
}));

type CustomSvgIconProps = {
  src: string;
};

const CustomSvgIcon:FunctionComponent<CustomSvgIconProps> = ({ src }) => {
  const classes = useStyles();
  return (
    <Icon className={classes.iconRoot}>
      <img className={classes.imageIcon} alt="ButtonIcon" src={src} />
    </Icon>
  );
};

export default CustomSvgIcon;
