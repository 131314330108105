import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_BORDER_RADIUS } from '../utils/Constants';

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: DEFAULT_BORDER_RADIUS,
  },

  button: {
    borderRadius: DEFAULT_BORDER_RADIUS,
  },
}));

type DeleteConfirmProps = {
  description?: string,
  onConfirm: Function,
  open: boolean,
  onClose: Function,
};

const DeleteConfirm:React.FC<DeleteConfirmProps> = observer((
  { description,
    onConfirm,
    open,
    onClose,
  },
) => {
  const classes = useStyles();

  const { t } = useTranslation(['common']);

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      disableBackdropClick
    >

      <DialogTitle
        style={{
          textAlign: 'center',
        }}
      >
        {t('common:deleteConfirmDialog.assureDelete')}
        ?
      </DialogTitle>

      <DialogContent style={{
        textAlign: 'center',
      }}
      >
        {description}
      </DialogContent>

      <DialogActions>
        <Grid
          container
          justify="flex-end"
          spacing={1}
        >
          <Grid item>
            <Button
              color="secondary"
              variant="outlined"
              className={classes.button}
              onClick={() => {
                onConfirm();
              }}
            >
              {t('common:general.yes')}
            </Button>
          </Grid>

          <Grid item>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => onClose()}
            >
              {t('common:general.no')}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>

    </Dialog>

  );
});
export default DeleteConfirm;
