import { CircularProgress, Container, Paper } from '@material-ui/core';
import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import Box from '@material-ui/core/Box';
import { useHistory, useLocation } from 'react-router';
import useStores from '../hooks/use-stores';
import { LoginState } from '../models/LoginModels';
import InitialForm from '../components/LoginPage/InitialForm';
import { useLoginPageStyles } from '../components/LoginPage/LoginPageStyles';
import LoginForm from '../components/LoginPage/LoginForm';
import RegisterForm from '../components/LoginPage/RegisterForm';
import EMailVerificationNotice from '../components/LoginPage/EMailVerificationNotice';
import EMailVerificationSentNotice from '../components/LoginPage/EMailVerificationSentNotice';
import LogoutSuccess from '../components/LoginPage/LogoutSuccess';
import LogoWeiss from '../resources/vector/Logo-weiss.svg';
import ResetPasswordForm from '../components/LoginPage/ResetPasswordForm';
import { DEFAULT_CONTAINER_WIDTH } from '../utils/Constants';
import ResetPasswordSentNotice from '../components/LoginPage/ResetPasswordSentNotice';
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

// Reactive Component using observer
const LoginPage:React.FC = observer(() => {
  // define the style classes
  const classes = useLoginPageStyles();

  // provide a store
  const {
    moovyAuthStore: {
      step,
      loading,
      error,
      isAuthenticated,
      setStep,
      setMail,
    },
  } = useStores();

  const history = useHistory();
  const location = useLocation();

  const { t } = useTranslation(['common']);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(document.location.search);
    if (urlSearchParams.has('message')
      && urlSearchParams.has('email')
      && urlSearchParams.has('messageType')) {
      const message: string = urlSearchParams.get('message') ?? '';
      const messageType: string | null = urlSearchParams.get('messageType');
      if (messageType === 'SUCCESS') {
        toast.success(t('common:login.emailVerificationSuccess'));
        setMail(urlSearchParams.get('email') as string);
        setStep(LoginState.login);
      }
      if (messageType === 'ERROR') {
        toast.error(`${t('common:login.emailVerificationError')}\n${message}`);
        setStep(LoginState.initial);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // @ts-ignore
  const { from } = location.state ?? '';
  if (isAuthenticated) {
    // When on Login Page go directly to home and not login again (in a loop)
    if (location.pathname === '/login' && from !== undefined) history.replace(from);
    else history.replace('/home'); // Coming from somewhere else then /login
  }

  return (
    <Container maxWidth={DEFAULT_CONTAINER_WIDTH} className={classes.container}>
      <Box className={classes.logo}>
        <img className={classes.image} src={LogoWeiss} alt="Logo" />
      </Box>
      <Paper className={classes.paper} elevation={0}>
        {!loading
          ? (
            <Box className={classes.form}>
              {step === LoginState.initial && <InitialForm />}
              {step === LoginState.login && <LoginForm />}
              {step === LoginState.register && <RegisterForm />}
              {step === LoginState.emailVerification && <EMailVerificationNotice />}
              {step === LoginState.emailVerificationSent && <EMailVerificationSentNotice />}
              {step === LoginState.logoutSuccess && <LogoutSuccess />}
              {step === LoginState.resetPassword && <ResetPasswordForm />}
              {step === LoginState.resetPasswordMailSent && <ResetPasswordSentNotice />}
              <Box>
                {error?.message}
              </Box>
            </Box>
          )
          : (
            <Box className={classes.form} display="flex" justifyContent="center" p={2}>
              <CircularProgress size={150} />
            </Box>
          )}
      </Paper>
    </Container>
  );
});

export default LoginPage;
