// Please don't touch this file.
// For highest level changes, use App.tsx

/* eslint-disable no-multiple-empty-lines */







































import { enableLogging } from 'mobx-logger';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/App';
import './index.css';

// enable mobx logging
enableLogging();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

