import i18next from 'i18next';

export const getLocalizedFolderName = (folderName: string): string => {
  const currentLanguage = i18next.language;
  console.log(folderName);
  const translatedNamesEnToDe = {
    Befunde: 'Findings',
    Bilder: 'Images',
    Medikamente: 'Medications',
    Moovies: 'Moovies',
    'Pässe / Ausweise': 'Passports / IDs',
    Kommunikation: 'Communication',
    Finanzen: 'Finances',
    'Medizinisches Netzwerk': 'Medical Network',
    'Juristische Dokumente': 'Legal Documents',
    'Medizinische Bibliothek': 'Medical Library',
    'Notfall-Infos': 'Emergency Information',
  };
  const translatedNamesDeToEn = {
    'Medical Results': 'Befunde',
    Images: 'Bilder',
    Drugs: 'Medikamente',
    Moovies: 'Moovies',
    'ID Cards': 'Pässe / Ausweise',
    Communication: 'Kommunikation',
    Finance: 'Finanzen',
    'Medical Network': 'Medizinisches Netzwerk',
    'Legal Documents': 'Juristische Dokumente',
    'Medical Library': 'Medizinische Bibliothek',
    'Live Safer': 'Notfall-Infos',
  };
  const typedFolderNameEnToDe =
    folderName as keyof typeof translatedNamesEnToDe;
  const typedFolderNameDeToEn =
    folderName as keyof typeof translatedNamesDeToEn;
  const folderIsInEnglish = Object.keys(translatedNamesDeToEn).includes(
    folderName
  );
  const folderIsInGerman = Object.keys(translatedNamesEnToDe).includes(
    folderName
  );
  if (currentLanguage === 'de') {
    if (folderIsInEnglish) {
      return translatedNamesDeToEn[typedFolderNameDeToEn];
    }
    return folderName;
  }
  if (currentLanguage === 'en') {
    if (folderIsInGerman) {
      return translatedNamesEnToDe[typedFolderNameEnToDe];
    }
    return folderName;
  }

  return folderName;
};
