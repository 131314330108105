import React, { useEffect } from 'react';
import useStores from '../../hooks/use-stores';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { observer } from 'mobx-react-lite';
import { AddTagDialog } from './AddTagDialog';
import { TagList } from './TagList';
import { ChangeTagDialog } from "./ChangeTagDialog";
import { DEFAULT_GRID_SPACING } from "../../utils/Constants";

export const TagSettings:React.FC = observer(() => {
  const {
    tagStore: {
      getAllTags,
      tags,
      setAddTagDialogOpen,
    },
  } = useStores();

  const { t } = useTranslation(['common']);

  useEffect(() => {
    getAllTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container spacing={DEFAULT_GRID_SPACING} direction="column">
      <AddTagDialog />
      <ChangeTagDialog />

      <Grid item>
        <Grid container spacing={DEFAULT_GRID_SPACING}>
          <Grid item>
            <Button variant="outlined" onClick={() => setAddTagDialogOpen(true)}>{t('common:tags.addTag')}</Button>
          </Grid>

        </Grid>
      </Grid>
      <Grid item>
        {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
        <TagList tags={tags} />
      </Grid>
    </Grid>
  );
});
