import React from 'react';
import {
  Container, Grid, Typography,
} from '@material-ui/core';
import { DEFAULT_CONTAINER_WIDTH } from '../../utils/Constants';
import TransparentBackgroundWrapper from '../TransparentBackgroundWrapper';
import ButtonBack from '../ButtonBack';
import { SettingCard } from './SettingCard';
import { useTranslation } from 'react-i18next';

export const SettingsOverview: React.FunctionComponent = () => {
  const { t } = useTranslation(['common']);

  return (

    <Container maxWidth={DEFAULT_CONTAINER_WIDTH}>
      <TransparentBackgroundWrapper>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ButtonBack goBackTo="home" />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h2">
              {t("common:settingsPage.settings")}
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
          >
            <Grid container spacing={2}>
              {/* <SettingCard
                settingId="resetPassword"
                title={`${t('common:settingsPage.password')}`}
                description={`${t('common:settingsPage.passwordDescription')}`}
              /> */}
              <SettingCard
                settingId="tags"
                title={t('common:tags.tagSettings')}
                description={t('common:tags.tagSettingsDescription')}
              />
              <SettingCard
                settingId="profile"
                title={t('common:profile.userProfile')}
                description={t('common:profile.description')}
              />
            </Grid>
          </Grid>

        </Grid>
      </TransparentBackgroundWrapper>
    </Container>
  );
};
