import {
  Button, Grid, Link as MuiLink, Typography,
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useStores from '../../hooks/use-stores';
import { useLoginPageStyles } from './LoginPageStyles';
import { LoginState } from '../../models/LoginModels';

export default function EMailVerificationNotice() {
  const classes = useLoginPageStyles();

  const {
    moovyAuthStore: {
      mail,
      resendVerificationEmail,
      setStep,
    },
  } = useStores();

  const { t } = useTranslation(['common', 'validation', 'error']);

  return (
    <Grid
      container
      spacing={2}
      justify="center"
      alignItems="center"
      direction="column"
    >

      <Grid item>
        <Typography variant="h5">
          {t('common:login.emailVerification')}
        </Typography>
      </Grid>

      <Grid item style={{ textAlign: 'center' }}>
        <Typography paragraph variant="body2">
          {t('common:login.emailVerificationTextHeader')}
        </Typography>
        <Typography paragraph variant="h4">
          {mail}
        </Typography>
        <Typography paragraph variant="body2">
          {t('common:login.emailVerificationTextExplain')}
        </Typography>
      </Grid>

      <Grid item>
        <Button
          className={classes.button}
          disableElevation
          type="submit"
          size="large"
          variant="contained"
          color="primary"
          fullWidth
          onClick={resendVerificationEmail}
        >
          {t('common:login.sendVerficationMailAgain')}
        </Button>
      </Grid>

      <Grid item>
        <MuiLink onClick={() => setStep(LoginState.initial)}>
          {t('common:login.loginLabel')}
        </MuiLink>
      </Grid>
    </Grid>
  );
}
