import { toast } from 'react-toastify';
import React from 'react';
import _ from 'lodash-es';

export type ErrorConfig = {
  showToast: boolean;
};

const errors: Array<Partial<Error>> = [];

export const handleError = (
  error: Error,
  config: ErrorConfig = {
    showToast: true,
  }
) => {
  errors.push(error);
  if (config.showToast && !error.message.includes('401')) {
    toast.dark(
      <>
        <b>Fehler:</b>{' '}
        <b>
          <i>{error.name}</i>
        </b>{' '}
        <pre>{error.message}</pre>
      </>,
      {
        autoClose: 2000,
        toastId: 'error', // prevent duplicate
      }
    );
  }
  if (config.showToast && error.message.includes('401')) {
    const deb = _.debounce(() => {
      toast.info(
        <>
          <b>Nicht angemeldet.</b>
        </>,
        {
          toastId: 'not-logged-in',
        }
      );
    }, 1000);
    deb();
  }
  throw error;
};
