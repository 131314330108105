import React, { FunctionComponent } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

type CircularProgressWithLabelProps = {
  value: number;
};

export const CircularProgressWithLabel:
FunctionComponent<CircularProgressWithLabelProps> = ({ value, ...props }) => (
  <Box position="relative" display="inline-flex">
    <CircularProgress size={20} style={{ color: 'white' }} variant="determinate" {...props} />
    <Box
      top={0}
      left={0}
      bottom={0}
      right={0}
      position="absolute"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Typography variant="caption" component="div" style={{ color: 'white' }}>
        {`${Math.round(
          value,
        )}%`}
      </Typography>
    </Box>
  </Box>
);
