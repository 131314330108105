/* eslint-disable react-hooks/exhaustive-deps */
import { observer } from 'mobx-react-lite';
import { Box, Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import useStores from '../../hooks/use-stores';
import { RootFolder } from './RootFolder';
import { ChildFolder } from "../../models/Folders";
import { DEFAULT_GRID_SPACING } from "../../utils/Constants";
import { useTranslation } from "react-i18next";
import { CenteredSectionHeadline } from "../CenteredSectionHeadline";

export const RootFolderSection = observer(() => {
  const {
    folderStore: {
      folders,
      getFolders,
    },
  } = useStores();

  useEffect(() => {
    getFolders();
  }, []);

  const { t } = useTranslation(['common']);

  return (
    <Box>
      <Grid container justify="center" spacing={DEFAULT_GRID_SPACING}>
        <Grid item xs={12}>
          <CenteredSectionHeadline string={t('common:categoryOverview.categoryTitle')} />
        </Grid>
        {folders.children?.map((folder: ChildFolder) => <RootFolder folder={folder} />)}
      </Grid>
    </Box>
  );
});
