import React from 'react';
import {
  createMuiTheme,
  CssBaseline,
  MuiThemeProvider,
  responsiveFontSizes,
} from '@material-ui/core';
import { ToastContainer, Zoom } from 'react-toastify';
import PageContainer from './PageContainer';
import './i18next';
import { theme } from '../resources/theme';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

function App() {
  let muiTheme = createMuiTheme(theme);
  muiTheme = responsiveFontSizes(muiTheme);

  // Setup global moment.js i18n
  const { t } = useTranslation(['common']);
  const locale = t('common:detailPage.locale');
  moment.locale(locale);

  return (
    <MuiThemeProvider theme={muiTheme}>
      <CssBaseline />
      <ToastContainer
        position="bottom-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Zoom}
        limit={3}
      />
      <PageContainer />
    </MuiThemeProvider>
  );
}

export default App;
