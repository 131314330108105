import { Button } from '@material-ui/core';
import React from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

type ButtonBackProps = {
  goBackTo?: string,
};

const ButtonBack: React.FunctionComponent<ButtonBackProps> = ({ goBackTo }) => {
  const history = useHistory();
  const { t } = useTranslation(['common']);

  return (
    <Button
      size="large"
      variant="text"
      color="primary"
      onClick={() => {
        if (goBackTo) {
          history.push(`/${goBackTo}`);
        } else {
          history.goBack();
        }
      }}
      startIcon={<ArrowBackIcon />}
    >
      {t('common:general.back')}
    </Button>
  );
};
export default ButtonBack;
