import { CircularProgress, Container, Paper } from '@material-ui/core';
import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import Box from '@material-ui/core/Box';
import { useHistory, useLocation, useParams } from 'react-router';
import useStores from '../hooks/use-stores';
import { LoginState } from '../models/LoginModels';
import InitialForm from '../components/LoginPage/InitialForm';
import { useLoginPageStyles } from '../components/LoginPage/LoginPageStyles';
import LoginForm from '../components/LoginPage/LoginForm';
import RegisterForm from '../components/LoginPage/RegisterForm';
import EMailVerificationNotice from '../components/LoginPage/EMailVerificationNotice';
import EMailVerificationSentNotice from '../components/LoginPage/EMailVerificationSentNotice';
import LogoutSuccess from '../components/LoginPage/LogoutSuccess';
import LogoWeiss from '../resources/vector/Logo-weiss.svg';
import ResetPasswordForm from '../components/LoginPage/ResetPasswordForm';
import { DEFAULT_CONTAINER_WIDTH } from '../utils/Constants';
import ResetPasswordSentNotice from '../components/LoginPage/ResetPasswordSentNotice';
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import NewPasswordForm from "../components/NewPasswordPage/NewPasswordForm";

// Reactive Component using observer
const NewPasswordPage: React.FC = observer(() => {
  // define the style classes
  const classes = useLoginPageStyles();

  // provide a store
  const {
    moovyAuthStore: {
      loading,
    },
  } = useStores();

  const history = useHistory();

  const { t } = useTranslation(['common']);

  const urlSearchParams = new URLSearchParams(document.location.search);
  let token: string;
  if (urlSearchParams.has("token")) {
    console.warn(urlSearchParams.get("token"));
    token = urlSearchParams.get("token")!;
  } else {
    toast.error("No token or broken link.");
    history.replace("/home");
  }

  useEffect(() => {
    console.warn();
    if (urlSearchParams.has("token")) {
      console.warn(urlSearchParams.get("token"));
    } else {
      toast.error("No token or broken link.");
      history.replace("/home");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container maxWidth={DEFAULT_CONTAINER_WIDTH} className={classes.container}>
      <Box className={classes.logo}>
        <img className={classes.image} src={LogoWeiss} alt="Logo" />
      </Box>
      <Paper className={classes.paper} elevation={0}>
        {!loading
          ? (
            <Box className={classes.form}>
              <NewPasswordForm token={token!} />
            </Box>
          )
          : (
            <Box className={classes.form} display="flex" justifyContent="center" p={2}>
              <CircularProgress size={150} />
            </Box>
          )}
      </Paper>
    </Container>
  );
});

export default NewPasswordPage;
