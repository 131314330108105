import { get } from './RequestManager';
import { AxiosResponse } from 'axios';

export enum PreviewSizes {
  S = 'S',
  M = 'M',
  L = 'L',
  XL = 'XL',
  XXL = 'XXL',
}

export function b64StringToCSSString(b64string: string) {
  return `url(data:image/png;base64,${b64string})`;
}

export function previewUrlFrom(fileUrl: string, size: PreviewSizes): string {
  return `${fileUrl}/preview?size=${size}`;
}

export async function retrieveB64ImagePreview(
  attachmentUrl: string, size: PreviewSizes,
): Promise<AxiosResponse> {
  const url = previewUrlFrom(attachmentUrl, size);
  return get(url);
}
