import {
  AppBar,
  Box,
  IconButton,
  makeStyles,
  Theme,
  Toolbar,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Link } from 'react-router-dom';
import FeedbackDialog from '../app/FeedbackDialog';
import { MOOV_COLOR_WHITE } from '../resources/colors';
import logo from '../resources/vector/Logo.svg';
import menuClose from '../resources/vector/Menu-schliessen.svg';
import menu from '../resources/vector/Menu.svg';
import NavbarMenu from './NavbarMenu';
import NavbarMenuDesktop from './NavbarMenuDesktop';
import SearchInput from './SearchInput';

const useNavbarStyles = makeStyles((theme: Theme) => ({
  appbar: {
    backgroundColor: MOOV_COLOR_WHITE,
  },
  menu: {
    maxHeight: 60,
    width: 60,
  },
  menuBox: {
    justifyContent: 'flex-end',
  },
  menuIcon: {
    display: 'flex',
    width: 'inherit',
    height: 'inherit',
  },
  title: {
    flexGrow: 1,
  },
  logoWrapper: {
    flexGrow: 1,
    height: 70,
    padding: theme.spacing(1),
  },
  logo: {
    height: '100%',
    width: 'auto',
  },
}));

const Navbar = observer(() => {
  const classes = useNavbarStyles();

  // Local state for animations
  const [open, setOpen] = React.useState(false);

  const handleChange = () => {
    setOpen((prev) => !prev);
  };

  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <AppBar position="relative" className={classes.appbar} elevation={0}>
      <FeedbackDialog />
      <SearchInput />

      <Toolbar>
        <Box className={classes.logoWrapper}>
          <Link to="/">
            <img src={logo} alt="Logo" className={classes.logo} />
          </Link>
        </Box>
        {!isSm && <NavbarMenuDesktop />}
        {isSm && (
          <Box className={classes.menuBox}>
            <IconButton
              className={classes.menu}
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleChange}
            >
              <img
                src={open ? menuClose : menu}
                alt="menu"
                className={classes.menuIcon}
              />
            </IconButton>
          </Box>
        )}
      </Toolbar>

      {isSm && <NavbarMenu open={open} />}
    </AppBar>
  );
});

export default Navbar;
