import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import deCommon from '../resources/locale/de/common.json';
import deError from '../resources/locale/de/error.json';
import deUpload from '../resources/locale/de/upload.json';
import deValidation from '../resources/locale/de/validation.json';
import enCommon from '../resources/locale/en/common.json';
import enError from '../resources/locale/en/error.json';
import enUpload from '../resources/locale/en/upload.json';
import enValidation from '../resources/locale/en/validation.json';

declare module 'react-i18next' {
  interface Resources {
    common: typeof deCommon;
    validation: typeof deValidation;
    error: typeof deError;
    upload: typeof deUpload;
  }
}

export const supportedLanguages = ['de', 'en'];
export const fallbackLanguage = 'de';

export default i18next
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    supportedLngs: supportedLanguages,
    nonExplicitSupportedLngs: true,
    fallbackLng: fallbackLanguage,
    resources: {
      de: {
        validation: deValidation,
        common: deCommon,
        error: deError,
        upload: deUpload,
      },
      en: {
        validation: enValidation,
        common: enCommon,
        error: enError,
        upload: enUpload,
      },
    },
    interpolation: {
      escapeValue: false,
    },
    detection: {
      lookupLocalStorage: 'lang', // name of the variable in localstorage
    },
    react: {
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'span'],
    },
  });
