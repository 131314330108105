import { Box, Grid, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import i18next from 'i18next';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ChildFolder } from '../../models/Folders';
import { MOOV_COLOR_BLUE } from '../../resources/colors';
import { DEFAULT_GRADIENT_NO_WHITE } from '../../utils/Constants';
import { RootFolderIconMapping } from '../../utils/DefaultRootFolders';
import { getLocalizedFolderName } from '../../utils/folderTranslations';

const useStyles = makeStyles(() => ({
  folderSectionRoundIcon: {
    borderRadius: '50%',
    background: DEFAULT_GRADIENT_NO_WHITE,
    height: 0,
    width: '100%',
    paddingBottom: '100%',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
  },
  icon: {
    position: 'absolute',
    bottom: '50%',
    transform: 'translateY(50%) scale(0.6)',
    height: 'auto',
    width: '100%',
  },
  folderLabel: {
    color: MOOV_COLOR_BLUE,
    fontWeight: 400,
    fontSize: 12,
    textAlign: 'center',
  },
  folderLabelBold: {
    color: MOOV_COLOR_BLUE,
    fontWeight: 500,
    fontSize: 12,
    textAlign: 'center',
  },
}));

type RootFolderProps = {
  folder: ChildFolder;
};

export const RootFolder: React.FC<RootFolderProps> = ({ folder }) => {
  const classes = useStyles();
  const { language } = i18next;
  const folderInfo = RootFolderIconMapping.find(
    (icon) => folder?.icon === icon.apiIconId
  );
  const iconSource = folderInfo?.icon;

  const localizedHelpText =
    language === 'de' ? folderInfo?.helptext : folderInfo?.helptextEN;

  const history = useHistory();

  return (
    <Grid
      item
      key={folder.id}
      onClick={() => history.push(`/folder/${folder.id}`)}
    >
      <Tooltip
        title={
          <Box
            style={{
              padding: 7,
            }}
          >
            <Typography>{localizedHelpText}</Typography>
          </Box>
        }
      >
        <Box width={130} margin={2}>
          <Box className={classes.folderSectionRoundIcon}>
            <img className={classes.icon} src={iconSource} alt="Icon" />
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            p={1}
            flexDirection="column"
          >
            <Typography variant="button" className={classes.folderLabelBold}>
              {getLocalizedFolderName(folder.name)}
            </Typography>
            <Typography variant="button" className={classes.folderLabel}>
              {folder.assets?.length && `${folder.assets?.length}`}
            </Typography>
          </Box>
        </Box>
      </Tooltip>
    </Grid>
  );
};
