import {
  Box,
  Button,
  FormHelperText,
  Grid,
  makeStyles,
  TextField,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import BackupIcon from '@material-ui/icons/Backup';
import CloseIcon from '@material-ui/icons/Close';
import Autocomplete, {
  AutocompleteRenderInputParams,
} from '@material-ui/lab/Autocomplete';
import { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgressWithLabel } from './CircularProgressWithLabel';

import { observer } from 'mobx-react-lite';
// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond';

// Import FilePond styles
import 'filepond/dist/filepond.min.css';

import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';

import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import useStores from '../hooks/use-stores';
import {
  DEFAULT_BORDER_RADIUS,
  DEFAULT_GRADIENT_NO_WHITE,
} from '../utils/Constants';
// eslint-disable-next-line import/order
import * as yup from 'yup';
import './UploadDialog.css';
// eslint-disable-next-line import/order
import { green } from '@material-ui/core/colors';
import { FilePondFile } from 'filepond';
import { FormikValues, useFormik } from 'formik';
import { toJS } from 'mobx';
import { toast } from 'react-toastify';
import { FolderLabels } from '../models/FolderLabels';
import { UploadDialogContent } from '../models/UploadDialogContent';
import { getLocalizedFolderName } from '../utils/folderTranslations';
import { TagCreatableBox } from './TagCreatableBox';
// import AttachFileIcon from '@material-ui/icons/AttachFileOutlined';

// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType
);

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: DEFAULT_BORDER_RADIUS,
  },
  button: {
    background: DEFAULT_GRADIENT_NO_WHITE,
    borderRadius: DEFAULT_BORDER_RADIUS,
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginLeft: -12,
    marginTop: -12,
  },
}));

type UploadDialogProps = {
  preSelectFolderId?: string;
};

export const UploadDialog: FunctionComponent<UploadDialogProps> = observer(
  ({ preSelectFolderId }) => {
    const classes = useStyles();

    const theme = useTheme();

    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const { t } = useTranslation(['upload', 'validation', 'common']);

    const {
      userAssetStore: { getUserAssetsInRootFolder },
      uploadDialogStore: {
        uploadDialogOpen,
        setUploadDialogOpen,
        submit,
        loading,
        percentComplete,
        cancelSubmit,
      },
      folderStore: {
        folderLabels,
        getFolderLabels,
        getAssetsInFolder,
        getFolders,
      },
      tagStore: { getAllTags, createTag },
    } = useStores();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const validationSchema = yup.object({
      title: yup.string().notRequired(),
      notes: yup.string().notRequired(),
      category: yup.string().notRequired(),
      files: yup
        .array()
        .min(1, t('upload:validation.noFiles'))
        .required(t('upload:validation.noFiles')),
      tags: yup.array().notRequired(),
    });

    const formik = useFormik({
      initialValues: {
        title: '',
        notes: '',
        category: preSelectFolderId ?? '',
        files: [],
        tags: [],
      },
      validationSchema,
      onSubmit: async (values: FormikValues) => {
        const formValues = values as UploadDialogContent;
        // find tags with an id that starts by "partial"
        const partialTags = formValues.tags.filter((tag) =>
          tag.id?.startsWith('partial')
        );

        // remove partial tags from the form values
        formValues.tags = formValues.tags.filter(
          (tag) => !tag.id?.startsWith('partial')
        );

        if (partialTags.length > 0) {
          for (const tag of partialTags) {
            const newTag = await createTag({ name: String(tag?.name) });
            // push the new tag to the form values
            formValues.tags.push(newTag.data);
          }
        }
        // execute request from the store, use formik values directly
        submit(values as UploadDialogContent)
          .then(async () => {
            getFolders();
            getUserAssetsInRootFolder();
            setUploadDialogOpen(false);
            toast.success(t('upload:uploadSuccess'));
          })
          .finally(() => {
            formik.resetForm();
          });
      },
    });

    useEffect(() => {
      getFolderLabels();
      getAllTags();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleClose() {
      cancelSubmit();
      formik.resetForm();
      setUploadDialogOpen(false);
      toast.warn(t('upload:dialog.uploadCancelled'));
    }

    // @ts-ignore
    return (
      <>
        <Dialog
          fullScreen={fullScreen}
          open={uploadDialogOpen}
          onClose={handleClose}
          disableBackdropClick
          scroll="paper"
          aria-labelledby="responsive-dialog-title"
        >
          <form onSubmit={formik.handleSubmit}>
            <DialogTitle id="responsive-dialog-title">
              {t('upload:dialog.title')}
            </DialogTitle>

            <DialogContent dividers>
              <DialogContentText>
                <Grid container direction="column" spacing={2}>
                  <Grid item>{t('upload:dialog.contentText')}</Grid>
                  <Grid item>
                    <FilePond
                      onupdatefiles={(files) => {
                        // We do not want FilePondFile[] but File[], so extract them.
                        formik.setFieldValue(
                          'files',
                          files.map((i: FilePondFile) => i.file),
                          true
                        );
                      }}
                      labelIdle={
                        `
                      <div>
                      <span className="filepond--label-action">
                        ${t('upload:choose')}
                      </span>
                      </div>
                      ` as string
                      }
                      allowMultiple
                      maxFiles={100}
                      name="files"
                    />

                    {formik.touched.files && (
                      <FormHelperText
                        error={Boolean(formik.errors.files)}
                        component="span"
                      >
                        {formik.errors.files}
                      </FormHelperText>
                    )}
                  </Grid>

                  <Grid item>
                    <TextField
                      id="title"
                      type="title"
                      label={t('upload:dialog.Addtitle')}
                      fullWidth
                      variant="outlined"
                      value={formik.values.title}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.title && Boolean(formik.errors.title)
                      }
                      helperText={formik.touched.title && formik.errors.title}
                    />
                  </Grid>

                  <Grid item>
                    <TextField
                      id="notes"
                      label={t('upload:dialog.uploadNotes')}
                      fullWidth
                      variant="outlined"
                      rowsMax={5}
                      multiline
                      value={formik.values.notes}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.notes && Boolean(formik.errors.notes)
                      }
                      helperText={formik.touched.notes && formik.errors.notes}
                    />
                  </Grid>

                  <Grid item>
                    <Autocomplete
                      options={toJS(folderLabels?.children!)}
                      getOptionLabel={(option) =>
                        option?.name! ? getLocalizedFolderName(option.name) : ''
                      }
                      getOptionSelected={(option, value) =>
                        option?.name === value?.name
                      }
                      fullWidth
                      onChange={(event, value: FolderLabels | null) => {
                        formik.setFieldValue('category', value?.id);
                      }}
                      onBlur={formik.handleBlur}
                      renderInput={(params: AutocompleteRenderInputParams) => (
                        <TextField
                          {...params}
                          label={t('upload:chooseCategory')}
                          variant="outlined"
                          error={
                            formik.touched.category &&
                            Boolean(formik.errors.category)
                          }
                          helperText={
                            formik.touched.category && formik.errors.category
                          }
                        />
                      )}
                      defaultValue={
                        toJS(folderLabels?.children!)
                          ? toJS(folderLabels?.children!).find(
                              (i) => i?.id === preSelectFolderId
                            )
                          : null
                      }
                    />
                  </Grid>

                  <Grid item>
                    <TagCreatableBox
                      formik={{
                        autocompleteOnBlur: formik.handleBlur,
                        autocompleteSetFieldValue: (field, value) =>
                          formik.setFieldValue(field, value),
                        textfieldFormHelperText: formik.touched.category
                          ? (formik.errors.category as string)
                          : undefined,
                        textfieldError:
                          formik.touched.category &&
                          Boolean(formik.errors.category),
                        selectedCategory: folderLabels?.children?.find(
                          (folder) => folder?.id === formik.values.category
                        )?.name!,
                      }}
                    />
                  </Grid>
                </Grid>
              </DialogContentText>
            </DialogContent>

            <DialogActions>
              <Box justifyContent="center" p={1}>
                <Button
                  onClick={handleClose}
                  color="primary"
                  size="large"
                  startIcon={<CloseIcon />}
                >
                  {t('common:general.cancel')}
                </Button>

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  disabled={loading}
                  className={classes.button}
                  startIcon={
                    loading ? (
                      <CircularProgressWithLabel value={percentComplete} />
                    ) : (
                      <BackupIcon />
                    )
                  }
                >
                  {t('upload:upload')}
                </Button>
              </Box>
            </DialogActions>
          </form>
        </Dialog>
      </>
    );
  }
);
