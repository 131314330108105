import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { Redirect } from 'react-router';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import AssetDetailPage from '../pages/AssetDetailPage';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import PrivateRoute from '../components/PrivateRoute';
import HelpPage from '../pages/HelpPage';
import HomePage from '../pages/HomePage';
// import LegalPage from '../pages/LegalPage';
import LoginPage from '../pages/LoginPage';
import ProfilePage from '../pages/ProfilePage';
import TermsPage from '../pages/TermsPage';
import { DEFAULT_GRADIENT } from '../utils/Constants';
import FolderPage from '../pages/FolderPage';
import { RoutingErrorPage } from '../pages/RoutingErrorPage';
import SettingsPage from '../pages/SettingsPage';
import NewPasswordPage from "../pages/NewPasswordPage";
import { ImpressumPage } from '../pages/impressum/ImpressumPage';
// import PrivacyPage from '../pages/datenschutz/PrivacyPage';
import PrivacyPage from "../pages/datenschutz/PrivacyPage";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    minHeight: '100vh',
    backgroundImage: DEFAULT_GRADIENT,
  },
  footer: {
    marginTop: 'auto',
  },
  mainContent: {
    paddingTop: theme.spacing(2),
    height: '100%',
  },
}));

export default function PageContainer() {
  const classes = useStyles();

  return (
    <Router>
      <Grid className={classes.root}>
        <Grid item xs={12} id="navbar">
          <Navbar />
        </Grid>

        <Grid item xs={12} id="main-content" className={classes.mainContent}>
          <Switch>
            <Route exact path="/">
              <Redirect to="/login" />
            </Route>

            <Route path="/resetPassword/reset">
              <NewPasswordPage />
            </Route>

            <Route path="/login">
              <LoginPage />
            </Route>

            <Route path="/terms">
              <TermsPage />
            </Route>
            <Route path="/legal">
              <ImpressumPage />
            </Route>
            <Route path="/privacy">
              <PrivacyPage />
            </Route>

            <PrivateRoute path="/home">
              <HomePage />
            </PrivateRoute>

            <PrivateRoute path="/asset/:id">
              <AssetDetailPage />
            </PrivateRoute>

            <PrivateRoute path="/folder/:id">
              <FolderPage />
            </PrivateRoute>

            <PrivateRoute path="/help">
              <HelpPage />
            </PrivateRoute>

            <PrivateRoute path="/profile">
              <ProfilePage />
            </PrivateRoute>

            <PrivateRoute path="/settings">
              <SettingsPage />
            </PrivateRoute>

            <Route path="*">
              <RoutingErrorPage />
            </Route>

          </Switch>

        </Grid>

        <Grid item xs={12} id="footer" className={classes.footer}>
          <Footer />
        </Grid>
      </Grid>
    </Router>
  );
}
