import { Box, Paper, Typography, useTheme } from '@material-ui/core';
import React, { FunctionComponent, useEffect } from 'react';
import useStores from '../hooks/use-stores';
import { UserAsset } from '../models/UserAssetModel';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import { DEFAULT_BORDER_RADIUS, PAPER_BG_COLOR } from '../utils/Constants';
import { useTranslation } from 'react-i18next';
import moment from "moment/moment";
import { InnerBoxes } from "./InnerBoxes";
import { CenteredSectionHeadline } from './CenteredSectionHeadline';

// ChequeredPreviewSection
const FolderViewMultipleIcons: FunctionComponent = observer(() => {
  const {
    userAssetStore: {
      getUserAssetsInRootFolder,
      inboxItems,
    },
    uploadDialogStore: {
      setUploadDialogOpen,
    },
  } = useStores();

  const history = useHistory();

  const { t } = useTranslation(['common']);

  const theme = useTheme();

  // get all inbox content
  useEffect(() => {
    getUserAssetsInRootFolder();
  }, []); // eslint-disable-line

  return (
    <Paper
      style={
      {
        display: inboxItems.length > 0 ? 'flex' : 'none',
        flexDirection: 'column',
        backdropFilter: 'blur(20px)',
        backgroundColor: PAPER_BG_COLOR,
        borderRadius: DEFAULT_BORDER_RADIUS,
        padding: theme.spacing(1),
        width: '100%',
      }
    }
      elevation={0}
    >

      <CenteredSectionHeadline string={t('common:title.lastUsedWithoutCategory')} />

      <div
        style={{
          display: 'flex',
          overflow: 'auto',
        }}
      >
        {inboxItems.map((userAsset: UserAsset) => (
          <Box p={2} key={userAsset.id}>
            <Box
              width={200}
              height={200}
              style={{
                background: PAPER_BG_COLOR,
                flexShrink: 0,
                borderRadius: DEFAULT_BORDER_RADIUS,
              }}
              display="flex"
              justifyContent="center"
              alignItems="center"
              alignContent="center"
              mb={1}
              onClick={() => history.push(`/asset/${userAsset.id}`)}
            >
              {userAsset.attachments ? <InnerBoxes attachments={userAsset.attachments} /> : null}
            </Box>
            <Box
              width={200}
              height="auto"
              display="flex"
              justifyContent="center"
            >
              <Typography
                variant="subtitle2"
              >

                {userAsset.title ? userAsset.title : `${t('common:detailPage.collectionFrom')} ${moment(userAsset.created).format('DD.MM.YY')}`}
              </Typography>

            </Box>

            {' '}
            {/* {userAsset.created.toString()} */}
          </Box>
        ))}
      </div>
    </Paper>
  );
});

export default FolderViewMultipleIcons;
