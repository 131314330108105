/* eslint-disable */
// @ts-nocheck

import { Box, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(() => ({
  container: {
    height: '100%',
  },
  wrapper: {
    padding: 30,
  },
  ul: {
    listStyleType: 'disc',
  },
  header: {
    fontSize: 20,
    color: '#005798',
  },
  largeHeader: {
    fontSize: 24,
    color: '#005798',
    fontWeight: '500',
  },
}));

export const PrivacyTextEnglish: React.FC = () => {
  const classes = useStyles();
  return (
    <Box className={classes.wrapper}>
      <h2 className={classes.largeHeader}>
        Data protection information from MoovyMed
      </h2>
      <p>
        <span className={classes.header}>
          Data protection information from MoovyMed
        </span>
        <br />
        As the operator of the website at www.moovymed.de, we are responsible
        within the meaning of the applicable data protection law, in particular
        the General Data Protection Regulation ("GDPR"), for the personal data
        of the user of the user ("you") of this website.
        <br />
        <br />
        In the following, we will inform you clearly within the scope of our
        information obligations (Art. 13 ff. GDPR) about which data is processed
        when processed when you visit our website and the legal basis legal
        basis this is done. You will also receive information what rights you
        have vis-à-vis us and vis-à-vis the competent supervisory authority.
      </p>
      <p>
        <span className={classes.header}>
          1. Information on the person responsible
        </span>
        <br />
        MoovyMed GmbH
        <br />
        <br />
        Werftstr. 12
        <br />
        76189 Karlsruhe
        <br />
        <br />
        <a href="mailto:datenschutz@moovymed.de">datenschutz@moovymed.de</a>
      </p>
      <p>
        <span className={classes.header}>
          2. Informational use of our website
        </span>
        <br />
        When you access our website merely to visit it, so-called log files are
        so-called log files are processed by our system by automatically
        automatically recorded by our system.
        <br />
        <br />
        The following log files are processed automatically: <br />
        <ul>
          <li>IP address of the requesting computer</li>
          <li>Type of internet browser used</li>
          <li>Language of the internet browser used</li>
          <li>Version of the internet browser used</li>
          <li>Operating system and its version</li>
          <li>Interface of the operating system</li>
          <li>Pages accessed</li>
          <li>Date and time of the visit</li>
          <li>Time zone difference to Greenwich Mean Time (GMT)</li>
          <li>Access status/http status code</li>
          <li>Amount of data transferred</li>
          <li>Success or failure of the loading process</li>
          <li>Referrer</li>
          <li>
            Websites that are accessed by the visitor's system via our website
          </li>
          <li>Internet Service Provider of the user</li>
        </ul>
        <br />
        The log files contain your IP address, but it is truncated before
        storage. Therefore, it is not possible to associate it with you and your
        data is not stored together with other personal data.
        <br />
        <br />
        The processing of the above-mentioned data is necessary for the
        provision of our website.
        <br />
        <br />
        The legal basis for processing the data for anonymization purposes is
        Art. 6 (1) lit. f GDPR.
      </p>
      <p>
        <span className={classes.header}>3. Use of Offers</span>
        <br />
        <span
          style={{
            fontWeight: 'bold',
          }}
        >
          {`a) Registration for the newsletter (regularly) / for current tips and
          hints (irregularly)`}
        </span>
        <br />
        <br />
        You have the opportunity to register on our website. Then you will
        receive – depending on what you register for with us – our newsletter
        regularly, or / and current information, e.g. you will be the first to
        know when our portal goes online. We process the following personal data
        when you fill out our registration form:
        <br />
        <ul>
          <li>First and last name, to address you personally</li>
          <li>Email address</li>
          <li>Questions/Information/Notes (free field for you)</li>
        </ul>
        The <b>legal basis</b> for this data processing is Art. 6 (1) lit. a
        GDPR. By submitting the registration with us, you agree to the
        processing of your data by us (also in accordance with § 7 UWG).
        <br />
        <br />
        We will only send you the newsletter or our tips and hints when you have
        previously confirmed your registration via a confirmation email sent to
        you for this purpose by clicking on the link provided for this purpose.
        With this, we want to ensure that only you can register with us. Your
        confirmation in this regard must be made promptly after receiving the
        confirmation email, as otherwise your newsletter registration will be
        automatically deleted from our database.
        <br />
        <br />
        In addition, as part of your newsletter registration, we store the time
        (date and time) of the transmission of your data to us, as well as your
        IP address. The processing of this data corresponds to our legitimate
        interest according to Art. 6 (1) lit. f GDPR, to ensure the security of
        our systems and to counteract misuse.
        <br />
        <br />
        Your data will be processed exclusively in connection with the dispatch
        of newsletters or tips and hints. The purpose of processing your email
        address is the ability to deliver our emails to you. Additional data
        during the registration process either serve to address you personally
        or also to ensure the security of our services and to prevent misuse of
        the used email address.
        <br />
        <br />
        Your data will only be stored as long as it is necessary for the
        achievement of the purpose. Therefore, your email address will be stored
        over the period of your active newsletter subscription, if your consent
        is given for this. The data that we additionally automatically collect
        during your registration (IP address, date and time) will be deleted at
        the latest when you end your newsletter subscription.
        <br />
        <br />
        <span
          style={{
            fontWeight: 'bold',
          }}
        >
          {`b) Possibility of objection / Unsubscribe from newsletter`}
        </span>
        <br />
        <br />
        You can unsubscribe from our email distribution list at any time. You
        can find the link for this at the end of every newsletter. By doing
        this, you revoke your consent or object to further use of your data for
        the purpose of sending the newsletter.
        <br />
        <br />
        <span
          style={{
            fontWeight: 'bold',
          }}
        >
          {`c) Contact via email, phone or fax`}
        </span>
        <br />
        <br />
        You have the opportunity to contact us via <b>email or phone</b>. When
        we communicate with each other, we process your personal data for this
        purpose. The data is exclusively processed to handle your contact and
        resolve your concern. The
        <b>legal basis</b> for the processing of your personal data is Art. 6
        (1) lit. f GDPR. The data is stored until it is no longer necessary for
        the purpose of the conversation with you and the concern of your contact
        is fully clarified.
        <br />
        <br />
        If your contact is aimed at concluding a contract with us, the
        additional <b>legal basis</b> for the processing of your personal data
        is Art. 6 (1) lit. b GDPR. This data is stored as long as it is
        necessary for the execution of the contract or pre-contractual measures.
        Beyond that, we only store your data to comply with contractual or legal
        obligations (e.g. tax obligations) (Art. 6 (1) lit. c GDPR).
        <br />
        <br />
        In addition to the data that you voluntarily provide to us, we may
        receive the time (date and time) of the transmission of your data to us,
        as well as your IP address. The processing of this data corresponds to
        our legitimate interest (Art. 6 (1) lit. f GDPR), to ensure the security
        of our systems and to counteract misuse. These data, which we collect
        additionally during your contact, will be deleted as soon as they are no
        longer needed, at the latest when the concern of your contact is fully
        clarified.
        <br />
        <br />
        You can inform us at any time (see above <b>number 1</b>), that we
        should delete the data communicated in the course of the conversation.
        In this case, as far as permissible, all personal data of the
        conversation will be deleted and a continuation of the conversation is
        not possible.
        <br />
        <br />
        <span
          style={{
            fontWeight: 'bold',
          }}
        >
          {`d) Arrange a callback (with Calendly)`}
        </span>
        <br />
        <br />
        If you click on the "Arrange a callback" button on our website, you can
        arrange a phone appointment with us. For this, we use the service
        provider "Calendly" from Calendly LLC, 1315 Peachtree St NE, Atlanta, GA
        30309, USA.
        <br />
        <br />
        The following data is collected for the appointment with us:
        <ul>
          <li>Name</li>
          <li>Email address</li>
          <li>Phone number</li>
          <li>Date and time of the appointment</li>
          <li>Your message to us (optional)</li>
        </ul>
        The data is used for the appointment. By submitting the form you agree
        to a processing of your data by us. The legal bases for the processing
        of your data via the Calendly appointment are Art. 6 (1) lit. b and f
        GDPR. This data, which we collect for the appointment, will be deleted
        as soon as they are no longer needed, at the latest when the concern of
        your contact is fully clarified.
        <br />
        <br />
        In addition, internal statistics are created based on the data, with
        which we can improve our organization. For us, this function is
        therefore very helpful, and in the context of a balance of interests
        with your interests, we have concluded that your interests and
        fundamental rights do not oppose the use of Calendly and do not outweigh
        our interests. The legal basis for the processing of your data to create
        the statistics is Art. 6 (1) lit. f GDPR. The statistics do not have any
        personal reference.
        <br />
        <br />
        In addition to this data, we store the time (date and time) of the
        transmission of your data to us, as well as your IP address. The
        processing of this data corresponds to our legitimate interest (Art. 6
        (1) lit. f GDPR), to ensure the security of our systems and to
        counteract misuse. These additional data will be deleted as soon as they
        are no longer needed, at the latest when the contract with you has been
        processed.
        <br />
        <br />
        You can find Calendly's privacy policy at:
        <a
          href="https://calendly.com/de/pages/privacy"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://calendly.com/de/pages/privacy
        </a>{' '}
        For any data transfer from the EU to a third country (e.g. the USA), we
        rely on the EU standard contractual clauses concluded with Calendly.
        <br />
        <br />
        From an organizational point of view, we prefer it if the function of
        Calendly is used for scheduling appointments. However, if you have no
        interest in scheduling an appointment with us via Calendly, there is
        also the possibility that we can arrange an appointment by email or
        phone.
      </p>
      <p>
        <span className={classes.header}>4. Social Media</span>
        <br />
        <span
          style={{
            fontWeight: 'bold',
          }}
        >
          {`a) Icon links to social networks`}
        </span>
        <br />
        <br />
        On our website, we use small icons that each link to our web presence on
        third-party platforms (Facebook, Twitter, LinkedIn). These are{' '}
        <b>hyperlinks</b>, so <b>no</b>
        data from you is automatically transferred, but only when you click on
        the icons and a new tab with the third-party website opens in your
        browser.
        <br />
        <br />
        <span
          style={{
            fontWeight: 'bold',
          }}
        >
          {`b) Facebook fan page`}
        </span>
        <br />
        <br />
        We operate a fan page on the social media platform Facebook (Facebook
        Inc., Facebook Ireland Ltd, 4 Grand Canal Square, Grand Canal Harbour,
        D2 Dublin, Ireland (hereinafter: <b>"Facebook"</b>), which we link to
        our company page via the Facebook icon. As long as you do not click on
        the link, Facebook does not receive any data from you. If you click on
        the link, for example to view our company presence on Facebook or to
        "like" our page, Facebook receives data from you (which data Facebook
        receives, also depends on whether you are logged in to Facebook with
        your user profile while you click on the page or not).
        <br />
        <br />
        While Facebook uses this data in its own responsibility, among other
        things, to create profiles, we can only see aggregated data, i.e.
        statistics, on our company homepage that no longer have any personal
        reference. These are called "Page Insights". You can find more
        information about Page Insights at the following link:
        <br />
        <br />
        <a
          href="https://www.facebook.com/legal/terms/information_about_page_insights_data"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.facebook.com/legal/terms/information_about_page_insights_data
        </a>
        <br />
        <br />
        Due to the requirements of the GDPR, we have concluded an agreement
        provided by Facebook with Facebook, which regulates the joint
        responsibility for our fan page. You can find this agreement in German
        at the following link:
        <br />
        <br />
        <a
          href="https://www.facebook.com/legal/terms/page_controller_addendum"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.facebook.com/legal/terms/page_controller_addendum
        </a>
        <br />
        <br />
        This results in Facebook being primarily responsible for the aggregated
        Insight data. In addition, Facebook will fulfill all obligations from
        the GDPR with regard to the processing of Insight data (including Art.
        12, 13 GDPR, Art. 15-22 GDPR and Art. 32-34 GDPR). If you make a request
        regarding our Facebook fan page to us, we will inform Facebook promptly.
        According to our agreement, Facebook will answer the request.
        <br />
        <br />
        Our legitimate interest in processing personal data lies in the use and
        linking of different communication channels.
        <br />
        <br />
        The processing is based on the following <b>legal bases</b> Art. 6 Para.
        1 S. 1 lit. a and f GDPR (your consent to the setting of cookies and our
        legitimate interest in analysis, evaluation and marketing). You can find
        Facebook's data policy at the following link:
        <a
          href="https://www.facebook.com/policy.php"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.facebook.com/policy.php
        </a>
        .
        <br />
        <br />
        For the data transfer to the USA, we have concluded contracts with
        Facebook, including the standard contractual clauses. You can find more
        about this here
        <br />
        <a
          href="https://www.facebook.com/legal/technology_terms"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.facebook.com/legal/technology_terms
        </a>
        .
        <br />
        <br />
        Facebook is still certified under the Privacy Shield; however, we do not
        base the data transfer to the USA on this basis. You can find
        information on this at:
        <br />
        <a
          href=" https://www.facebook.com/about/privacyshield"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.facebook.com/about/privacyshield
        </a>
        <br />
        <br />
        <a
          href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC.
        </a>
        <br />
        <br />
        <span
          style={{
            fontWeight: 'bold',
          }}
        >
          {`c) LinkedIn`}
        </span>
        <br />
        <br />
        We operate an online presence on the social media portal LinkedIn
        (LinkedIn Ireland Unlimited Company, Wilton Place, Dublin 2, Ireland).
        We are partially jointly responsible with LinkedIn for data processing
        that takes place on our LinkedIn page (
        <a
          href="https://www.linkedin.com/company/memoresa"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.linkedin.com/company/memoresa
        </a>
        ). We process personal data via LinkedIn, when we create or comment on
        postings and report on our company.
        <br />
        <br />
        The legal bases for data processing are your consent in accordance with
        Art. 6 para. 1 lit. a GDPR, if you have given us such (e.g. for the
        publication of photos or videos) and/or our legitimate interests
        according to Art. 6 para. 1 lit. f GDPR, e.g. because the reporting or
        interaction on the social media portal serves to make our company or our
        activities known. The storage duration depends on how long we need to
        maintain the data processing to achieve our purposes. If you revoke your
        consent to us and there is no other basis for the data processing, we
        will delete your personal data under the conditions of Art. 17 GDPR.
        <br />
        <br />
        We have partially a contract processing with LinkedIn (
        <a
          href="https://legal.linkedin.com/dpa/DE"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://legal.linkedin.com/dpa/DE
        </a>
        ) and partially, at least with regard to the "Insight data" (visible to
        us only as statistics), which we can view on our profile, a joint
        responsibility (more about this here:
        <a
          href="https://legal.linkedin.com/pages-joint-controller-addendum"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://legal.linkedin.com/pages-joint-controller-addendum
        </a>
        ).
        <br />
        <br />
        You can find LinkedIn's privacy policy at:
        <a
          href="https://www.linkedin.com/legal/privacy-policy?trk=lithograph_footer-privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.linkedin.com/legal/privacy-policy?trk=lithograph_footer-privacy-policy
        </a>
        <br />
        <br />
        Since LinkedIn is a company whose parent company is in the USA, it is
        fundamentally not excluded that a data transfer to the USA or to other
        third countries takes place. For a possible data transfer, we rely on EU
        standard contractual clauses. You can find more information about this
        at:
        <a
          href="https://www.linkedin.com/help/linkedin/answer/62533?trk=microsites-frontend_legal_privacy-policy&lang=de"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.linkedin.com/help/linkedin/answer/62533?trk=microsites-frontend_legal_privacy-policy&lang=de
        </a>
        <br />
        <br />
        <span
          style={{
            fontWeight: 'bold',
          }}
        >
          {`d) XING`}
        </span>
        <br />
        <br />
        We have a company page on the social media portal XING (XING AG,
        Dammtorstraße 29-32, 20354 Hamburg). We process personal data via XING
        when we create or comment on postings and report on our company.
        <br />
        <br />
        The legal bases for data processing are your consent in accordance with
        Art. 6 para. 1 lit. a GDPR, if you have given us such (e.g. for the
        publication of photos or videos) and/or our legitimate interests
        according to Art. 6 para. 1 lit. f GDPR, e.g. because the reporting or
        interaction on the social media portal serves to make our company or our
        activities known. The storage duration depends on how long we need to
        maintain the data processing to achieve our purposes. If you revoke your
        consent to us and there is no other basis for the data processing, we
        will delete your personal data under the conditions of Art. 17 GDPR.
        <br />
        <br />
        You can find XING's privacy policy at:
        <a
          href="https://privacy.xing.com/de/datenschutzerklaerung"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://privacy.xing.com/de/datenschutzerklaerung
        </a>
        .
      </p>
      <p>
        <span className={classes.header}>5. Use of Service Providers</span>
        <br />
        We would like to point out that we may use service providers in the
        processing of your personal data, with whom we have concluded data
        processing agreements (e.g. for website hosting). If processors in a
        third country (not within the EU) carry out the data processing, we
        ensure that the level of protection for your data guaranteed by the GDPR
        is not undermined (Art. 44 ff GDPR). The legal basis for the use of
        service providers is Art. 6 para. 1 sentence 1 lit. f GDPR. The
        commissioning of service providers (specialists or other service
        providers in areas that we cannot serve ourselves) corresponds to our
        legitimate interest. If you would like to receive a copy of the
        appropriate or adequate guarantees, please let us know (see above
        <b>No. 1</b>).
      </p>
      <p>
        <span className={classes.header}>6. Applications</span>
        <br />
        <span
          style={{
            fontWeight: 'bold',
          }}
        >
          {`a. Application Options`}
        </span>
        <br />
        <br />
        You have various options to apply to us.
        <br />
        <br />
        <span
          style={{
            fontWeight: 'bold',
          }}
        >
          {`Application by Email`}
        </span>
        <br />
        <br />
        If you apply to us by email, the personal data you transmit will be
        processed by us for the handling of the application process. This means
        that your application will be viewed by our employees who are
        responsible for pre-selection. The legal basis for the processing of
        your data is Art. 6 (1) S.1 lit. b, Art. 88 (1) GDPR in conjunction with
        § 26 BDSG.
        <br />
        <br />
        If we reject your application, we will only store the data as long as
        necessary, at most for a period of six months, unless you give us your
        consent that we may store the applicant data longer, to possibly contact
        you even after the six months have expired.
        <br />
        <br />
        Upon your request (for example by email), all personal data of the
        application will be deleted, unless a longer storage period is necessary
        to protect our legitimate interests and unless your interests do not
        outweigh (Art. 6 (1) lit. f GDPR).
        <br />
        <br />
        <span
          style={{
            fontWeight: 'bold',
          }}
        >
          {`Application by Mail`}
        </span>
        <br />
        <br />
        Your personal data transmitted by mail will be processed by us for the
        handling of the application process. This means that your application
        will be viewed by our employees who are responsible for pre-selection.
        The legal basis for the processing of your data is Art. 6 (1) S.1 lit.
        b, Art. 88 (1) GDPR in conjunction with § 26 BDSG.
        <br />
        <br />
        If we reject your application, we will only store the data as long as
        necessary, at most for a period of six months, unless you give us your
        consent that we may store the applicant data longer, to possibly contact
        you even after the six months have expired.
        <br />
        <br />
        Upon your request (for example by email), all personal data of the
        application will be deleted, unless a longer storage period is necessary
        to protect our legitimate interests and unless your interests do not
        outweigh (Art. 6 (1) lit. f GDPR).
        <br />
        <br />
        Documents that we have received from you by mail, we will send back to
        you immediately after the completion of the application process. Please
        provide a stamped return envelope for this. The documents can also be
        picked up at our office during regular office hours.
        <br />
        <br />
        <span
          style={{
            fontWeight: 'bold',
          }}
        >
          {`b. Job Interview`}
        </span>
        <br />
        <br />
        We process your applicant data, in particular first and last names,
        address and possibly email address or phone number, to invite you to a
        job interview, in case your application was selected in the
        pre-selection. The legal basis for the processing of your data is Art. 6
        (1) S.1 lit. b, Art. 88 (1) GDPR in conjunction with § 26 BDSG.
        <br />
        <br />
        <span
          style={{
            fontWeight: 'bold',
          }}
        >
          {`c. Offer for an Employment Relationship`}
        </span>
        <br />
        <br />
        If you receive an offer from us to enter into an employment
        relationship, in addition to this privacy policy, the privacy policy for
        employees applies.
      </p>
      <p>
        <span className={classes.header}>7. Your Rights</span>
        <br />
        When we process your data, you are a "data subject" within the meaning
        of the GDPR. You have the following rights: right to information, right
        to rectification, right to restriction of processing, right to erasure,
        right to be informed, and right to data portability. In addition, you
        have a right to object and a right to revoke your consent, and the right
        to complain to the supervisory authority. Below you will find some
        details about the individual rights:
        <br />
        <br />
        <span
          style={{
            fontWeight: 'bold',
          }}
        >
          {`a) Right to information`}
        </span>
        <br />
        <br />
        You have the right to request confirmation from us as to whether we
        process your personal data. If we process your personal data, you have
        the right, in particular, to information about the purposes of
        processing, categories of personal data, recipients or categories of
        recipients, and, if applicable, storage duration.
        <br />
        <br />
        <span
          style={{
            fontWeight: 'bold',
          }}
        >
          {`b) Right to rectification`}
        </span>
        <br />
        <br />
        You have the right to rectification and/or completion of the data we
        have stored about you if this data is incorrect or incomplete. We will
        make the correction or completion immediately.
        <br />
        <br />
        <span
          style={{
            fontWeight: 'bold',
          }}
        >
          {`c) Right to restriction of processing`}
        </span>
        <br />
        <br />
        Under certain conditions, you have the right to request from us the
        restriction of processing your personal data. An example of this is that
        you dispute the accuracy of your personal data and we need to verify the
        accuracy for a certain period. During the period of verification, your
        data will only be processed to a limited extent. Another example of the
        restriction is that we no longer need your data, but you need them for a
        legal dispute.
        <br />
        <br />
        <span
          style={{
            fontWeight: 'bold',
          }}
        >
          {`d) Right to erasure`}
        </span>
        <br />
        <br />
        In certain situations, you have the right to demand from us that we
        delete your personal data immediately. This is for example the case if
        we no longer need your personal data for the purposes for which we
        collected the data, or if we have processed your data unlawfully.
        Another example would be that we process your data based on your
        consent, you revoke your consent and we do not process the data on the
        basis of another legal basis. However, your right to erasure does not
        always exist. It may be, for example, that we process your personal data
        to comply with a legal obligation or because we need them for a legal
        dispute.
        <br />
        <br />
        <span
          style={{
            fontWeight: 'bold',
          }}
        >
          {`e) Right to be informed`}
        </span>
        <br />
        <br />
        If you have asserted your right to rectification, erasure or restriction
        of processing against us, we are obliged to inform all recipients to
        whom we have disclosed your personal data of the rectification, erasure
        or restriction of processing of your data, unless this proves impossible
        or involves a disproportionate effort.
        <br />
        <br />
        <span
          style={{
            fontWeight: 'bold',
          }}
        >
          {`f) Right to data portability`}
        </span>
        <br />
        <br />
        Under certain conditions, you have the right to receive the personal
        data that you have provided to us in a structured, common and
        machine-readable format and the right that these data are transferred to
        another controller. This is the case when we process the data either
        based on your consent or due to a contract with you and that we process
        the data using automated procedures.
        <br />
        <br />
        You have the right to have us transfer your personal data directly to
        another controller, as far as this is technically feasible and freedoms
        and rights of other persons are not affected by this.
        <br />
        <br />
        <span
          style={{
            fontWeight: 'bold',
          }}
        >
          {`g) Right to object`}
        </span>
        <br />
        <br />
        You have the right, for reasons arising from your particular situation,
        to object at any time to the processing of your personal data, which is
        based on Art. 6 (1) lit. e or lit. f GDPR. This also applies to
        profiling based on these provisions.
        <br />
        <br />
        After an objection, we will no longer process your personal data unless
        we can demonstrate compelling legitimate grounds for the processing that
        override your interests, rights and freedoms, or the processing is for
        the establishment, exercise or defense of legal claims.
        <br />
        <br />
        If we process your personal data for direct marketing purposes, you have
        the right to object at any time to the processing of your personal data
        for such advertising. This also applies to profiling, as far as it is
        connected with direct advertising. If you object to the processing of
        your personal data for direct marketing purposes, we will no longer
        process them for these purposes.
        <br />
        <br />
        <span
          style={{
            fontWeight: 'bold',
          }}
        >
          {`h) Right to revoke`}
        </span>
        <br />
        <br />
        According to Art. 7 (3) GDPR, you have the right to revoke your consent
        at any time. The revocation of consent does not affect the legality of
        the processing carried out on the basis of the consent until the
        revocation.
        <br />
        <br />
        <span
          style={{
            fontWeight: 'bold',
          }}
        >
          {`i) Right to lodge a complaint with a supervisory authority`}
        </span>
        <br />
        <br />
        You have the right to lodge a complaint with a supervisory authority,
        irrespective of any other administrative or judicial remedy. You can
        particularly exercise your right to complain in the Member State of your
        residence, your place of work or the place of the alleged infringement
        if you consider that the processing of your personal data violates the
        GDPR.
        <br />
        <br />
        An overview of the respective data protection officers of the countries
        and their contact details can be found at the following link:
        <br />
        <br />
        <a
          href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html.
        </a>
      </p>
      <h2 className={classes.largeHeader}>
        Information about cookies on our website
      </h2>

      <p>
        Please note the following: You can ensure yourself that no cookies are
        stored on your computer at all, or that only certain cookies are allowed
        to be stored. This you can select in your internet browser settings. You
        can also view and delete the stored cookies there.
        <br />
        <br />
        If you block all cookies, it may be that not all functions of our
        website are available to you.
        <br />
        <br />
        We use cookies on our website. Cookies are text files that are sent from
        our web server to your browser during your visit to our website and are
        kept by it on your computer for a later retrieval. Through a cookie,
        your internet browser can thus be identified when you visit the website
        again. There are session cookies, which are those that delete themselves
        when the browser is closed and there are persistent cookies, which are
        stored on the hard drive until their preset expiration date is reached
        or until they are actively removed by you.
        <br />
        <br />
        <span className={classes.header}>1. Own Cookies (First-Party)</span>
        <br />
        <br />
        We use our own cookies to ensure the
        <b>functionality of our website</b>. Some elements of our website
        necessarily require that your internet browser is recognized again after
        a page change.
        <br />
        <br />
        For the processing of personal data in cookies, which we set on our
        website to ensure the <b>functionality</b> of our website and our offer,
        the <b>legal basis</b> is Art. 6 (1) lit. f GDPR, provided personal data
        are contained in the cookies.
        <br />
        <br />
        <span className={classes.header}>
          2. Third-Party Cookies (Third-Party-Cookies/Provider Cookies)
        </span>
        <br />
        <br />
        We use cookies from so-called "third parties" on our website. This means
        that during your visit to our website, data from your web browser is
        transferred to the third party's web server and stored there. The
        processing operation that is triggered on our website is therefore a
        transmission. We do not receive your personal data.
        <br />
        <br />
        <span className={classes.header}>3. Google Analytics</span>
        <br />
        <br />
        The analysis service Google Analytics from Google LLC, 1600 Amphitheatre
        Parkway Mountain View, CA 94043, USA (in the following: "
        <b>Google Analytics</b>") is implemented on our website.
        <br />
        <br />
        Google Analytics sets cookies that store the following information:
        <ul>
          <li>
            Type of internet browser used, version of the internet browser
          </li>
          <li>the operating system you use</li>
          <li>Referrer (previously visited website)</li>
          <li>Your shortened IP address</li>
          <li>Time of the server request</li>
        </ul>
        Name, purpose and duration of the cookie
        <br />
        <br />
        _ga; serves to distinguish users in order to generate statistical data
        about the use of the website; Duration: 2 years
        <br />
        <br />
        Name, purpose and duration of the cookie:
        <br />
        <br />
        _gat, limits the request rate for Google Analytics; Duration: until the
        end of the browser session
        <br />
        <br />
        Name, purpose and duration of the cookie:
        <br />
        <br />
        _gid, serves to distinguish users in order to generate statistical data
        about the use of the website; Duration: 24 hours
        <br />
        <br />
        We use a feature of Google Analytics that anonymizes your IP address
        before it is stored or processed. Your IP address is usually shortened
        within the European Union/EEA and only then, for example, transferred to
        Google servers in the USA. The processing of your information is done
        pseudonymously and we will not merge it with other personal data from
        you.
        <br />
        <br />
        We only receive statistics via the tool, based on which we can optimize
        our website and offers.
        <br />
        <br />
        Before we set the cookies, we obtain your consent for this (Art. 6 (1)
        lit. a GDPR).
        <br />
        <br />
        You can prevent the collection of data generated by the cookie and
        related to your use of the website (incl. your (anonymized) IP address)
        to Google as well as the processing of this data by Google by
        downloading and installing the browser plugin available at the following
        link (
        <a
          href="http://tools.google.com/dlpage/gaoptout?hl=en"
          target="_blank"
          rel="noopener noreferrer"
        >
          http://tools.google.com/dlpage/gaoptout?hl=en
        </a>
        ).
        <br />
        <br />
        You can find Google's privacy policy at the following link:
        <a
          href="https://policies.google.com/privacy?hl=en"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://policies.google.com/privacy?hl=en
        </a>
        .
        <br />
        <br />
        Google is still certified under the Privacy Shield, but for data
        transfers, Google now relies on the standard contractual clauses.
        <br />
        <br />
        For this:
        <a
          href="https://support.google.com/analytics/answer/6004245?hl=en&ref_topic=2919631"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://support.google.com/analytics/answer/6004245?hl=en&ref_topic=2919631
        </a>
        and for the Privacy Shield:
        <br />
        <br />
        <a
          href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI
        </a>{' '}
        as well as
        <br />
        <br />
        <a
          href="https://policies.google.com/privacy/frameworks?hl=en&gl=en"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://policies.google.com/privacy/frameworks?hl=en&gl=en{' '}
        </a>{' '}
        .
        <br />
        <br />
        <span className={classes.header}>
          2. Status and currentness of this cookie information
        </span>
        <br />
        Status: March 2023
      </p>
    </Box>
  );
};
