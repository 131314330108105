import React, { FunctionComponent } from 'react';
import useStores from '../../hooks/use-stores';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Box, CircularProgress, Grid, Typography } from '@material-ui/core';
import {
  DEFAULT_BORDER_RADIUS,
  DEFAULT_GRADIENT_NO_WHITE,
  PAPER_BG_COLOR,
} from '../../utils/Constants';
import { makeStyles } from '@material-ui/core/styles';
import { MOOV_COLOR_BLUE } from '../../resources/colors';
import { InnerBoxes } from '../InnerBoxes';
import moment from 'moment/moment';
import { Asset } from '../../models/Folders';
import { observer } from 'mobx-react-lite';

export const useStyles = makeStyles(() => ({
  folderSectionRoundIcon: {
    borderRadius: '50%',
    background: DEFAULT_GRADIENT_NO_WHITE,
    height: 0,
    width: '100%',
    paddingBottom: '100%',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
  },
  icon: {
    position: 'absolute',
    bottom: '50%',
    transform: 'translateY(50%) scale(0.6)',
    height: 'auto',
    width: '100%',
  },
  folderLabel: {
    color: MOOV_COLOR_BLUE,
    fontWeight: 400,
    fontSize: 12,
    textAlign: 'center',
  },
}));

type NormalFolderProps = {
  id: string;
};

export const NormalFolder: FunctionComponent<NormalFolderProps> = observer(
  ({ id }) => {
    const {
      folderStore: { getAssetsInFolder, loading },
    } = useStores();

    const { t } = useTranslation(['common']);

    const history = useHistory();

    const assets = getAssetsInFolder(id);

    if (loading) {
      return (
        <Grid container justify="center">
          <Grid item>
            <CircularProgress size={100} />
          </Grid>
        </Grid>
      );
    }

    if (assets && assets.length === 0) {
      return (
        <Grid container justify="center">
          <Grid item>
            <Typography>{t('common:general.noItems')}</Typography>
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid container justify="center">
        {assets &&
          assets.map((asset: Asset) => (
            <Box p={2}>
              <Box
                width={200}
                height={200}
                style={{
                  background: PAPER_BG_COLOR,
                  flexShrink: 0,
                  borderRadius: DEFAULT_BORDER_RADIUS,
                }}
                display="flex"
                justifyContent="center"
                alignItems="center"
                alignContent="center"
                mb={1}
                onClick={() => history.push(`/asset/${asset.id}`)}
              >
                {asset.attachments ? (
                  <InnerBoxes attachments={asset.attachments} />
                ) : null}
              </Box>
              <Box
                width={200}
                height="auto"
                display="flex"
                justifyContent="center"
              >
                <Typography variant="subtitle2">
                  {asset.title
                    ? asset.title
                    : `${t('common:detailPage.collectionFrom')} ${moment(
                        asset.created
                      ).format('DD.MM.YY')}`}
                </Typography>
              </Box>
            </Box>
          ))}
      </Grid>
    );
  }
);
