import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import {
  CircularProgress, Grid, Paper,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import useResizeObserver from 'use-resize-observer';
import _ from 'lodash-es';
import Typography from '@material-ui/core/Typography';

type PDFViewerProps = {
  url: string,
};

/* eslint-disable @typescript-eslint/no-unused-vars */

const PDFViewer: FunctionComponent<PDFViewerProps> = ({ url }) => {
  const { t } = useTranslation(['common']);

  // PDF Page Management
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const onDocumentLoadSuccess = ({ numPages } : any) => {
    setNumPages(numPages);
  };

  // PDF Responsive Display Width Management
  // const {
  //   ref,
  //   // @ts-ignore
  //   width,
  //   // @ts-ignore
  //   height,
  // } = useDebouncedResizeObserver(5000);

  // Only rerender the page section when actually window size changed.
  // Prevent the ref from forcing rerendering the page secse
  const [delayedPageDimensions, setDelayedPageDimensions] = useState({
    width: 300,
    height: 500,
  });

  // width / height will not be returned here when the onResize callback is present
  const { ref } = useResizeObserver<HTMLDivElement>({
    onResize: ({ width, height }) => {
      const exec = () => {
        if (delayedPageDimensions.height !== height && delayedPageDimensions.width !== width) {
          setDelayedPageDimensions({
            // @ts-ignore
            width,
            // @ts-ignore
            height,
          });
        }
      };

      _.delay(() => exec(), 2);
    },
  });

  return (
    <div ref={ref}>
      <Document
        file={{ url, withCredentials: true }}
        loading={<CircularProgress />}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={() => toast.error(t('common:general.errorLoadingContent'))}
      >
        <Grid container justify="center">
          {
              Array.from(
                new Array(numPages),
                (el, index) => (
                  <Grid item style={{ borderRadius: 0, marginBottom: 30 }}>
                    <Paper style={{ borderRadius: 0, marginBottom: 10 }} elevation={6}>
                      <Page
                        key={`p_${index + 1}`}
                        pageNumber={index + 1}
                        width={delayedPageDimensions.width ?? 300}
                      />
                    </Paper>
                    <Typography style={{ textAlign: 'center' }}>
                      {t('common:pdfviewer.page')}
                      {' '}
                      {index + 1}
                      {' '}
                      {t('common:pdfviewer.of')}
                      {' '}
                      {numPages}
                    </Typography>
                  </Grid>
                ),
              )
            }
        </Grid>
      </Document>
    </div>
  );
};

export default PDFViewer;
