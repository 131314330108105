export class PreAuthResponse {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  constructor(login: boolean, register: boolean) {
    this.login = login;
    this.register = register;
  }

  login: boolean;

  register: boolean;
}
