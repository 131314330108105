import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Grid, Link as MuiLink, Typography, } from '@material-ui/core';
import { useLoginPageStyles } from './LoginPageStyles';
import useStores from '../../hooks/use-stores';
import { LoginState } from '../../models/LoginModels';
import { useTranslation } from 'react-i18next';

const ResetPasswordSentNotice:React.FC = observer(() => {
  const classes = useLoginPageStyles();
  const { t } = useTranslation(['common', 'validation']);

  const {
    moovyAuthStore: {
      setStep,
      mail,
    },
  } = useStores();

  // E-Mail Validation with Formik
  return (
    <Box className={classes.form} display="flex" justifyContent="center" p={2}>
      <Grid
        container
        spacing={2}
        justify="center"
        alignItems="center"
        direction="column"
      >
        <Grid item style={{ textAlign: 'center' }}>
          <Typography variant="h5">
            {t('common:login.resetPasswordSent')}
            {' '}
          </Typography>
        </Grid>
        <Grid item style={{ textAlign: 'center' }}>
          <Typography>{mail}</Typography>
        </Grid>
        <Grid item style={{ textAlign: 'center' }}>
          <Typography>{t('common:login.resetPasswordSentText')}</Typography>
        </Grid>
        <Grid item>
          <MuiLink onClick={() => setStep(LoginState.initial)}>
            {t('common:general.back')}
          </MuiLink>
        </Grid>

      </Grid>
    </Box>
  );
});

export default ResetPasswordSentNotice;
