import { makeStyles, useTheme } from '@material-ui/core/styles';
import { MOOV_COLOR_WHITE } from '../../resources/colors';
import { DEFAULT_BORDER_RADIUS, DEFAULT_GRADIENT_NO_WHITE } from '../../utils/Constants';
import { green } from '@material-ui/core/colors';
import React from 'react';
import Slide from '@material-ui/core/Slide';
import { observer } from 'mobx-react-lite';
import useStores from '../../hooks/use-stores';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { FormikValues, useFormik } from 'formik';
import { CircularProgress, TextField, useMediaQuery } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Grid from '@material-ui/core/Grid';
import DialogActions from '@material-ui/core/DialogActions';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import { UserAssetTag } from "../../stores/TagStore";
import { toast } from "react-toastify";

const useStylesForAddTagDialog = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    backgroundColor: MOOV_COLOR_WHITE,
    color: theme.palette.primary.main,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  button: {
    background: DEFAULT_GRADIENT_NO_WHITE,
    borderRadius: DEFAULT_BORDER_RADIUS,
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));
// @ts-ignore
// @ts-ignore
const Transition: any = React.forwardRef((props, ref) => (
  // @ts-ignore
  <Slide
    direction="up"
    ref={ref}
    {...props}
  />
));
export const AddTagDialog = observer(() => {
  // Store
  const {
    tagStore: {
      isAddTagDialogOpen,
      setAddTagDialogOpen,
      loading,
      createTag,
    },
  } = useStores();

  // Style Classes
  const classes = useStylesForAddTagDialog();

  // Translation
  const { t } = useTranslation(['upload', 'validation', 'common']);

  // Formik Begin
  const validationSchema = yup.object({
    name: yup
      .string()
      .min(1, t('validation:general.fieldRequired'))
      .max(100, t('validation:general.fieldTooLong'))
      .required(t('validation:general.fieldRequired')),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema,
    onSubmit: ((values: FormikValues) => {
      console.warn(values);
      createTag(values as Partial<UserAssetTag>)
        .then(() => {
          setAddTagDialogOpen(false);
          toast.success(t('common:tags.addTagSuccess'));
        })
        .finally(() => {
          formik.resetForm();
        });
    }),
  });

  // Formik End

  // Handle Size of Dialog
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      open={isAddTagDialogOpen}
      onClose={() => setAddTagDialogOpen(false)}
      TransitionComponent={Transition}
      fullScreen={fullScreen}
      maxWidth="sm"
      fullWidth
    >
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle id="responsive-dialog-title">
          {t('common:tags.addTag')}
        </DialogTitle>

        <DialogContent dividers>

          <DialogContentText>

            <Grid container direction="column" spacing={2}>

              <Grid item>
                <TextField
                  id="name"
                  label={t('common:tags.newTagName')}
                  fullWidth
                  variant="outlined"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.name
                    && Boolean(formik.errors.name)
                  }
                  helperText={formik.touched.name && formik.errors.name}
                  autoFocus
                />
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Box justifyContent="center" p={1}>

            <Button
              onClick={() => setAddTagDialogOpen(false)}
              color="primary"
              size="large"
              startIcon={<CloseIcon />}
            >
              {t('common:general.cancel')}
            </Button>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              disabled={loading}
              className={classes.button}
              startIcon={loading ? (
                <CircularProgress size={10} />
              ) : (
                <SaveIcon />
              )}
            >
              {t('common:general.add')}
            </Button>
          </Box>
        </DialogActions>
      </form>

    </Dialog>
  );
});
