import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  footerTextItem: {
    fontSize: theme.typography.fontSize - 4,
    color: theme.palette.primary.contrastText,
  },
  footerTextSeperator: {
    color: theme.palette.primary.contrastText,
    fontSize: theme.typography.fontSize - 4,
    marginLeft: 5,
    marginRight: 5,
  },
}));

export default function Footer() {
  const classes = useStyles();
  const { t } = useTranslation(['common']);
  return (
    <Box
      display="flex"
      p={1}
      flexGrow={1}
      justifyContent="center"
      flexWrap="wrap"
      alignItems="center"
    >
      <Link to="/legal">
        <Typography className={classes.footerTextItem}>
          {t('common:footer.legal')}
        </Typography>
      </Link>
      <Typography className={classes.footerTextSeperator}>|</Typography>
      <Link to="/privacy">
        <Typography className={classes.footerTextItem}>
          {t('common:footer.privacy')}
        </Typography>
      </Link>
      {/* <Typography className={classes.footerTextSeperator}>|</Typography>
      <Link to="/terms">
        <Typography className={classes.footerTextItem}>{t('common:footer.terms')}</Typography>
      </Link> */}
    </Box>
  );
}
