/* eslint-disable */
// @ts-nocheck
import { Box, makeStyles, Typography } from '@material-ui/core';
import TransparentPaper from '../../components/TransparentPaper';

const useStyles = makeStyles(() => ({
  container: {
    height: '100%',
  },
  wrapper: {
    padding: 30,
  },
  header: {
    fontSize: 20,
    color: '#005798',
  },
}));
export const ImpressumTextGerman = () => {
  const classes = useStyles();
  return (
    <TransparentPaper>
      <Box className={classes.wrapper}>
        <Typography variant="h1">Impressum</Typography>
        <p>
          Angaben gem. § 5 TMG
          <br />
        </p>
        <p>
          <span className={classes.header}>Betreiber und Kontakt</span>
          <br />
          MoovyMed GmbH
          <br />
          Werftstr. 12
          <br />
          76189 Karlsruhe
        </p>
        <p>
          E-Mail: <a href="mailto:info@moovymed.de">info@moovymed.de</a>
          <br />
          <br />
          Telefonnummer: +49 721 4763350
        </p>
        <p>
          <span className={classes.header}>Vertretung</span>
          <br />
          MoovyMed GmbH wird vertreten durch die Geschäftsführer Dr. Martin
          Benedict | Martin Taller
          <br />
          <br />
          Registergericht: Amtsgericht Mannheim HRB 748972
        </p>
        <p>
          <span className={classes.header}>
            Online-Streitbeilegung gemäß Art. 14 Abs. 1 ODR-VO
          </span>
          <br />
          Die Europäische Kommission stellt eine Plattform zur
          Online-Streitbeilegung (OS) bereit:{' '}
          <a
            href="https://ec.europa.eu/consumers/odr"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://ec.europa.eu/consumers/odr
          </a>
          . Unsere E-Mail-Adresse finden Sie oben im Impressum. Wir sind nicht
          bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer
          Verbraucherschlichtungsstelle teilzunehmen.
        </p>
        <p>
          <span className={classes.header}>Bildnachweis</span>
          <br />
          iStock 612016238:{' '}
          <a
            href="https://www.istockphoto.com/de/portfolio/filadendron?mediatype=photography"
            target="_blank"
            rel="noopener noreferrer"
          >
            Todor Tsvetkov
          </a>
          <br />
          iStock 611776510:{' '}
          <a
            href="https://www.istockphoto.com/de/portfolio/cyano66?mediatype=photography"
            target="_blank"
            rel="noopener noreferrer"
          >
            cyano66
          </a>
          <br />
          iStock 611776510:{' '}
          <a
            href="https://www.istockphoto.com/de/portfolio/cyano66?mediatype=photography"
            target="_blank"
            rel="noopener noreferrer"
          >
            cyano66
          </a>{' '}
          unsplash hIz3vbUbOoI:{' '}
          <a
            href="https://unsplash.com/@danny144"
            target="_blank"
            rel="noopener noreferrer"
          >
            Dan Nelson
          </a>
        </p>
      </Box>
    </TransparentPaper>
  );
};
