/**
 * Selection of Autocomplete / ComboBoxes for various Interactions with Tags
 */
import { TextField } from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { FormikErrors } from 'formik/dist/types';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import useStores from '../hooks/use-stores';
import { UserAssetTag } from '../stores/TagStore';

const filter = createFilterOptions<UserAssetTagAutocomplete>();

interface UserAssetTagAutocomplete extends UserAssetTag {
  // Input Value is a raw string which represent the value the user entered into the autocomplete.
  inputValue?: string;
}

type TagCreatableBoxProps = {
  // Mutable array, e.g. formik
  preSelectedTags?: Array<UserAssetTag>;
  formik?: {
    autocompleteSetFieldValue?: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => Promise<FormikErrors<any>> | Promise<void>;
    autocompleteOnBlur?: React.FocusEventHandler;
    textfieldError?: boolean;
    textfieldFormHelperText?: string;
    selectedCategory?: string;
  };
};

export const TagCreatableBox: React.FC<TagCreatableBoxProps> = observer(
  ({ preSelectedTags, formik }) => {
    const {
      tagStore: { getAllTags, loading, createTag },
    } = useStores();

    const { t } = useTranslation(['common']);

    const [selectedTags, setSelectedTags] = useState<
      (UserAssetTagAutocomplete | string)[] | undefined
    >(preSelectedTags ?? undefined);

    const [extractedTags, setExtractedTags] =
      useState<UserAssetTagAutocomplete[]>();
    const showExtraTags =
      formik?.selectedCategory === 'Befunde' ||
      formik?.selectedCategory === 'Medical Results' ||
      formik?.selectedCategory === 'Bilder' ||
      formik?.selectedCategory === 'Images';

    useEffect(() => {
      const fetchTags = async () => {
        getAllTags(undefined, showExtraTags).then((res) => {
          if (preSelectedTags && preSelectedTags.length > 0) {
            setExtractedTags(
              res.data.filter(
                (tag: any) =>
                  !preSelectedTags.some(
                    (selectedTag) => selectedTag.name === tag.name
                  )
              )
            );
          } else {
            setExtractedTags(res.data);
          }
        });
      };
      fetchTags();
    }, [showExtraTags]); // eslint-disable-line
    if (!extractedTags) return null;
    return (
      <>
        <Autocomplete
          key={`tags-${selectedTags?.length}`}
          id="tagfilter"
          options={extractedTags}
          multiple
          loading={loading}
          filterSelectedOptions
          freeSolo
          autoSelect
          defaultValue={preSelectedTags as UserAssetTagAutocomplete[]}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={t('common:tags.addTag')}
              placeholder={t('common:tags.addTag')}
              error={formik?.textfieldError}
              helperText={t('common:tags.helpText')}
            />
          )}
          onFocus={
            () => getAllTags() /* Ensure that latest tags are available */
          }
          getOptionSelected={(option, value) => option.name === value.name}
          // eslint-disable-next-line padded-blocks
          onChange={(event, newValues, reason) => {
            console.warn(reason);
            const lastAddedElement = newValues[newValues.length - 1];
            if (
              lastAddedElement &&
              Object.keys(lastAddedElement).includes('inputValue')
            ) {
              console.log('here 1');
              console.warn('inputValue on Object detected', lastAddedElement);
              const lastAddedWithInputValue =
                newValues.pop() as UserAssetTagAutocomplete;
              console.log(
                'astAddedWithInputValue?.inputValue',
                lastAddedWithInputValue?.inputValue
              );
              createTag({ name: String(lastAddedWithInputValue?.inputValue) }) // Create a new tag with the last string
                .then((res) => {
                  toast.success(
                    `${t('common:tags.addTagSuccess')}: ${res.data.name}`
                  );
                  newValues.push(res.data); // place the new tag as selected tag
                  if (formik?.autocompleteSetFieldValue) {
                    formik.autocompleteSetFieldValue('tags', newValues);
                  }
                  setSelectedTags(newValues);
                });
            } else {
              console.log('here 3');

              if (formik?.autocompleteSetFieldValue) {
                formik.autocompleteSetFieldValue('tags', newValues);
              }
              console.log('new values', newValues);

              setSelectedTags(newValues);
            }
            // New Value is a string?
            // const lastAddedElement = newValues[newValues.length - 1];
            // if (typeof lastAddedElement === 'string') {
            //   const lastString = newValues.pop(); // Remove the string and remember it
            //   createTag({ name: String(lastString) }) // Create a new tag with the last string
            //     .then((res) => {
            //       toast.success(`${t('common:tags.addTagSuccess')}: ${res.data.name}`);
            //       newValues.push(res.data); // add the newly created tag to the value list
            //       setValueList(newValues);
            //     });
            // } else {
            //   setValueList(newValues);
            // }
          }}
          filterOptions={(options, state) => {
            const filtered = filter(options, state);

            // Suggest the creation of a new value
            if (state.inputValue !== '') {
              filtered.push({
                id: '',
                style: undefined,
                inputValue: state.inputValue,
                name: `${t('common:tags.addTag')}: "${state.inputValue}"`,
              });
            }

            return filtered;
          }}
          getOptionLabel={(option) => {
            // Value selected with enter, right from the input
            if (typeof option === 'string') {
              return option;
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
              return option.name;
            }
            // Regular option
            return option.name;
          }}
          onBlur={formik?.autocompleteOnBlur}
        />
      </>
    );
  }
);
