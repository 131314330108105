import { Container, Grid, makeStyles, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import {
  DEFAULT_CONTAINER_WIDTH,
  DEFAULT_GRID_SPACING,
} from '../utils/Constants';
import { Redirect, useParams } from 'react-router';
import ButtonBack from '../components/ButtonBack';
import TransparentBackgroundWrapper from '../components/TransparentBackgroundWrapper';
import { NormalFolder } from '../components/FolderPage/NormalFolder';
import { LargeHeaderFolder } from '../components/FolderPage/LargeHeaderFolder';
import useStores from '../hooks/use-stores';
import UploadButton from '../components/UploadButton';
import { UploadDialog } from '../components/UploadDialog';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

// Reactive Component using observer
const FolderPage = observer(() => {
  const {
    folderStore: { getFolders, getChildrenForId },
  } = useStores();

  const classes = useStyles();
  // @ts-ignore
  const { id } = useParams();

  useEffect(() => {
    getFolders().then(() => getChildrenForId(id));
  }, []); // eslint-disable-line

  // Return to home if no id is given
  if (!id) {
    return <Redirect to="/home" />;
  }

  return (
    <Container maxWidth={DEFAULT_CONTAINER_WIDTH} className={classes.container}>
      <TransparentBackgroundWrapper marginBottom={20}>
        <Grid container spacing={DEFAULT_GRID_SPACING}>
          <Grid item xs={12}>
            <ButtonBack />
          </Grid>

          <Grid item xs={12}>
            <LargeHeaderFolder id={id} />
          </Grid>

          <UploadDialog preSelectFolderId={id} />

          <Grid
            item
            xs={12}
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              position: 'relative',
            }}
          >
            <UploadButton />
          </Grid>

          <Grid item xs={12}>
            <NormalFolder id={id} />
          </Grid>
        </Grid>
      </TransparentBackgroundWrapper>
    </Container>
  );
});

export default FolderPage;
