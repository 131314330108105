import React, { FunctionComponent } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  TextField,
  Theme,
} from '@material-ui/core';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import useStores from '../hooks/use-stores';
import CloseIcon from '@material-ui/icons/Close';
import { DEFAULT_GRADIENT_NO_WHITE, DEFAULT_BORDER_RADIUS } from '../utils/Constants';

const useStyles = makeStyles((theme: Theme) => ({
  textfield: {
    marginBottom: theme.spacing(1),
  },
  button: {
    background: DEFAULT_GRADIENT_NO_WHITE,
    borderRadius: DEFAULT_BORDER_RADIUS,
  },
}));

const FeedbackDialog: FunctionComponent = observer(() => {
  const { t } = useTranslation(['common', 'validation']);
  const classes = useStyles();
  const {
    dialogStore: {
      isFeedbackDialogOpen,
      setFeedbackDialogOpen,
      setFeedbackContent,
    },
  } = useStores();

  // yup validation scheme
  const feedbackValidationSchema = yup.object({
    inputTitle: yup
      .string()
      .required(t('validation:general.fieldRequired')),
    inputFeedback: yup
      .string()
      .required(t('validation:general.fieldRequired')),
  });

  // init formik for this component
  const feedbackFormik = useFormik({
    initialValues: {
      inputTitle: '',
      inputFeedback: '',
    },
    validationSchema: feedbackValidationSchema,
    onSubmit: (values) => {
      setFeedbackContent(values.inputTitle, values.inputFeedback).then(() => {
        toast.success(t('common:dialog.feedbackSuccess'));
      }).finally(() => {
        feedbackFormik.resetForm();
        setFeedbackDialogOpen(false);
      });
    },

  });

  return (

    <Dialog open={isFeedbackDialogOpen}>

      <form onSubmit={feedbackFormik.handleSubmit} autoComplete="off">
        <DialogTitle>{t('common:dialog.yourFeedback')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('common:dialog.textFeedback')}

          </DialogContentText>

          <TextField
            id="inputTitle"
            label={t('common:dialog.titlePlaceholder')}
            className={classes.textfield}
            autoFocus
            placeholder={t('common:dialog.titlePlaceholder')}
            type="text"
            variant="outlined"
            fullWidth
            required
            value={feedbackFormik.values.inputTitle}
            onChange={feedbackFormik.handleChange}
            onBlur={feedbackFormik.handleBlur}
            error={feedbackFormik.touched.inputTitle && Boolean(feedbackFormik.errors.inputTitle)}
            helperText={feedbackFormik.touched.inputTitle && feedbackFormik.errors.inputTitle}
          />
          <TextField
            id="inputFeedback"
            // label="Your Feddback"
            label={t('common:dialog.yourFeedback')}
            className={classes.textfield}
            placeholder={t('common:dialog.contentPlaceholder')}
            type="text"
            variant="outlined"
            multiline
            rowsMax={7}
            fullWidth
            required
            value={feedbackFormik.values.inputFeedback}
            onChange={feedbackFormik.handleChange}
            onBlur={feedbackFormik.handleBlur}
            error={feedbackFormik.touched.inputFeedback
            && Boolean(feedbackFormik.errors.inputFeedback)}
            helperText={feedbackFormik.touched.inputFeedback
            && feedbackFormik.errors.inputFeedback}
          />
        </DialogContent>
        <DialogActions>

          <Button
            onClick={() => {
              setFeedbackDialogOpen(false);
              feedbackFormik.resetForm();
            }}
            color="primary"
            size="large"
            startIcon={<CloseIcon />}
          >
            {t('common:general.cancel')}
          </Button>

          <Button
            className={classes.button}
            type="submit"
            color="primary"
            variant="contained"
          >
            {t('common:general.send')}
          </Button>

        </DialogActions>
      </form>

    </Dialog>

  );
});

export default FeedbackDialog;
