import React from 'react';
import { useTranslation } from 'react-i18next';
import TransparentBackgroundWrapper from '../components/TransparentBackgroundWrapper';
import { Container, Grid, Typography } from '@material-ui/core';
import { DEFAULT_CONTAINER_WIDTH } from '../utils/Constants';

export const RoutingErrorPage: React.FC = () => {
  const { t } = useTranslation(['common']);
  return (
    <Container maxWidth={DEFAULT_CONTAINER_WIDTH}>
      <TransparentBackgroundWrapper>
        <Grid container direction="column">
          <Grid item>
            <Typography variant="h1">{t('common:general.oops')}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h5">{t('common:general.notFound')}</Typography>
          </Grid>
        </Grid>
      </TransparentBackgroundWrapper>
    </Container>
  );
};
