import { makeStyles, Theme } from '@material-ui/core';
import {
  DEFAULT_BORDER_RADIUS,
  DEFAULT_GRADIENT_NO_WHITE,
  PAPER_BG_COLOR,
} from '../../utils/Constants';

export const useLoginPageStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    height: 'auto',
    margin: theme.spacing(2),
    maxWidth: 400,
  },
  image: {
    width: '100%',
    height: 'auto',
  },
  form: {
    padding: theme.spacing(2),
    minWidth: 350,
    [theme.breakpoints.down('sm')]: {
      minWidth: 250,
    },
  },
  footer: {
    marginTop: 'auto',
  },
  button: {
    background: DEFAULT_GRADIENT_NO_WHITE,
    borderRadius: DEFAULT_BORDER_RADIUS,
  },
  paper: {
    display: 'flex',
    backdropFilter: 'blur(20px)',
    backgroundColor: PAPER_BG_COLOR,
    borderRadius: DEFAULT_BORDER_RADIUS,
    padding: theme.spacing(1),
    width: 'min-content',
  },
  textfield: {
    width: 400,
    [theme.breakpoints.down('sm')]: {
      width: 270,
    },
  },
  textfield_invisible: {
    opacity: 0,
    width: 0,
    height: 0,
  },
}));
