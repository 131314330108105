import { Box, Container, Grid, makeStyles, Paper, Theme, Tooltip, Typography, useTheme } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { RootFolderSection } from '../components/HomePage/RootFolderSection';
import UploadButton from '../components/UploadButton';
import { UploadDialog } from '../components/UploadDialog';
import {
  DEFAULT_BORDER_RADIUS,
  DEFAULT_CONTAINER_WIDTH,
  PAPER_BG_COLOR,
} from '../utils/Constants';
import FolderViewMultipleIcons from '../components/FolderViewMultipleIcons';
import { TagFilterSection } from "../components/TagFilterSection";
import { CenteredSectionHeadline } from "../components/CenteredSectionHeadline";
import { useTranslation } from "react-i18next";
import useStores from '../hooks/use-stores';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    backdropFilter: 'blur(20px)',
    backgroundColor: PAPER_BG_COLOR,
    borderRadius: DEFAULT_BORDER_RADIUS,
    padding: theme.spacing(1),
    width: '100%',
  },
  lastAddedBackground: {
    backdropFilter: 'blur(20px) hue-rotate(20deg)',
    backgroundColor: 'transparent',
    borderRadius: DEFAULT_BORDER_RADIUS,
    padding: theme.spacing(1),
    width: '100%',
  },
}));

// Reactive Component using observer
const HomePage = observer(() => {
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation(['common']);

  const {
    userAssetStore: {
      userProfile,
      getUserProfile,
    },
  } = useStores();

  useEffect(() => {
    getUserProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { name, email } = userProfile;

  return (
    <Container maxWidth={DEFAULT_CONTAINER_WIDTH} className={classes.container}>

      <UploadDialog />

      <Box marginY={theme.spacing(0.5)} width="100%">
        <Grid container justify="center">
          <UploadButton />
        </Grid>
      </Box>
      <Box height={40}>
        {(name || email) && (
        <Typography variant="h5">
          {t('common:login.hello')}
          {' '}
          {name || email}
          !
        </Typography>
        )}
      </Box>

      <Box marginY={theme.spacing(0.2)} width="100%">
        <FolderViewMultipleIcons />
      </Box>

      <Box marginY={theme.spacing(0.2)} width="100%">
        <Paper className={classes.paper} elevation={0}>
          <TagFilterSection />
        </Paper>
      </Box>

      <Box marginY={theme.spacing(0.2)} width="100%">
        <Paper className={classes.paper} elevation={0}>
          <RootFolderSection />
        </Paper>
      </Box>

    </Container>
  );
});

export default HomePage;
