import { UserProfile, Attachment, UserAsset } from '../models/UserAssetModel.d';
import { makeAutoObservable, runInAction, toJS } from 'mobx';
import { deleteReq, get, put } from '../utils/RequestManager';
import axios, { AxiosResponse, CancelTokenSource } from 'axios';
import { handleError } from '../utils/ErrorUtils';
import moment from 'moment/moment';

/**
 * This store contains and manages user assets.
 */
export class UserAssetStore {
  inboxItems: Array<UserAsset> = [];

  singleUserAsset: Partial<UserAsset> = {};

  userProfile: Partial<UserProfile> = {};

  updatedUserProfile: Partial<UserProfile> = {};

  loading: boolean = false;

  singleItemId: string = '';

  currentActiveAttachment: { index: number; attachment: Partial<Attachment> } =
    { index: 0, attachment: {} };

  isAttachmentDialogOpen: boolean = false;

  axiosUploadCancelToken: CancelTokenSource | undefined = undefined;

  uploading: boolean = false;

  percentComplete: number = 0;

  isAddToAttachmentDialogOpen: boolean = false;

  isResetPasswordFormOpen: boolean = false;

  constructor() {
    this.getUserAssetsInRootFolder();
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  get assetDateFromNow(): string {
    return moment(this.singleUserAsset.created).fromNow();
  }

  get assetDateLocalString(): string {
    return moment(this.singleUserAsset.created).format('D MMMM YYYY, HH:mm:ss');
  }

  setCurrentActiveAttachment(index: number, attachment: Attachment) {
    this.currentActiveAttachment = { index, attachment };
    console.warn(toJS(this.currentActiveAttachment));
  }

  setLoading(loading: boolean): void {
    this.loading = loading;
  }

  setAttachmentDialogOpen(
    open: boolean,
    index?: number,
    attachment?: Attachment
  ): void {
    if (attachment) this.setCurrentActiveAttachment(index!, attachment);
    else this.currentActiveAttachment = { index: 0, attachment: {} };
    this.isAttachmentDialogOpen = open;
  }

  setResetPasswordFormOpen(open: boolean): void {
    this.isResetPasswordFormOpen = open;
  }

  // Gets all user assets which are not assigned to any folder
  async getUserAssetsInRootFolder(): Promise<AxiosResponse> {
    try {
      const res = await get('/userasset');
      runInAction(() => {
        this.inboxItems = res.data.reverse(); // API gives oldest item first -__-
      });
      return res;
    } catch (e: any) {
      return handleError(e);
    }
  }

  // Gets a user profile
  async getUserProfile(): Promise<AxiosResponse> {
    try {
      const res = await get(`/user/profile`);
      runInAction(() => {
        this.userProfile = res.data;
      });
      return res;
    } catch (e: any) {
      return handleError(e);
    }
  }

  // Updates user profile
  async updateUserProfile(
    updatedUserProfile: Partial<UserProfile>
  ): Promise<AxiosResponse> {
    console.log(updatedUserProfile);
    try {
      const res = await put(`/user/profile`, updatedUserProfile);
      runInAction(() => {
        this.updatedUserProfile = res.data;
      });
      return res;
    } catch (e: any) {
      console.log(e.message);
      return handleError(e);
    }
  }

  // Gets a single user asset by id.
  async getUserAsset(id: string): Promise<AxiosResponse> {
    try {
      const res = await get(`/userasset/${id}`);
      runInAction(() => {
        this.singleUserAsset = res.data;
      });
      return res;
    } catch (e: any) {
      return handleError(e);
    }
  }

  async updateDocument(
    updatedInboxItem: Partial<UserAsset>
  ): Promise<AxiosResponse> {
    console.log(updatedInboxItem);
    try {
      const res = await put(
        `/userasset/${updatedInboxItem.id}`,
        updatedInboxItem
      );
      runInAction(() => {
        this.singleUserAsset = res.data;
      });
      return res;
    } catch (e: any) {
      return handleError(e);
    }
  }

  async deleteUserAsset(): Promise<AxiosResponse> {
    try {
      const res = await deleteReq(`/userasset/${this.singleUserAsset.id}`);
      runInAction(() => {
        // Ensure inboxItems are without the deleted asset.
        this.singleUserAsset = {};
        this.getUserAssetsInRootFolder();
      });
      return res;
    } catch (e: any) {
      return handleError(e);
    }
  }

  async deleteDocument(attachmentId: string): Promise<AxiosResponse> {
    try {
      const res = await deleteReq(`/documents/${attachmentId}`);
      runInAction(() => {
        this.currentActiveAttachment = { index: 0, attachment: {} };
        const { id } = this.singleUserAsset;
        this.getUserAsset(id!);
      });
      return res;
    } catch (e: any) {
      return handleError(e);
    }
  }

  setAddAttachmentDialogOpen(open: boolean): void {
    this.isAddToAttachmentDialogOpen = open;
  }

  async addAttachmentsToAsset(files: File[]): Promise<AxiosResponse> {
    try {
      // Lets add to the current asset
      const { id, title } = this.singleUserAsset;

      console.warn('currentAsset', id, title);

      const formData = new FormData();
      files.forEach((file: File) => formData.append('file', file));

      runInAction(() => {
        // create a new cancel token for this upload
        this.axiosUploadCancelToken = axios.CancelToken.source();
        // gui changes
        this.uploading = true;
      });

      const res = await put(`/userasset/${id}/attachements`, formData, {
        headers: {
          'content-type': 'multipart/form-data',
        },
        cancelToken: this.axiosUploadCancelToken?.token,
        onUploadProgress: (progress) => {
          runInAction(() => {
            this.percentComplete = Math.floor(
              (progress.loaded * 100) / progress.total
            );
            if (this.percentComplete >= 100) {
              this.uploading = false;
              // Progress is done here, but we have no response.
            }
          });
        },
      });

      // Here we have response

      runInAction(() => {
        this.getUserAsset(id!);
      });

      return res;
    } catch (e: any) {
      runInAction(() => {
        this.setAddAttachmentDialogOpen(false);
        this.uploading = false;
      });
      return handleError(e);
    }
  }

  cancelAddAttachmentToAsset() {
    this.axiosUploadCancelToken?.cancel('Upload cancelled');
  }
}
