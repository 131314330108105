import { Container, makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import TransparentPaper from '../../components/TransparentPaper';
import { DEFAULT_CONTAINER_WIDTH } from '../../utils/Constants';
import { PrivacyTextEnglish } from './PrivacyTextEnglish';
import { PrivacyTextGerman } from './PrivacyTextGerman';

const useStyles = makeStyles(() => ({
  container: {
    height: '100%',
  },
  wrapper: {
    padding: 30,
  },
  ul: {
    listStyleType: 'disc',
  },
}));

const PrivacyPage: React.FC = () => {
  const classes = useStyles();
  const { i18n } = useTranslation('common');

  return (
    <Container maxWidth={DEFAULT_CONTAINER_WIDTH} className={classes.container}>
      <TransparentPaper>
        {i18n.language === 'de' ? (
          <PrivacyTextGerman />
        ) : (
          <PrivacyTextEnglish />
        )}
      </TransparentPaper>
    </Container>
  );
};

export default PrivacyPage;
