import { Grid, Link as MuiLink, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLoginPageStyles } from './LoginPageStyles';
import { LoginState } from '../../models/LoginModels';
import useStores from '../../hooks/use-stores';

export default function LogoutSuccess() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const classes = useLoginPageStyles();

  const {
    moovyAuthStore: {
      setStep,
    },
  } = useStores();

  const { t } = useTranslation(['common']);

  return (
    <Grid
      container
      spacing={2}
      justify="center"
      alignItems="center"
      direction="column"
    >

      <Grid item>
        <Typography variant="h5">
          {t('common:login.logoutSuccess')}
        </Typography>
      </Grid>

      <Grid item style={{ textAlign: 'center' }}>
        <Typography paragraph variant="body2">
          {t('common:login.logoutHaveANiceDay')}
        </Typography>
      </Grid>

      {/* <Grid item>
        <Button
          className={classes.button}
          disableElevation
          type="submit"
          size="large"
          variant="contained"
          color="primary"
          fullWidth
          onClick={resendVerificationEmail}
        >
          {t('common:login.sendVerficationMailAgain')}
        </Button>
      </Grid> */}

      <Grid item>
        <MuiLink onClick={() => {
          setStep(LoginState.initial);
        }}
        >
          {t('common:login.loginLabel')}
        </MuiLink>
      </Grid>
    </Grid>
  );
}
