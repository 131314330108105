import React from 'react';
import { observer } from 'mobx-react-lite';
// import { useLoginPageStyles } from './LoginPageStyles';
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Link as MuiLink,
} from '@material-ui/core';
import { useLoginPageStyles } from './LoginPageStyles';
import useStores from '../../hooks/use-stores';
import { LoginState } from '../../models/LoginModels';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useFormik } from 'formik';

const ResetPasswordForm = observer(() => {
  const classes = useLoginPageStyles();
  const { t } = useTranslation(['common', 'validation']);

  const {
    moovyAuthStore: { setStep, mail, sendResetPasswordMail },
  } = useStores();

  const validationSchema = yup.object({
    mail: yup
      .string()
      .email(t('validation:login.mailDeformedError'))
      .required(t('validation:general.fieldRequired')),
  });

  const formik = useFormik({
    initialValues: {
      mail: mail ?? '',
    },
    validationSchema,
    onSubmit: (values) => {
      sendResetPasswordMail(values.mail);
    },
  });
  // E-Mail Validation with Formik
  return (
    <Box className={classes.form} display="flex" justifyContent="center" p={2}>
      <form autoComplete="off" onSubmit={formik.handleSubmit}>
        <Grid
          container
          spacing={2}
          justify="center"
          alignItems="center"
          direction="column"
        >
          <Grid item style={{ textAlign: 'center' }}>
            <Typography variant="h5">
              {t('common:login.resetPassword')}{' '}
            </Typography>
          </Grid>
          <Grid item style={{ textAlign: 'center' }}>
            <Typography>{t('common:login.resetPasswordText')}</Typography>
          </Grid>
          <Grid item>
            <TextField
              autoComplete="username"
              id="mail"
              fullWidth
              variant="outlined"
              label="E-mail*"
              value={formik.values.mail}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.mail && Boolean(formik.errors.mail)}
              helperText={formik.touched.mail && formik.errors.mail}
            />
          </Grid>
          <Grid item>
            <Button
              className={classes.button}
              disableElevation
              type="submit"
              size="large"
              variant="contained"
              color="primary"
            >
              E-mail senden
            </Button>
          </Grid>

          <Grid item>
            <MuiLink onClick={() => setStep(LoginState.initial)}>
              {t('common:general.back')}
            </MuiLink>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
});

export default ResetPasswordForm;
