import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  image: {
    width: '100%',
    height: 'auto',
  },
}));

type ImageWrapperZoomProps = {
  src: string,
};

const ImageViewer: FunctionComponent<ImageWrapperZoomProps> = ({ src }) => {
  const classes = useStyles();

  return <img src={src} alt={src} className={classes.image} />;

  // return (
  //   <TransformWrapper
  //     defaultScale={1}
  //     defaultPositionX={200}
  //     defaultPositionY={100}
  //   >
  //
  //     {({
  //       zoomIn,
  //       zoomOut,
  //     }: any) => (
  //       <>
  //         <TransformComponent>
  //           <img src={src} alt={src} className={classes.image} />
  //         </TransformComponent>
  //         <Box>
  //           <IconButton onClick={zoomIn}><ZoomInIcon /></IconButton>
  //           <IconButton onClick={zoomOut}><ZoomOutIcon /></IconButton>
  //         </Box>
  //       </>
  //     )}
  //
  //   </TransformWrapper>
  //
  // );
};

export default ImageViewer;
