import {
  Typography, Grid, Container,
} from '@material-ui/core';
import React from 'react';
import TransparentBackgroundWrapper from '../TransparentBackgroundWrapper';
import { DEFAULT_CONTAINER_WIDTH } from '../../utils/Constants';
import ButtonBack from '../ButtonBack';
import { useTranslation } from 'react-i18next';
import Box from "@material-ui/core/Box";

type SettingsSubpageProps = {
  // Title of the settings page
  title: string;
  // Short description text for the user. Optional.
  description: string;
};

export const SettingsSubpage: React.FunctionComponent<SettingsSubpageProps> = (
  {
    children,
    title,
    description,
  },
) => {
  const { t } = useTranslation(['common']);
  return (
    <Container maxWidth={DEFAULT_CONTAINER_WIDTH}>
      <TransparentBackgroundWrapper>
        <Grid container spacing={2}>

          <Grid item xs={12}>
            <ButtonBack goBackTo="settings" />
          </Grid>

          <Grid item>
            <Typography variant="h2">{title}</Typography>
            <Typography variant="subtitle1">{description}</Typography>
          </Grid>

        </Grid>

        <Box my={2}>
        {children ?? <Typography>{t('common:general.noItems')}</Typography>}
        </Box>
      </TransparentBackgroundWrapper>
    </Container>
  );
};
