import { makeAutoObservable, runInAction } from 'mobx';
// import { handleError } from '../utils/ErrorUtils';
import { AxiosResponse } from 'axios';

import { get } from '../utils/RequestManager';
import { UserAsset } from '../models/UserAssetModel';

export class SearchStore {
  searchResults: Array<Partial<UserAsset>> = [];

  loading: boolean = false;

  isSearchDialogOpen: boolean = false;

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  setSearchDialogOpen(open: boolean):void {
    if (!open) this.searchResults = [];
    this.isSearchDialogOpen = open;
  }

  async search(keyword: string): Promise<AxiosResponse> {
    try {
      const res = await get(`/userasset/searchbytext?filter=${keyword}`);
      runInAction(() => {
        this.searchResults = res.data;
      });
      return res;
      console.log(res.data);
    } catch (e) {
      console.log(e.message);
      //   return handleError(e);
      return (e);
    }
  }
}
