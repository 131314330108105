import { CircularProgress, Container, Grid, Tooltip, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Redirect, useHistory, useParams } from 'react-router';
import DetailPageForm from '../components/DetailPage/DetailPageForm';
import TransparentBackgroundWrapper from '../components/TransparentBackgroundWrapper';
import { observer } from 'mobx-react-lite';
import useStores from '../hooks/use-stores';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
// import AttachmentThumbnailSlider from '../components/DetailPage/AttachmentThumbnailSlider';
import AttachmentPreviewDialog from '../components/DetailPage/AttachmentPreviewDialog';
import { AddAttachmentDialog } from '../components/DetailPage/AddAttachmentDialog';
import { AssetTools } from '../components/DetailPage/AssetTools';
import { DEFAULT_CONTAINER_WIDTH } from '../utils/Constants';
import ButtonBack from '../components/ButtonBack';
import AttachmentThumbnailGrid from '../components/DetailPage/AttachmentThumbnailGrid';

const AssetDetailPage: React.FunctionComponent = observer(() => {
  // Extract Router Params
  // @ts-ignore It's existent. :) TS will never know.
  const { id } = useParams();
  const history = useHistory();

  // Return to home if no id is given
  if (!id) {
    return <Redirect to="/home" />;
  }

  // Store
  const {
    userAssetStore: {
      getUserAsset,
      loading,
      setLoading,
      singleUserAsset,
      assetDateFromNow,
      assetDateLocalString,
    },
  } = useStores();

  // Translation
  const { t } = useTranslation(['common']);

  // Get initial Detail Page Data, and react.
  useEffect(() => {
    setLoading(true);
    getUserAsset(id)
      .then(() => setLoading(false))
      .catch(() => {
        setLoading(false);
        toast.error(t('common:general.notFound'));
        history.replace('/home');
      });
  }, [id]); // eslint-disable-line

  return (
    <Container maxWidth={DEFAULT_CONTAINER_WIDTH}>
      {loading ? (
        <Box width="100%" height={200} display="flex" justifyContent="center" alignItems="center">
          <CircularProgress size={100} />
        </Box>
      ) : (
        <>
          <TransparentBackgroundWrapper marginBottom={20} paddingBottom={30}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
              >
                <ButtonBack goBackTo="home" />
                {' '}
              </Grid>

              <Grid item xs={12}>
                <Typography
                  variant="h2"
                  style={{
                    textAlign: "center",
                  }}
                >
                  { singleUserAsset.title ? singleUserAsset.title : `${t('common:detailPage.collectionFrom')} ${assetDateLocalString}` }
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Tooltip title={assetDateLocalString}>
                  <Typography style={{
                    textAlign: "center",
                  }}
                  >

                    { assetDateFromNow }
                    <span> </span>
                    {t('common:detailPage.dateCaption')}
                  </Typography>
                </Tooltip>
              </Grid>

              <Grid item xs={12}>
                <DetailPageForm />
              </Grid>

              <Grid item xs={12}>
                <AssetTools />
              </Grid>
              <AddAttachmentDialog />
            </Grid>

          </TransparentBackgroundWrapper>

          <TransparentBackgroundWrapper>
            <Grid container>
              <Grid item xs={12}>
                <AttachmentThumbnailGrid />
                {/* <AttachmentThumbnailSlider /> */}
              </Grid>
            </Grid>
          </TransparentBackgroundWrapper>
        </>
      )}

      <AttachmentPreviewDialog />
    </Container>

  );
});
export default AssetDetailPage;
