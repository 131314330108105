import { get, post } from '../utils/RequestManager';
import { PreAuthResponse } from '../models/PreAuthResponse';
import { LoginRequestData, RegisterRequestData } from '../models/LoginModels';

// Errors should be catched and handled by actions in stores

export async function login(loginRequestData: LoginRequestData) {
  try {
    return await post('/auth/login', {
      email: loginRequestData.email,
      password: loginRequestData.passwordSha256,
      rememberMe: loginRequestData.rememberMe,
    });
  } catch (e: any) {
    throw new Error(e);
  }
}

export async function logout() {
  try {
    return await post('/auth/logout');
  } catch (e: any) {
    throw new Error(e);
  }
}

export async function logoutAll() {
  try {
    return await post('/auth/logoutAllDevices');
  } catch (e: any) {
    throw new Error(e);
  }
}

export async function signUp(registerRequestData: RegisterRequestData) {
  try {
    return await post('/auth/signup', {
      email: registerRequestData.email,
      password: registerRequestData.passwordSha256,
      lang: registerRequestData.language,
    });
  } catch (e: any) {
    throw new Error(e);
  }
}

export async function status() {
  try {
    return await get('/auth');
  } catch (e: any) {
    throw new Error(e);
  }
}

export async function preAuth(email: string) {
  try {
    const { data } = await post('/auth/preAuth', { email });
    return new PreAuthResponse(data.login, data.register);
  } catch (e: any) {
    throw new Error(e);
  }
}

export async function resendEmail(email: string) {
  try {
    return await post('/auth/sendEmailVerificationMail', { email });
  } catch (e: any) {
    throw new Error(e);
  }
}
