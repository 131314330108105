/* eslint-disable no-console */
import { makeAutoObservable, runInAction } from 'mobx';
import { AxiosResponse } from 'axios';
import {
  LoginError,
  LoginErrorState,
  LoginRequestData,
  LoginResponse,
  LoginState,
  RegisterRequestData,
  RegisterResponse,
} from '../models/LoginModels';
import {
  login,
  logout,
  preAuth,
  resendEmail,
  signUp,
  status,
} from '../services/AuthenticationService';
import { PreAuthResponse } from '../models/PreAuthResponse';
import { handleError } from '../utils/ErrorUtils';
import { post } from '../utils/RequestManager';

export class AuthStore {
  // Define the Fields of the store
  mail: string = '';

  passwordHash: string = '';

  isAuthenticated: boolean = false;

  step: LoginState = LoginState.initial;

  loading: boolean = false;

  rememberMe: boolean = false;

  error: LoginError | null = null;

  // make the fields observable
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
    this.checkIsAuthenticated();
  }

  // eslint-disable-next-line class-methods-use-this
  // @ts-ignore
  async checkIsAuthenticated(): void {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const response = await status();
      if (response.status === 204) {
        runInAction(() => {
          this.loading = false;
          this.isAuthenticated = true;
          this.error = new LoginError(LoginErrorState.none);
          this.step = LoginState.final; // Really needed?
          console.warn(`from login store: ${this.isAuthenticated}`);
        });
      }
    } catch (e) {
      runInAction(() => {
        this.loading = false;
        this.isAuthenticated = false;
      });
      // No need to handle error. Just side effects.
    }
  }

  toggleLoading(isLoading: boolean): void {
    this.loading = !isLoading;
  }

  setStep(currentStep: LoginState): void {
    this.checkIsAuthenticated();
    this.error = new LoginError(LoginErrorState.none); // reset the login error state
    this.step = currentStep;
  }

  setMail(mail: string): void {
    this.checkIsAuthenticated();
    this.mail = mail;
  }

  setPassword(password: string) {
    this.checkIsAuthenticated();
    this.passwordHash = password;
  }

  // TODO: Use hashed passwords.
  // This is async because we are using the WebCrypto API for safe
  // hashing.
  // async setPassword(password: string): Promise<void> {
  //   try {
  //     const pwdHash = await sha256(password);
  //     runInAction(() => {
  //       this.passwordHash = pwdHash;
  //     });
  //   } catch (e) {
  //     throw new Error('WebCryptoApi not available.');
  //   }
  // }

  setRememberMe(rememberMe: boolean): void {
    this.rememberMe = rememberMe;
  }

  async checkMail() {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const preAuthResponse: PreAuthResponse = await preAuth(this.mail);
      if (preAuthResponse) {
        runInAction(() => {
          this.loading = false;
          this.error = null;
          if (preAuthResponse.login) this.step = LoginState.login;
          if (preAuthResponse.register) this.step = LoginState.register;
        });
      }
    } catch (e: any) {
      runInAction(() => {
        this.loading = false;
        this.error = new LoginError(LoginErrorState.none, e.message);
      });
      handleError(e);
    }
  }

  async login(): Promise<void> {
    try {
      runInAction(() => {
        this.error = null;
        this.loading = true;
      });

      const loginRequestData = new LoginRequestData(
        this.mail,
        this.passwordHash,
        this.rememberMe
      );

      const loginReponse: AxiosResponse<LoginResponse> = await login(
        loginRequestData
      );

      if (loginReponse.status === 200) {
        runInAction(() => {
          this.loading = false;
          if (loginReponse.data.emailVerificationRequired) {
            // OTP ignored for now.
            this.step = LoginState.emailVerification;
          } else {
            this.isAuthenticated = true;
            this.step = LoginState.final; // TODO: Remove
          }
        });
      }
    } catch (e: any) {
      runInAction(() => {
        this.loading = false;
        this.isAuthenticated = false;
        this.error = new LoginError(
          LoginErrorState.loginFailed,
          `Login failed. ${e}`
        );
      });
      handleError(e, {
        showToast: true,
      });
    }
  }

  async signUp(): Promise<void> {
    try {
      runInAction(() => {
        this.error = null;
        this.loading = true;
      });

      const registerRequestData = new RegisterRequestData(
        this.mail,
        this.passwordHash
      );

      const response: AxiosResponse<RegisterResponse> = await signUp(
        registerRequestData
      );
      if (response) {
        runInAction(() => {
          this.loading = false;
          if (response.data.success) {
            this.step = LoginState.emailVerification;
          } else {
            this.step = LoginState.final;
          }
        });
      }
    } catch (e) {
      runInAction(() => {
        this.loading = false;
        this.error = new LoginError(
          LoginErrorState.registerFailed,
          `register failed: ${e}`
        );
      });
      handleError(e);
    }
  }

  async logout(): Promise<void> {
    try {
      runInAction(() => {
        this.error = null;
      });
      const response: any = await logout();
      runInAction(() => {
        if (response.status === 204) {
          this.isAuthenticated = false;
          this.step = LoginState.logoutSuccess;
        }
      });
    } catch (e) {
      runInAction(() => {
        this.loading = false;
        this.error = new LoginError(
          LoginErrorState.logoutFailed,
          `cannot logout: ${e}`
        );
      });
      handleError(e);
    }
  }

  async resendVerificationEmail(): Promise<void> {
    try {
      runInAction(() => {
        this.error = null;
        this.loading = true;
      });
      await resendEmail(this.mail);
      runInAction(() => {
        this.loading = false;
        this.step = LoginState.emailVerificationSent;
      });
    } catch (e) {
      runInAction(() => {
        this.loading = false;
        this.error = new LoginError(
          LoginErrorState.resendVerificationMailFailed,
          `cannot send mail: ${e}`
        );
      });
      handleError(e);
    }
  }

  async sendResetPasswordMail(email: string): Promise<void> {
    try {
      runInAction(() => {
        this.error = null;
        this.loading = true;
      });
      await post('/auth/sendResetPasswordMail', { email });
      runInAction(() => {
        this.loading = false;
        this.step = LoginState.resetPasswordMailSent;
      });
    } catch (e) {
      runInAction(() => {
        this.loading = false;
      });
      handleError(e);
    }
  }

  /**
   * Reset the Password for a gien user, id'd by token from mail.
   * @param password
   * @param token
   */
  async resetPassword(password: string, token: string): Promise<void> {
    try {
      runInAction(() => {
        this.error = null;
        this.loading = true;
      });
      await post('/auth/resetPassword', { token, password });
      runInAction(() => {
        this.loading = false;
      });
    } catch (e) {
      runInAction(() => {
        this.loading = false;
      });
      handleError(e);
    }
  }
}
