import BefundeIcon from '../resources/vector/Icon-Befunde.svg';
import MedizinischeBibliothekIcon from '../resources/vector/Icon-Bibliothek.svg';
import BilderIcon from '../resources/vector/Icon-Bilder.svg';
import FinanzenIcon from '../resources/vector/Icon-Finanzen.svg';
import JuristischeDokumenteIcon from '../resources/vector/Icon-Jur-Dokumente.svg';
import KommunikationIcon from '../resources/vector/Icon-Kommunikation.svg';
import KrankheitsbilderIcon from '../resources/vector/Icon-Krankheitsbilder.svg';
import LiveSaverIcon from '../resources/vector/Icon-Live-Safer.svg';
import MedikamenteIcon from '../resources/vector/Icon-Medikamente.svg';
import MooviesIcon from '../resources/vector/Icon-Moovies.svg';
import PassIcon from '../resources/vector/Icon-Pass.svg';
import MedizinischesNetzwerkIcon from '../resources/vector/Icon-med-Netzwerke.svg';

export const RootFolderIconMapping = [
  {
    apiIconId: 'MedicalResultIcon',
    icon: BefundeIcon,
    helptext:
      'Ambulante und stationäre Arztbriefe, Laborwerte, Messergebnisse, OP-Berichte, Untersuchungsdokumentation',
    helptextEN:
      "Ambulatory and inpatient doctor's letters, laboratory values, measurement results, OP reports, examination documentation",
  },
  {
    apiIconId: 'ImagesIcon',
    icon: BilderIcon,
    helptext:
      'Bildgebung (Röntgen, CT, MRT, DVT, Endoskopie), Eigene Bilder (mit dem Smartphone selbst aufgenommene Fotos und Videos von krankhaften Veränderungen)',
    helptextEN:
      'Imaging (X-ray, CT, MRI, DVT, endoscopy), Own images (photos and videos of pathological changes taken with the smartphone)',
  },
  {
    apiIconId: 'IdCardsIcon',
    icon: PassIcon,
    helptext:
      'Impfpass, Allergie-Ausweis, Mutterpass, U-Untersuchungen Kinder und Jugendliche, Implantatpass, Röntgenpass, Bonushefte, Behinderten-Ausweis, Transplantationsausweis',
    helptextEN:
      "Vaccination card, allergy ID, mother's pass, U-examinations for children and adolescents, implant pass, X-ray pass, bonus booklets, disability ID, transplantation ID",
  },
  {
    apiIconId: 'CommunicationIcon',
    icon: KommunikationIcon,
    helptext:
      'Mit Praxen und Kliniken, Versorgungseinrichtungen, Pflegediensten, Sanitärfachhandel',
    helptextEN:
      'With practices and clinics, care facilities, nursing services, sanitary specialist trade',
  },
  {
    apiIconId: 'FinanceIcon',
    icon: FinanzenIcon,
    helptext:
      'Schriftwechsel mit GKV/PKV, Kostenvoranschläge, Rechnungen (Heil- und Hilfsmittel), Quittungen (nicht erstattungsfähige Medikamente, Selbstzahlerleistungen)',
    helptextEN:
      'Correspondence with insurances, cost estimates, invoices (medical and auxiliary materials), receipts (non-refundable medicines, self-payer services)',
  },
  {
    apiIconId: 'MooviesIcon',
    icon: MooviesIcon,
    helptext:
      'Atteste, Arbeitsunfähigkeitsbescheinigungen, Rezepte/Verordnungen',
    helptextEN: 'Certificates, incapacity certificates, prescriptions/orders',
  },
  {
    apiIconId: 'DiseasePatternsIcon',
    icon: KrankheitsbilderIcon,
    helptext: 'Schlagworte zu bestimmten Krankheitsbildern',
    helptextEN: 'Keywords for specific diseases',
  },
  {
    apiIconId: 'DrugsIcon',
    icon: MedikamenteIcon,
    helptext: 'Foto der Medikamentenpackung oder des Medikationsplans',
    helptextEN: 'Photo of the medication package or the medication plan',
  },
  {
    apiIconId: 'MedicalNetworkIcon',
    icon: MedizinischesNetzwerkIcon,
    helptext:
      'Ärzte in Praxen und Kliniken, Gesundheitsdienste (ambulante Pflege, Hebamme etc.), Arbeitgeber, Schule, Kindergarten (jeweils mit Mailadresse, Telefon, Webseite)',
    helptextEN:
      'Doctors in practices and clinics, health services (outpatient care, midwife etc.), employer, school, kindergarten (each with email address, telephone, website)',
  },
  {
    apiIconId: 'LegalDocumentsIcon',
    icon: JuristischeDokumenteIcon,
    helptext: 'Patientenverfügung, Vollmachten, Transplantations-Einwiligung',
    helptextEN: 'Patient decree, powers of attorney, transplantation consent',
  },
  {
    apiIconId: 'MedicalLibraryIcon',
    icon: MedizinischeBibliothekIcon,
    helptext: 'Artikel und Links',
    helptextEN: 'Articles and links',
  },
  {
    apiIconId: 'LiveSaferIcon',
    icon: LiveSaverIcon,
    helptext:
      'Blutgruppe, lebensbedrohliche Allergien, ernstere Vorerkrankungen, nächste Angehörige (Adresse, Mail und Telefon)',
    helptextEN:
      'Blood group, life-threatening allergies, more serious pre-existing conditions, next of kin (address, email and telephone)',
  },
];

export default [
  {
    title: 'Befunde',
    icon: BefundeIcon,
  },
  {
    title: 'Bilder',
    icon: BilderIcon,
  },
  {
    title: 'Pässe/Ausweise',
    icon: PassIcon,
  },
  {
    title: 'Kommunikation',
    icon: KommunikationIcon,
  },
  {
    title: 'Finanzen',
    icon: FinanzenIcon,
  },
  {
    title: 'Moovies',
    icon: MooviesIcon,
  },
  {
    title: 'Krankheitsbilder',
    icon: KrankheitsbilderIcon,
  },
  {
    title: 'Medikamente',
    icon: MedikamenteIcon,
  },
  {
    title: 'Medizinisches Netzwerk',
    icon: MedizinischesNetzwerkIcon,
  },
  {
    title: 'Juristische Dokumente',
    icon: JuristischeDokumenteIcon,
  },
  {
    title: 'Medizinische Bibliothek',
    icon: MedizinischeBibliothekIcon,
  },
  {
    title: 'Live-Saver',
    icon: LiveSaverIcon,
  },
];
