/* eslint-disable */
import {makeAutoObservable, runInAction} from 'mobx';
import {AxiosResponse} from 'axios';
import {get} from '../utils/RequestManager';
import {handleError} from '../utils/ErrorUtils';
import {FolderLabels} from '../models/FolderLabels';
import {Asset, ChildFolder, FolderResponse} from '../models/Folders';

export class FolderStore {
  folders: Partial<FolderResponse> = {};
  folderLabels: Partial<FolderLabels> = {};
  loading: boolean = false

  constructor() {
    this.getFolders();
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  // Get Subfolders for a current ID.
  getChildrenForId(id: string): ChildFolder[] | undefined {
    if (this.folders.children === undefined) {
      this.getFolders();
    }
    const sel = this.folders.children?.find((child) => id === child.id);
    return sel!.children;
  }

  getAssetsInFolder(id: string): Asset[] | undefined {
    if (this.folders.children === undefined) {
      this.getFolders();
    }
    // @ts-ignore
    return this.folders.children?.find((child) => id === child.id).assets;
  }

  getChildById(id: string): ChildFolder | undefined {
    if (this.folders.children === undefined) {
      this.getFolders();
    }
    return this.folders.children?.find((child) => id === child.id);
  }

  async getFolders(): Promise<void> {
    try {
      this.loading = true;
      const res = await get('/folder');
      runInAction(() => {
        this.folders = res.data;
        this.loading = false;
      });
    } catch (e: any) {
      return handleError(e);
    }
  }

  async getFolderLabels(): Promise<void> {
    try {
      const res = await get('/folder/labels');
      runInAction(() => {
        this.folderLabels = res.data;
      });
    } catch (e: any) {
      return handleError(e);
    }
  }
}
