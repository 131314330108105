import {
  Box, Button, Grid, Typography,
} from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import ImageViewer from '../ImageViewer';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import PDFViewer from '../PDFViewer';
import DescriptionIcon from '@material-ui/icons/Description';
import CloudDownload from '@material-ui/icons/CloudDownload';
import TransparentPaper from '../TransparentPaper';
import { Attachment } from '../../models/UserAssetModel';

type AttachmentPreviewProps = {
  attachment: Attachment,
  classNames?: any,
};

const AttachmentPreview: FunctionComponent<AttachmentPreviewProps> = observer(({ attachment }) => {
  const { t } = useTranslation(['common']);

  const {
    filetype,
    url,
    fileName,
  } = attachment;

  // Choose a designated file viewer

  if (filetype.startsWith('image/')) {
    return (
      <Grid container direction="column">
        <Grid item>
          <ImageViewer src={url} />
        </Grid>
      </Grid>
    );
  }

  if (filetype === 'application/pdf') {
    return (
      <Grid container direction="column">
        <PDFViewer url={url} />
      </Grid>
    );
  }

  return (
    <Grid container direction="column">
      <Grid item>
        <TransparentPaper>
          <Box display="flex" p={2} justifyContent="center" alignItems="center" flexDirection="column">
            <DescriptionIcon color="primary" style={{ fontSize: 150 }} />
            <Box py={2}>
              <Typography variant="h4">
                {fileName}
              </Typography>
            </Box>
            <Box pb={2}>
              <Typography color="textSecondary">
                {t('common:detailPage.previewNotAvailable')}
              </Typography>
            </Box>
            <Button
              color="primary"
              variant="outlined"
              size="large"
              startIcon={<CloudDownload />}
              href={url}
            >
              {t('common:detailPage.download')}
            </Button>
          </Box>
        </TransparentPaper>

      </Grid>
    </Grid>
  );
});

export default AttachmentPreview;
