import { createContext } from 'react';
import { DialogStore } from './DialogStore';
import { AuthStore } from './AuthStore';
import { UserAssetStore } from './UserAssetStore';
import { UploadDialogStore } from './UploadDialogStore';
import { FolderStore } from './FolderStore';
import { TagStore } from './TagStore';
import { SearchStore } from './SearchStore';

/**
 * Pass all the stores to the rootStoreContext, which is then used
 * by useStores() "hook".
 */
const rootStoreContext = createContext({
  moovyAuthStore: new AuthStore(),
  userAssetStore: new UserAssetStore(),
  dialogStore: new DialogStore(),
  uploadDialogStore: new UploadDialogStore(),
  folderStore: new FolderStore(),
  tagStore: new TagStore(),
  searchStore: new SearchStore(),
});

export default rootStoreContext;
