import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Chip, CircularProgress, Tooltip, Typography,
} from '@material-ui/core';
import { UserAssetTag } from '../../stores/TagStore';
import Grid from '@material-ui/core/Grid';
import DeleteIcon from '@material-ui/icons/Delete';
import useStores from '../../hooks/use-stores';
import { toast } from 'react-toastify';
import { DEFAULT_GRID_SPACING } from "../../utils/Constants";
import DeleteConfirm from '../DeleteConfirm';

type TagElementProps = {
  tag: UserAssetTag
  index?: number,
};

export const TagElement: React.FC<TagElementProps> = ({ tag }) => {
  const {
    tagStore: {
      deleteTag, loading, setChangeTagDialogOpen,
    },
  } = useStores();
  const { t } = useTranslation(['common']);
  const [open, setOpen] = React.useState(false);
  return (
    <Grid item>
      <Tooltip title={t('common:general.edit')}>

        <Chip
          onClick={() => {
            setChangeTagDialogOpen(true, tag);
          }}
          deleteIcon={loading ? <CircularProgress size={10} /> : <DeleteIcon />}
          onDelete={() => {
            setOpen(true);
          }}
          label={tag.name}
        />

      </Tooltip>

      <DeleteConfirm
        open={open}
        description={t('common:deleteConfirmDialog.irreversibleDelete')}
        onConfirm={() => deleteTag(tag.id)
          .then(() => {
            setOpen(false);
            toast.success(t('common:tags.deleteSuccess'));
            // history.replace('/home');
          })}
        onClose={() => setOpen(false)}
      />

    </Grid>
  );
};

type TagListProps = {
  tags: any;
};
export const TagList: React.FC<TagListProps> = ({ tags }) => {
  const { t } = useTranslation(['common']);
  if (tags && tags.length < 0) return <Typography>{t('common:general.noItems')}</Typography>;
  return (
    <Grid container spacing={DEFAULT_GRID_SPACING}>
      {tags && tags.map((tag: UserAssetTag, index: number) => <TagElement tag={tag} index={index} />)}
    </Grid>
  );
};
