import {
  action, makeObservable, observable, runInAction,
} from 'mobx';
import { post } from '../utils/RequestManager';
import { handleError } from '../utils/ErrorUtils';

export class DialogStore {
  isFeedbackDialogOpen: boolean = false;

  inputTitle: string = '';

  inputFeedback: string = '';

  sendSuccess: boolean = false;

  constructor() {
    // makeAutoObservable(this, undefined, { autoBind: true });

    makeObservable(this, {
      isFeedbackDialogOpen: observable,
      setFeedbackDialogOpen: action.bound,
      inputTitle: observable,
      inputFeedback: observable,
      setFeedbackContent: action.bound,
      sendSuccess: observable,
    });
  }

  setFeedbackDialogOpen(open: boolean): void {
    this.isFeedbackDialogOpen = open;
  }

  async setFeedbackContent(inputTitle: string, inputFeedback: string): Promise<void> {
    this.inputTitle = inputTitle;
    this.inputFeedback = inputFeedback;
    this.sendFeedback();
  }

  async sendFeedback(): Promise<void> {
    try {
      const response = await post('/feedback', {
        title: this.inputTitle,
        message: this.inputFeedback,
      });
      runInAction(() => {
        if (response.status === 201) {
          this.sendSuccess = true;
        }
      });
    } catch (error) {
      handleError(error);
    }
  }
}
